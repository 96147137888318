<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2020/10/07
@copyright EVITECH
===============================================================================

=========================================================================== -->


<template>

    <div>

        <div class="w-100">

            <button
                class="btn w-100 border greyed"
                style="border-radius: .25rem .25rem 0 0;"
                @click="switchFilter()"
            >

                <i class="fas fa-sliders-h"></i>

                {{ $t("Filters") }}

                <i class="fas" :class="getCaret()"></i>

            </button>


            <div
                v-if="displayFilter"
                class="row mx-0 text-center border-start border-end py-2"
            >

                <div class="col-3 border-end">

                    {{ $t("User") }}

                    <input
                        type="text"
                        :placeholder="$t('Contains')"
                        class="form-control border"
                        v-model="userFilter"
                        @input="applyFilter()"
                    />

                </div>


                <div class="col-3 border-end">

                    {{ $t("Company") }}

                    <input
                        type="text"
                        :placeholder="$t('Contains')"
                        class="form-control border"
                        v-model="companyFilter"
                        @input="applyFilter()"
                    />


                </div>


                <div class="col-3 border-end">

                    {{ $t("Last change sort") }}


                    <div>

                        <i class="fas fa-arrow-down"></i>

                        <toggle-button
                            class="my-auto"
                            v-model="lastChangeSort"
                            :width="130"
                            :height="20"
                            @input="applyFilter()"
                        />

                        <i class="fas fa-arrow-up"></i>

                    </div>

                </div>


                <div class="col-3 ">

                    {{ $t("Disabled accounts") }}


                    <div>

                        <toggle-button
                            class="my-auto"
                            v-model="disabledFilter.display"
                            :labels="{checked: $t('Display'), unchecked: $t('Hide')}"
                            :width="130"
                            :height="20"
                            :font-size="15"
                            @input="applyFilter()"
                        />

                    </div>


                    <div v-if="disabledFilter.display">

                        <toggle-button
                            class="my-auto"
                            v-model="disabledFilter.only"
                            :labels="{checked: $t('Only'), unchecked: $t('All')}"
                            :width="130"
                            :height="20"
                            :font-size="15"
                            @input="applyFilter()"
                        />

                    </div>

                </div>

            </div>

        </div>


        <div>

            <kendo-datasource
                ref="usersDataSource"

                :transport-read="getUsers"

                :schema-type="'json'"
                :schema-data="'data'"
                :schema-model-id="'username'"
                :schema-model-fields="usersGridFields"
                :schema-total="'total'"

                :server-paging="true"
                :server-filtering="true"

                :page-size="10"
            ></kendo-datasource>


            <kendo-grid
                ref="usersGrid"
                class="selectable-kendo-grid text-center"
                :height="'500px'"
                :data-source-ref="'usersDataSource'"
                :pageable="usersGridPageable"
                :row-template="gridRowTemplate"
                :selectable="true"
                :noRecords="noUsersTemplate"
                :change="gridClick"
            >

                <kendo-grid-column
                    field="user"
                    :title="$t('User')"
                    :filterable-cell-suggestion-operator="'contains'"
                ></kendo-grid-column>


                <kendo-grid-column
                    field="company"
                    :title="$t('Company')"
                    :filterable-cell-suggestion-operator="'contains'"
                ></kendo-grid-column>


                <kendo-grid-column
                    field="phone"
                    :title="$t('Phone')"
                    :filterable="false"
                ></kendo-grid-column>


                <kendo-grid-column
                    field="project_data"
                    :title="$t('Project')"
                    :filterable-cell-suggestion-operator="'contains'"
                ></kendo-grid-column>


                <kendo-grid-column
                    field="last_change"
                    :title="$t('Last change')"
                    :format="dateFormat"
                ></kendo-grid-column>

            </kendo-grid>

        </div>


        <UserDetails
            id="userModal"
            :gridRowData="rowSelected"
            :users="validUsers"
            @close-modal="closeModal"
        />


        <button
            ref="triggerUserModal"
            class="d-none"
            data-bs-toggle="modal"
            data-bs-target="#userModal"
        >
        </button>

    </div>

</template>


<!-- ====================================================================== -->


<script>

import $ from "jquery"

import Vue from "vue"

import kendo from "@progress/kendo-ui"
import { DataSource, DataSourceInstaller } from "@progress/kendo-datasource-vue-wrapper"
import { Grid, GridInstaller } from "@progress/kendo-grid-vue-wrapper"
import { DateinputsInstaller, DatePicker, TimePicker } from "@progress/kendo-dateinputs-vue-wrapper"
import "@progress/kendo-theme-default/dist/all.css"

import { ToggleButton } from "vue-js-toggle-button"

import { FR } from "@/i18n/i18n_languages"

import "./css/evdesign_kendo_override.css"
import UserDetails from "./user_details"

import translationFR from "./i18n/FR/translation"


//-----------------------------------------------------------------------------


let _ = require("lodash")


//-----------------------------------------------------------------------------


Vue.use(kendo)
Vue.use(DataSourceInstaller)
Vue.use(GridInstaller)
Vue.use(DateinputsInstaller)

require("kendo-ui-core/js/cultures/kendo.culture.fr-FR.js")


//-----------------------------------------------------------------------------


export default {
    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    mounted: function() {
        this.getAllUsersForSelect()
    },

    data: function() {
        return {
            usersGridFields: {
                username: { type: "string" },
                user: { type: "string" },
                company: { type: "string" },
                phone: { type: "string" },
                disabled: { type: "boolean" },
                project_data: { type: "string" },
                last_change: { type: "date" },
            },
            usersGridPageable: {
                pageSizes: [10, 25, 50, 100],
                buttonCount: 3,
                info: true,
                messages: {
                    "display": this.$t("{0} - {1} from {2} users", { "0": "{0}", "1": "{1}", "2": "{2}" }),
                    "itemsPerPage": this.$t("Users per page"),
                    "first": this.$t("First page"),
                    "previous": this.$t("Previous page"),
                    "next": this.$t("Next page"),
                    "last": this.$t("Last page"),
                    "empty": this.$t("No users"),
                    "refresh": this.$t("Refresh"),
                },
                refresh: true,
            },
            dateFormat: "{0:dd-MM-yyyy - HH:mm:ss}",
            gridRowTemplate: this.getRowTemplate(),
            noUsersTemplate: {
                template: this.getNoUsersTemplate(),
            },

            // displayFilter: false,
            // filter: _.cloneDeep(DEFAULT_FILTER),

            rowSelected: {},

            displayFilter: false,
            userFilter: "",
            companyFilter: "",
            lastChangeSort: false,
            disabledFilter: {
                display: false,
                only: false,
            },

            validUsers: [],
        }
    },

    watch: {
        rowSelected: function(newValue) {
            if (!_.isEmpty(newValue)) {
                this.$refs.triggerUserModal.click()
            }
        },
    },

    methods: {
        getRowTemplate: function() {
            let template =
                "# if (disabled) { #" +
                "<tr data-uid='#: uid #' class='disabled-user'>" +
                "# } else { #" +
                "<tr data-uid='#: uid #'>" +
                "# } #" +
                    "<td> #: user #</td>" +
                    "<td> #: company #</td>" +
                    "<td> #: phone #</td>" +
                    "# if (project_data !== null) { #" +
                    "<td> #: project_data #</td>" +
                    "<td> #: kendo.toString(kendo.parseDate(last_change), 'dd-MM-yyyy - HH:mm:ss') #</td>" +
                    "# } else { #" +
                    "<td></td>" +
                    "<td></td>" +
                    "# } #" +
                "</tr>"

            return kendo.template(template)
        },

        getNoUsersTemplate: function() {
            return this.$t("No users")
        },

        getUsers: function(options) {
            let filters = this.getFiltersWithGridOptions(options)

            this.axios({
                method: "get",
                url: "evdesign/users_management/get_all_users",
                params: filters,
                type: "json",
            })
                .then((response) => {
                    let data = response.data
                    options.success(data)
                })
                .catch((error) => {
                    options.error(error)
                })
        },

        gridClick: function(e) {
            let grid = e.sender
            let lineSelected = grid.select()
            let lineIndex = $(lineSelected).closest("tr").index()
            this.select(lineIndex)
        },

        select: function(lineIndex) {
            let grid = this.$refs.usersGrid.kendoWidget()
            let line = grid.tbody.find("tr")[lineIndex]
            this.rowSelected = grid.dataItem(line)
        },

        closeModal: function() {
            this.rowSelected = {}
            this.$refs.usersDataSource.kendoDataSource.read()
        },

        getFiltersWithGridOptions: function(options) {
            let params = _.cloneDeep(options.data)

            let sortDir = "des"
            if (this.lastChangeSort) {
                sortDir = "asc"
            }

            params.filters = {
                user: this.userFilter,
                company: this.companyFilter,
                sort: sortDir,
                disabled: this.disabledFilter,
            }

            return params
        },

        switchFilter: function() {
            this.displayFilter = !this.displayFilter
        },

        getCaret: function() {
            if (this.displayFilter) {
                return "fa-caret-up"
            }
            return "fa-caret-down"
        },

        getAllUsersForSelect: function() {
            this.axios({
                method: "get",
                url: "evdesign/users_management/get_valid_users",
                params: {},
                type: "json",
            })
                .then((response) => {
                    let data = response.data
                    this.validUsers = data["users"]
                })
                .catch((error) => {
                    // TODO
                    console.log("get_valid_users error", error)
                })
        },

        applyFilter: function() {
            this.$refs.usersDataSource.kendoDataSource.read()
        },
    },

    components: {
        DataSource,
        Grid,
        DatePicker,
        TimePicker,
        UserDetails,
        ToggleButton,
    }
}

</script>


<!-- ====================================================================== -->


<style>

.disabled-user {
    background-color: #a6aaae;
    color: white;
}

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
