//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/05/11 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


//------------------------------------------------------------------------------


import { mapState, mapGetters, mapActions, mapMutations } from "vuex"


//------------------------------------------------------------------------------


function computeNamespace(ancestor, localNamespace) {
    return ancestor + "/" + localNamespace
}


//------------------------------------------------------------------------------


export function djefRegisterModule(
    self,
    defaultNamespace,
    defaultModule,
    states = "",
    getters = "",
    actions = "",
    mutations = ""
) {
    let computedNamespace = defaultNamespace

    if (self.$options.propsData.namespace) {
        computedNamespace = computeNamespace(self.$options.propsData.namespace, defaultNamespace)
    }

    let alreadyRegistered = false
    for (let namespace in self.$store._modulesNamespaceMap) {
        let computed = computedNamespace + "/"
        if (namespace === computed) {
            alreadyRegistered = true
        }
    }

    if (!alreadyRegistered) {
        if (self.$options.propsData.store) {
            self.$store.registerModule(computedNamespace, self.$options.propsData.store)
        } else {
            self.$store.registerModule(computedNamespace, defaultModule)
        }
    }

    if (states) {
        self.$options.computed = {
            ...self.$options.computed,
            ...mapState(computedNamespace, states),
        }
    }

    if (getters) {
        self.$options.computed = {
            ...self.$options.computed,
            ...mapGetters(computedNamespace, getters),
        }
    }

    if (actions) {
        self.$options.methods = {
            ...self.$options.methods,
            ...mapActions(computedNamespace, actions),
        }
    }

    if (mutations) {
        self.$options.methods = {
            ...self.$options.methods,
            ...mapMutations(computedNamespace, mutations),
        }
    }

    return computedNamespace
}


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
