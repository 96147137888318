<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2020/10/19
@copyright EVITECH
===============================================================================



=========================================================================== -->


<template>

    <div
        class="row mt-3 mx-0 border align-items-center"
        :class="{'border-themed': disabled, 'removed': !project.isVisible}"
    >

        <div class="col-3">
            {{ project.name }}
        </div>


        <div class="col-6">
            {{ project.coordinate.address }}
        </div>


        <div class="col-3 px-0 text-right">

            <div class="btn-group" role="group">

                <button
                    class="btn evdesign-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('Go to project')"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="goToProject()"
                >
                    <i class="fas fa-edit fa-2x"></i>
                </button>


                <button
                    class="btn evdesign-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :disabled="true"
                    :title="$t('Download PDF report - Not available')"
                    @click="downloadPDF()"
                >
                    <i class="fas fa-file-pdf fa-2x"></i>
                </button>


                <button
                    class="btn evdesign-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :disabled="disabled"
                    :title="$t('Delete project')"
                    @click="waitDeleteConfirmation()"
                >
                    <i class="fas fa-trash fa-2x"></i>
                </button>


                <button
                    class="btn evdesign-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :disabled="disabled"
                    :title="$t('Reassign to an other user')"
                    @click="waitReassignConfirmation()"
                >
                    <i class="fas fa-people-arrows fa-2x"></i>
                </button>

            </div>

        </div>


        <div
            v-if="displayDeleteConfirmation"
            class="w-100 text-center border-top"
        >

            <div class="pt-2">
                {{ $t("Are you sure you want to delete permenantly this project ?")}}
            </div>


            <div class="btn-group" role="group">

                <button
                    type="button"
                    class="btn evdesign-btn"
                    @click="closeDeleteConfirmation()"
                >
                    {{ $t("Cancel") }}
                </button>


                <button
                    type="button"
                    class="btn evdesign-btn"
                    @click="deleteProject()"
                >
                    {{ $t("Delete") }}
                </button>

            </div>

        </div>


        <div
            v-if="displayReassingConfirmation"
            class="w-100 text-center border-top"
        >

             <div class="pt-2">
                {{ $t("Select the user you want this project to be reassign :")}}
            </div>


            <div class="mw-50">

                <v-select
                    v-model="reassignUser"
                    :searchable="true"
                    :labelSearchPlaceholder="$t('Search')"
                    :labelNotFound="$t('No result')"
                    :options="users"
                />

            </div>


            <div class="btn-group" role="group">

                <button
                    type="button"
                    class="btn evdesign-btn"
                    @click="closeReassignConfirmation()"
                >
                    {{ $t("Cancel") }}
                </button>


                <button
                    type="button"
                    class="btn evdesign-btn"
                    @click="reassignProjectToUser()"
                >
                    {{ $t("Reassign") }}
                </button>

            </div>


        </div>

    </div>

</template>


<!-- ====================================================================== -->


<script>

import VSelect from "@alfsnd/vue-bootstrap-select"


// ----------------------------------------------------------------------------


export default {
    props: {
        project: {
            type: Object,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        }
    },

    computed: {
        isDeletedByUser: function() {
            if (!this.project.isVisible) {
                return this.$t("Deleted by the user")
            }
            return ""
        },

        disabled: function() {
            return this.displayDeleteConfirmation || this.displayReassingConfirmation
        }
    },

    data: function() {
        return {
            displayDeleteConfirmation: false,
            displayReassingConfirmation: false,
            reassignUser: undefined,
        }
    },

    methods: {
        goToProject: function() {
            this.$emit("goToProject", this.project.id)
        },

        // ----------------------------

        downloadPDF: function() {
            // TODO
            console.log("DOWNLOAD PDF of ", this.project.name)
        },

        // ----------------------------

        waitDeleteConfirmation: function() {
            this.displayDeleteConfirmation = true
        },

        deleteProject: function() {
            let requestParams = {
                "id": this.project.id,
            }

            this.axios({
                method: "post",
                url: "evdesign/projects/delete_project",
                data: requestParams,
                type: "json",
            })
                .then(() => {
                    this.$emit("deleted")
                    this.closeDeleteConfirmation()
                })
                .catch((error) => {
                    console.log("Delete project ", this.project.id, "error", error)
                })
        },

        closeDeleteConfirmation: function() {
            this.displayDeleteConfirmation = false
        },

        // ----------------------------

        waitReassignConfirmation: function() {
            this.displayReassingConfirmation = true
        },

        reassignProjectToUser: function() {
            let requestParams = {
                "project_id": this.project.id,
                "new_user_details": this.reassignUser,
            }

            this.axios({
                method: "post",
                url: "evdesign/projects/reassign_project_to_user",
                data: requestParams,
                type: "json",
            })
                .then(() => {
                    this.$emit("reassigned")
                    let msg = this.$t("Project \"{project}\" reassigned to {newUser}", {
                        project: this.project.name,
                        newUser: this.reassignUser.details,
                    })
                    this.$noty.success(msg)
                    this.closeReassignConfirmation()
                })
                .catch((error) => {
                    console.log("Reassign project ", this.project.id, "error", error)
                })
        },

        closeReassignConfirmation: function() {
            this.displayReassingConfirmation = false
        }
    },

    components: {
        VSelect,
    },
}

</script>


<!-- ====================================================================== -->


<style scoped>

.removed {
    background-color: rgb(215, 218, 221);
}

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
