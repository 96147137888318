<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/08
@copyright EVITECH
===============================================================================

=========================================================================== -->


<template>

<a
    name="logoutButton"
    v-if="user.username !== ''"
    @click="logout"
    class="dropdown-item"
    href="#"
>

    <i class="fas fa-sign-out-alt"></i>

    {{ $t("Log out") }}

</a>

</template>


<!-- ======================================================================= -->


<script>

import { mapState, mapActions } from "vuex"

import {
    AUTH_MODULE_NAME,
    AUTH_LOGOUT,
} from "@/components/app_authentification/login/actions/auth"

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------



export default {
    beforeCreate() {
        this.$i18n.add(FR, translationFR)
    },
    computed: {
        ...mapState(AUTH_MODULE_NAME, [
            "user",
        ])
    },

    methods: {
        ...mapActions(AUTH_MODULE_NAME, {
            AUTH_LOGOUT,
        }),

        logout: function() {
            this.AUTH_LOGOUT(this.user.username)
                .finally(() => {
                    this.$router.push("/")
                })
        },
    },
}

</script>


<!-- ======================================================================= -->


<style>

</style>


<!-- ======================================================================= -->
<!-- End of file -->
<!-- ======================================================================= -->
