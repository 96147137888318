<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <component
        class="dropdown"
        :is="tag"
        :class="[
            { show: isOpen },
            { dropdown: direction === 'down' },
            { dropup: direction === 'up' }
        ]"
        aria-haspopup="true"
        :aria-expanded="isOpen"
        @click="toggleDropDown"
    >

        <slot name="title">

            <a
                class="dropdown-toggle nav-link"
                :class="{ 'no-caret': hideArrow }"
                data-toggle="dropdown"
            >

                <i :class="icon"></i>

                <span class="no-icon">

                    {{ title }}

                </span>

            </a>

        </slot>

        <ul
            class="dropdown-menu animate slideIn"
            :class="[
                { 'dropdown-evdesign-right': position === 'right' },
                { show: isOpen }
            ]"
        >

            <!--
                 Le slot ici sert à placer le composant Logout dans le dropdown
                 dans app_menu
            -->

            <slot></slot>

        </ul>

    </component>

</template>

<!-- ====================================================================== -->

<script>

export default {
    props: {
        direction: {
            type: String,
            default: "down"
        },
        title: String,
        icon: String,
        position: String,
        hideArrow: Boolean,
        tag: {
            type: String,
            default: "li"
        },
    },

    data: function() {
        return {
            isOpen: false
        }
    },

    provide: function() {
        return {
            closeDropDown: this.closeDropDown
        }
    },

    methods: {
        toggleDropDown: function() {
            this.$emit("change", this.isOpen)
        },

        closeDropDown: function() {
            this.isOpen = false
            this.$emit("change", this.isOpen)
        },
    },
}
</script>

<!-- ====================================================================== -->

<style scoped>
</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
