<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/09/28
@copyright EVITECH
===============================================================================

Liste de caméras (sidebar)

=========================================================================== -->

<template>

<div>

    <div style="height: 82px">

        <div class="row w-100 ms-0 px-0 p-1">

            <div class="d-flex ms-0 px-0">

                <h4 class="mt-2 p-1 ms-0">
                    {{ $t("Cameras") }}
                </h4>


                <div
                    class="ps-2 my-auto ms-auto icon"
                    style="cursor: pointer;"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('Create')"
                    @click="createCamera"
                >
                    <i class="fas fa-plus fa-2x me-3 "></i>
                </div>

            </div>

        </div>

        <div
            class="d-flex"
            style="margin-top: -10px;"
        >

            <input
                class="col-1 my-auto me-0"
                style="cursor: pointer;"
                type="checkbox"
                :checked="showCameraLabels"
                @change="toggleCameraLabels"
            >


            <p class="my-auto">
                {{ $t("Show all camera labels")}}
            </p>

        </div>

    </div>


    <div class="p-2 cameras-list">

        <div v-if="cameras.length > 0">

            <!-- Plugin pour glisser-ordonner la liste de caméras -->

            <draggable
                :list="cameras"
                v-bind="dragOptions"
                @end="cameraListDragEnd"
            >

                <div
                    v-for="(camera, index) in cameras"
                    :key="camera.id"
                    :class="getSelectedCameraClass(index)"
                    class="px-2 my-2 shadow-lg camera"
                    @click="selectCamera(index)"
                >

                    <div class="d-flex flex-row">

                        <div class="handle my-auto">
                            <i class="fas fa-arrows-alt fa-2x mx-1"></i>
                        </div>


                        <p class="ms-2 my-auto camera-name">
                            {{ camera.settings.name }}
                        </p>


                        <div
                            class="btn-group ms-auto my-1"
                            style="margin-right: -5px;"
                            role="group"
                        >

                            <button
                                type="button"
                                class="btn camera-action rounded-lg p-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="$t('Duplicate')"
                                @click="cloneCamera(index)"
                            >
                                <i class="fas fa-clone fa-2x"></i>
                            </button>

                            <button
                                type="button"
                                class="btn camera-action rounded-lg p-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="$t('Delete')"
                                @click="deleteCamera(index)"
                            >
                                <i class="fas fa-trash fa-2x"></i>
                            </button>

                        </div>

                    </div>

                </div>

            </draggable>

        </div>


        <div
            v-if="cameras.length === 0"
            class="text-center"
        >
            {{ $t("You don't have any cameras, please create one.")}}
        </div>

    </div>


    <CameraInfo
        v-if="selectedCameraFullDetails"
        class="border-top"
        :selectedCamera="selectedCameraFullDetails"
        @setName="setName"
        @setHeight="setHeight"
        @setTilt="setTilt"
        @setPosition="setPosition"
        @setAOV="setAOV"
        @setFOVColor="setFOVColor"
    />

</div>

</template>


<!-- ====================================================================== -->

<script>


import draggable from "vuedraggable"

import { FR } from "@/i18n/i18n_languages"

import CameraInfo from "@/components/project/edit_project/camera_info/camera_info"

import translationFR from "./i18n/FR/translation"


//-------------------------------------


export default {
    props: {
        cameras: {
            type: Array,
            default: function() {
                return []
            },
        },

        selectedCamera: {
            type: Number,
            default: 0,
        },

        showCameraLabels: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        cameras: {
            handler() {},
            deep: true,
            immediate: false,
        },
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    data: function() {
        return {
            showCameraListOptions: false,
        }
    },

    computed: {
        dragOptions: function() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost",
                handle: ".handle",
            }
        },

        selectedCameraFullDetails: function() {
            return this.cameras[this.selectedCamera]
        }
    },

    methods: {
        createCamera: function() {
            this.$emit("createCamera")
        },

        cameraListDragEnd: function(event) {
            this.$emit("selectedCamera", event.newDraggableIndex)
            this.$emit("sortCameras")
        },

        getSelectedCameraClass: function(index) {
            if (this.selectedCamera === index) {
                return "selected-camera"
            }
            return ""
        },

        selectCamera: function(index) {
            this.$emit("selectedCamera", index)
        },

        toggleCameraLabels: function() {
            this.$emit("toggleCameraLabels")
        },

        deleteCamera: function(cameraIndex) {
            this.$emit("deleteCamera", cameraIndex)
        },

        cloneCamera: function(cameraIndex) {
            this.$emit("cloneCamera", cameraIndex)
        },

        setName: function(value) {
            this.$emit("setName", value)
        },

        setHeight: function(value) {
            this.$emit("setHeight", value)
        },

        setTilt: function(value) {
            this.$emit("setTilt", value)
        },

        setPosition: function(value) {
            this.$emit("setPosition", value)
        },

        setAOV: function(value) {
            this.$emit("setAOV", value)
        },

        setFOVColor: function(value) {
            this.$emit("setFOVColor", value)
        },
    },

    components: {
        draggable,
        CameraInfo,
    }
}
</script>

<!-- ====================================================================== -->

<style scoped>

.camera-action {
    border: 1px solid black;
    margin-left: 6px;
    margin-right: 6px;
    width: 50px;
}

.icon:hover,
.camera-action:hover {
    transition: transform .2s;
    transform: scale(1.05)
}

.selected-camera{
    border-left: 5px solid rgb(0, 140, 255) !important;
}

.camera {
    background-color: #fb5;
    cursor: pointer;
    border: 1px solid rgb(136, 132, 132);
    border-radius: 2%;
}

.camera-name {
    max-width: calc(100% - 160px);
    overflow: hidden !important;
    text-overflow: hidden !important;
}

.cameras-list {
    height: calc(100vh - 453px) !important;
    overflow-y: scroll;
}

.options-list {
    background-color: #9bb9f0;
}

.handle {
    cursor: move;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
