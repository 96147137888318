<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/10/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <div class="admin-page w-100">

        <div class="card h-100">

            <div class="card-header text-center">

                <ul class="nav nav-tabs card-header-tabs">

                    <li
                        class="nav-item"
                        @click="active('users')"
                    >

                        <a
                            href="#"
                            class="nav-link"
                            :class="isActive('users')"
                        >

                            <i class="fas fa-users"></i>

                            {{ $t("Users management") }}

                        </a>

                    </li>


                    <li
                        class="nav-item"
                        @click="active('cameras')"
                    >

                        <a
                            href="#"
                            class="nav-link"
                            :class="isActive('cameras')"
                        >

                            <i class="fas fa-video"></i>

                            {{ $t("Cameras database management") }}
                        </a>

                    </li>

                </ul>

            </div>


            <div class="card-body">

                <UsersManagement
                    v-if="display == 'users'"
                />


                <CamerasManagement
                    v-if="display == 'cameras'"
                />

            </div>

        </div>

    </div>

</template>

<!-- ====================================================================== -->

<script>

import { FR } from "@/i18n/i18n_languages"

import UsersManagement from "./users_management/users_management"
import CamerasManagement from "./cameras_management/cameras_management"

import translationFR from "./i18n/FR/translation"


//-----------------------------------------------------------------------------



export default {
    data: function() {
        return {
            display: "users",
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    methods: {
        active: function(menu) {
            this.display = menu
        },

        isActive: function(menu) {
            if (this.display === menu) {
                return "active"
            }
            return ""
        },
    },

    components: {
        UsersManagement,
        CamerasManagement,
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>


.admin-page {
    height: calc(-70px + 100vh);
}

.nav-item:hover {
    cursor: pointer;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
