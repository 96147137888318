var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"dropdown",class:[
        { show: _vm.isOpen },
        { dropdown: _vm.direction === 'down' },
        { dropup: _vm.direction === 'up' }
    ],attrs:{"aria-haspopup":"true","aria-expanded":_vm.isOpen},on:{"click":_vm.toggleDropDown}},[_vm._t("title",function(){return [_c('a',{staticClass:"dropdown-toggle nav-link",class:{ 'no-caret': _vm.hideArrow },attrs:{"data-toggle":"dropdown"}},[_c('i',{class:_vm.icon}),_vm._v(" "),_c('span',{staticClass:"no-icon"},[_vm._v("\n\n                "+_vm._s(_vm.title)+"\n\n            ")])])]}),_vm._v(" "),_c('ul',{staticClass:"dropdown-menu animate slideIn",class:[
            { 'dropdown-evdesign-right': _vm.position === 'right' },
            { show: _vm.isOpen }
        ]},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }