<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <div>

        <div class="container-fluid">

            <div class="container-fluid">

                <div class="d-flex justify-content-center mb-3">

                    <h2>
                        {{ $t("My projects") }}
                    </h2>


                    <i
                        class="fas fa-plus-circle evdesign-blue-color fa-2x my-auto ms-2 add-project"
                        data-bs-toggle="modal"
                        data-bs-target="#addProjectModal"
                        @click="createProject"
                    ></i>

                </div>


                <Loading
                    v-if="loading"
                />


                <Message
                    v-if="showMessage && !loading"
                    :message="message"
                    :messageType="messageType"
                    @rerender="forceRerender"
                />


                <!-- Liste de projets -->


                <div v-if="!loading">

                    <div v-if="projects.length > 0">

                        <table class="table table-hover table-striped text-center">

                            <thead>

                                <tr>

                                    <th scope="col">
                                        &nbsp;
                                    </th>


                                    <th scope="col">
                                        {{ $t("Project name") }}
                                    </th>


                                    <th scope="col">
                                        {{ $t("Location") }}
                                    </th>


                                    <th scope="col">
                                        {{ $t("Project type") }}
                                    </th>


                                    <th scope="col">
                                        {{ $t("Created at")}}
                                    </th>


                                    <th scope="col">
                                        {{ $t("Last saved at")}}
                                    </th>


                                    <th scope="col">
                                        <!-- Actions -->
                                    </th>

                                </tr>

                            </thead>


                            <!--
                                Pas besoin du <tbody>, le plugin l'insère en précisant
                                tag="tbody"
                            -->


                            <draggable
                                :list="projects"
                                tag="tbody"
                                v-bind="dragOptions"
                                @end="dragEnd"
                                ref="draggable"
                            >

                                <Project
                                    v-for="project in projects"
                                    :key="project.id"
                                    :project="project"
                                    @clone="cloneProject"
                                    @delete="removeProject"
                                />

                            </draggable>

                        </table>

                    </div>


                    <!-- Aucun projet -->


                    <div
                        v-if="!showMessage && projects.length === 0"
                        class="container text-center mt-5"
                    >

                        <h4>
                            {{ $t("You have no projects, click on the blue plus button to create one.")}}
                        </h4>

                    </div>

                </div>

            </div>

        </div>


        <AddProjectModal
            id="addProjectModal"
            ref="addProjectModal"
            @success="creationSuccess"
        />

    </div>

</template>

<!-- ====================================================================== -->

<script>

import draggable from "vuedraggable"

import { FR } from "@/i18n/i18n_languages"

import Loading from "@/components/app_utils/loading/loading"
import Message from "@/components/app_utils/message_handler/message_handler"

import Project from "@/components/project/project"
import AddProjectModal from "@/components/project/add_project/add_project"

import translationFR from "./i18n/FR/translation"


//----------------------------------------------------------------------------


export default {
    data: function() {
        return {
            projects: [],
            showMessage: false,
            message: "There has been a problem while retrieving your projects, please try again by refreshing the page",
            loading: true,
            messageType: "error",
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    mounted: function() {
        this.getUserProjects()
    },

    computed: {
        dragOptions: function() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost",
                handle: ".handle", // handle's class
            }
        },
    },

    methods: {
        getUserProjects: function() {
            let username = this.axios.defaults.headers.common["Authorization"]

            let request = {
                method: "post",
                url: "evdesign/projects/get_all_projects",
                data: {
                    username: username,
                },
                type: "json"
            }

            this.axios(request)
                .then(response => {
                    let status = response.data.data
                    if (status === 0) {
                        this.showMessage = true
                        // Par défaut message d'erreur dans le data()
                    } else {
                        this.showMessage = false
                        this.projects = response.data.data
                    }
                })
                .catch(() => {
                    this.showMessage = true
                })
                .finally(() => {
                    this.loading = false
                    this.saveProjectsOrder()
                })
        },

        forceRerender: function() {
            this.reset()
            this.getUserProjects()
        },

        reset: function() {
            Object.assign(this.$data, this.$options.data.call(this))
        },

        createProject: function() {
            this.$refs.addProjectModal.setMarker()
        },

        cloneProject: function(id) {
            this.loading = true

            let request = {
                method: "post",
                url: "evdesign/projects/clone_project",
                data: { id: id },
                type: "json"
            }
            this.showMessage = true

            this.axios(request)
                .then(() => {
                    this.getUserProjects()
                })
                .catch((error) => {
                    console.log("ERROR", error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        removeProject: function(id) {
            this.loading = true

            let request = {
                method: "post",
                url: "evdesign/projects/remove_project",
                data: { id: id },
                type: "json"
            }
            this.showMessage = true

            this.axios(request)
                .then(response => {
                    let status = response.data.data
                    if (status === 0) {
                        // TODO ?
                    } else {
                        this.showMessage = false
                        let removeIndex = this.projects.map(project => project.id).indexOf(id)
                        this.projects.splice(removeIndex, 1)
                    }
                })
                .catch(() => {
                    // TODO ?
                })
                .finally(() => {
                    this.loading = false
                })
        },

        saveProjectsOrder: function() {
            let projects = []

            let username = this.axios.defaults.headers.common["Authorization"]

            this.projects.forEach((project) => {
                let positionInList = this.projects.map(project => project.id).indexOf(project.id)
                projects.push(
                    {
                        id: project.id,
                        position_in_list: positionInList,
                    }
                )
            })

            let request = {
                method: "post",
                url: "evdesign/projects/edit_projects_order",
                data: {
                    username: username,
                    projects: projects
                },
                type: "json"
            }

            this.axios(request)
                .then(response => {
                    let status = response.data.data
                    if (status === 1) {
                        // Requete réussie
                    } else {
                        // TODO Info ?
                        this.getUserProjects()
                    }
                })
                .catch((response) => {
                    this.showMessage = true
                    this.projects = []
                    let order = this.$refs.draggable.toArray()
                    this.$refs.draggable.sort(order.reverse())
                })
        },

        creationSuccess: function() {
            this.getUserProjects()
            this.$noty.success(this.$t("Your project has been added successfully"))
        },

        dragEnd: function(event) {
            this.saveProjectsOrder()
        },
    },

    components: {
        draggable,
        Project,
        Loading,
        Message,
        AddProjectModal,
    },
}
</script>

<!-- ====================================================================== -->

<style scoped>

.add-project {
    cursor: pointer;
}

.add-project:hover {
    color: #28609c
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
