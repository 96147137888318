<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/08/25
@copyright EVITECH
===============================================================================

=========================================================================== -->
<template>

    <div class="container-fluid no-padding col-md-10">

        <div class="text-center mt-5">

            <sweetalert-icon
                icon="loading"
            />

        </div>


        <slot></slot>

    </div>

</template>


<!-- ====================================================================== -->


<script>

import SweetalertIcon from "vue-sweetalert-icons"

//-------------------------------------------------------------------------------

export default {
    components: {
        SweetalertIcon,
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>
</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->

