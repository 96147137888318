<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/21
@copyright EVITECH
===============================================================================
Page pour réinitialiser le mot de passe
=========================================================================== -->

<template>
    <div>

        <transition name="fade">

            <div class="text-center">


                <!-- ICONE DE CHARGEMENT -->

                <sweetalert-icon v-if="loading" icon="loading" />

                <!-- LE TOKEN EST VALIDE, ON AFFICHE LE FORMULAIRE DE CHANGEMENT DE MDP -->

                <div
                    v-if="tokenIsValid && !loading"
                    class="container mt-4 text-center"
                >
                    <ValidationObserver v-slot="{ invalid }">

                        <sweetalert-icon icon="success"></sweetalert-icon>

                        <div class="mt-4 errorText">

                            {{ $t(message) }}

                        </div>

                        <div
                            v-if="!passwordChanged"
                            class="mt-4"
                        >


                            <!-- MOT DE PASSE -->

                                <div class="container">

                                    <ValidationProvider rules="required|minPasswordLength" v-slot="{ errors }">

                                        <div class="margin-top">

                                            {{$t('Password')}}

                                        </div>

                                        <password
                                            :badge="false"
                                            id="password"
                                            type="password"
                                            :placeholder="$t('Password')"
                                            name="password"
                                            :class="(errors.length > 0)?'border border-danger rounded':''"
                                            v-model="password"
                                        />

                                        <div class="validation-error">

                                            {{ $t(errors[0]) }}

                                        </div>

                                    </ValidationProvider>

                                </div>

                                <!-- CONFIRMATION MOT DE PASSE -->

                                <div class="container">

                                    <ValidationProvider rules="required|minPasswordLength" v-slot="{ errors }">

                                        <div class="margin-top">{{$t('Password confirmation')}}</div>

                                        <password
                                            :badge="false"
                                            id="passwordConfirm"
                                            type="password"
                                            :placeholder="$t('Password confirmation')"
                                            name="passwordConfirm"
                                            :class="(errors.length > 0)?'border border-danger rounded':''"
                                            v-model="passwordConfirm"
                                        />

                                        <div class="validation-error">

                                            {{ $t(errors[0]) }}

                                        </div>

                                    </ValidationProvider>

                                </div>


                        </div>

                        <!-- BOUTTON DE VALIDATION -->

                        <button
                            v-if="!passwordChanged"
                            :disabled="invalid"
                            class="btn evdesign-btn text-center mt-3"
                            name="commit"
                            @click="changePassword"
                        >

                            {{$t('Change password')}}

                        </button>

                    </ValidationObserver>

                </div>

                <div class="container text-center mt-4" v-if="!tokenIsValid && !loading">

                    <sweetalert-icon icon="error"></sweetalert-icon>


                    <div class="container mt-4 errorText">{{ $t(message) }}</div>

                </div>

            </div>

        </transition>

    </div>
</template>

<!-- ======================================================================= -->


<script>

import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate"

import SweetalertIcon from "vue-sweetalert-icons"

import Password from "vue-password-strength-meter"

import NavBar from "@/components/navbar/navbar"
import { FR } from "@/i18n/i18n_languages"
import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------


export default {
    data() {
        return {
            loading: true,
            tokenIsValid: false,
            token: "",
            password: "",
            passwordConfirm: "",
            passwordChanged: false
        }
    },

    beforeCreate() {
        this.$i18n.add(FR, translationFR)
    },

    mounted() {
        this.token = this.$route.params.token
        this.checkToken()
    },

    methods: {
        checkToken: function() {
            this.axios({
                method: "post",
                url: "evdesign/users_management/verify_reset_token",
                data: {
                    token: this.token,
                },
                type: "json"
            })
                .then(() => {
                    this.tokenIsValid = true
                    this.message = this.$t("The token provided is valid, please fill the form below to reset password")
                })
                .catch((error) => {
                    this.tokenIsValid = false
                    if (error.response) {
                        let errorMessage = error.response.data.data

                        if (errorMessage.includes("TE")) {
                            this.message = this.$t("The link is invalid or expired, please try again by requesting a new email.")
                        } else if (errorMessage.includes("UDNE")) {
                            this.message = this.$t("Can not reset the password of this address.")
                        }
                    } else {
                        this.message = this.$t("Could not contact server, please try again")
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        changePassword: function() {
            if (this.password === this.passwordConfirm) {
                this.loading = true
                this.axios({
                    method: "post",
                    url: "evdesign/users_management/reset_password",
                    data: {
                        token: this.token,
                        password: this.password
                    },
                    type: "json"
                })
                    .then(() => {
                        this.passwordChanged = true
                        this.message = "Password successfully changed"
                    })
                    .catch((error) => {
                        this.passwordChanged = false
                        if (error.response) {
                            let errorMessage = error.response.data.data

                            if (errorMessage.includes("TE")) {
                                this.message = this.$t("The link is invalid or expired, please try again by requesting a new email.")
                            } else if (errorMessage.includes("UDNE")) {
                                this.message = this.$t("Can not reset the password of this address.")
                            }
                        } else {
                            this.message = this.$t("Could not contact server, please try again")
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    },

    components: {
        NavBar,
        SweetalertIcon,
        Password,
        ValidationProvider,
        ValidationObserver,
    }
}

</script>


<!-- ======================================================================= -->


<style scoped>
.header {
    height: 10%
}

.navbar.navbar-transparent {
    background-color: rgba(26, 86, 218, 0.89)
}
</style>


<!-- ======================================================================= -->
<!-- End of file -->
<!-- ======================================================================= -->
