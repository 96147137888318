//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/07/25 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


import Vue from "vue"
import VueNoty from "vuejs-noty"
import "vuejs-noty/dist/vuejs-noty.css"


//------------------------------------------------------------------------------


export function setupNoty() {
    Vue.use(VueNoty, {
        theme: "relax",
        layout: "bottomRight",
        timeout: 6000,
    })
}


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
