<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2020/10/07
@copyright EVITECH
===============================================================================

=========================================================================== -->


<template>

    <div class="container-fluid">

        <h3 class="mt-0">

            {{ $t("Total cameras registered : {nbCameras}", { nbCameras: cameras.length }) }}

        </h3>


        <div class="w3-row mx-0 py-2 border align-items-center text-center table-header">


            <div class="w3-col" style="width: 200px;">
                {{ $t("Manufacturer") }}
            </div>


            <div class="w3-col" style="width: 200px">
                {{ $t("Model") }}
            </div>


            <div class="w3-col" style="width: 200px">
                {{ $t("Sensor type") }}
            </div>


            <div class="w3-col" style="width: 120px">
                {{ $t("Mobile") }}

            </div>


            <div class="w3-col" style="width: 250px">
                {{ $t("Sensor details") }}
            </div>


            <div class="w3-col w3-right" style="width: 250px">
                {{ $t("Actions") }}
            </div>


            <div class="w3-col w3-right" style="width: 120px">
                {{ $t("N° used") }}
            </div>


            <div class="w3-rest">
                {{ $t("FOV") }}
            </div>

        </div>


        <div class="w3-row mx-0 py-2 border align-items-center text-center table-filters">


            <div class="w3-col" style="width: 200px; margin-top: 22px;">

                <input
                    type="text"
                    :placeholder="$t('Contains')"
                    class="form-control border"
                    v-model="filters.manufacturerFilter"
                />

            </div>


            <div class="w3-col" style="width: 200px; margin-top: 22px;">

                <input
                    type="text"
                    :placeholder="$t('Contains')"
                    class="form-control border"
                    v-model="filters.modelFilter"
                />

            </div>


            <div class="w3-col" style="width: 200px; margin-top: 22px;">

                <select
                    class="form-select"
                    style="font-size: 16px; height: 46px; margin-bottom: 5px; padding: 13px 12px;"
                    v-model="filters.typeFilter"
                >

                    <option :value="''">
                        {{ $t("No filter") }}
                    </option>


                    <option value="1">
                        {{ $t("Visible") }}
                    </option>


                    <option value="2">
                        {{ $t("Thermal") }}
                    </option>


                    <option value="3">
                        {{ $t("Thermographic") }}
                    </option>

                </select>

            </div>


            <div class="w3-col" style="width: 120px">

                <div>
                    {{ $t("Filter") }}
                </div>


                <div>
                    <toggle-button
                        class="my-auto"
                        v-model="filters.mobileFilter.active"
                        :width="50"
                        :height="20"
                    />
                </div>


                <div v-show="filters.mobileFilter.active">

                    <div v-if="filters.mobileFilter.value">
                        {{ $t("is mobile") }}
                    </div>


                    <div v-else>
                        {{ $t("is fix") }}
                    </div>


                    <toggle-button
                        class="my-auto"
                        v-model="filters.mobileFilter.value"
                        :width="50"
                        :height="20"
                    />

                </div>

            </div>


            <div class="w3-col" style="width: 250px">

                <div class="form-inline">

                    <div class="input-group">

                        <span
                            id="filter-width"
                            class="input-group-text sensor-detail"
                        >
                            {{ $t("Width") }}
                        </span>


                        <input
                            type="text"
                            :placeholder="$t('min - max')"
                            class="col form-control border"
                            aria-describedby="filter-width"
                            v-model="filters.detailsFilter.width"
                        />

                    </div>


                    <div class="input-group">

                        <span
                            id="filter-height"
                            class="input-group-text sensor-detail"
                        >
                            {{ $t("Height") }}
                        </span>


                        <input
                            type="text"
                            :placeholder="$t('min - max')"
                            class="col form-control border"
                            aria-describedby="filter-height"
                            v-model="filters.detailsFilter.height"
                        />

                    </div>

                </div>

            </div>


            <div class="w3-col w3-right" style="width: 250px; padding-left: 95px; margin-top: 20px;">

                <button
                    class="btn evdesign-btn action-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('Reset filters')"
                    @click="resetFilters()"
                    :disabled="!hasFilters"
                >
                    <i class="fas fa-times fa-2x"></i>
                </button>

            </div>


            <div class="w3-col w3-right" style="width: 120px; margin-left: 13px;">

                <div>
                    {{ $t("Filter") }}
                </div>


                <toggle-button
                    class="my-auto"
                    v-model="filters.usedSort.active"
                    :width="50"
                    :height="20"
                />


                <div v-show="filters.usedSort.active">

                    <i
                        class="cursorPointer fas pt-3"
                        :class="getSortArrow()"
                        @click="switchUsedSort"
                    ></i>

                </div>

            </div>


            <div class="w3-rest">

                <div class="form-inline d-flex">

                    <div class="input-group aov-info">

                        <span
                            id="filter-AOV-H-min"
                            class="input-group-text aov-detail"
                        >
                            {{ $t("horiz min") }}
                        </span>


                        <input
                            type="text"
                            :placeholder="'-'"
                            class="form-control border"
                            aria-describedby="filter-AOV-H-min"
                            v-model="filters.fovFilter.h_min"
                        />

                    </div>


                    <div class="input-group aov-info">

                        <span
                            id="filter-AOV-H-max"
                            class="input-group-text aov-detail"
                        >
                            {{ $t("horiz max") }}
                        </span>


                        <input
                            type="text"
                            :placeholder="'-'"
                            class="form-control border"
                            aria-describedby="filter-AOV-H-max"
                            v-model="filters.fovFilter.h_max"
                        />

                    </div>

                </div>


                <div class="form-inline d-flex">

                    <div class="input-group aov-info">

                        <span
                            id="filter-AOV-V-min"
                            class="input-group-text aov-detail"
                        >
                            {{ $t("vert min") }}
                        </span>


                        <input
                            type="text"
                            :placeholder="'-'"
                            class="form-control border"
                            aria-describedby="filter-AOV-V-min"
                            v-model="filters.fovFilter.v_min"
                        />

                    </div>


                    <div class="input-group aov-info">

                        <span
                            id="filter-AOV-V-max"
                            class="input-group-text aov-detail"
                        >
                            {{ $t("vert max") }}
                        </span>


                        <input
                            type="text"
                            :placeholder="'-'"
                            class="form-control border"
                            aria-describedby="filter-AOV-V-max"
                            v-model="filters.fovFilter.v_max"
                        />

                    </div>

                </div>

            </div>

        </div>


        <div class="camera-table">

            <Camera
                @created="createCamera"
            />


            <Camera
                v-for="(camera, index) in camerasFiltered"
                :key="camera.factory_camera_id"
                :camera="camera"
                :class="{'pair-row': index % 2 === 0}"
                @updated="updateCamera"
                @deleted="deleteCamera"
            />

        </div>

    </div>

</template>


<!-- ====================================================================== -->


<script>


import { ToggleButton } from "vue-js-toggle-button"

import { FR } from "@/i18n/i18n_languages"

import Camera from "./camera_row"
import translationFR from "./i18n/FR/translation"


//-----------------------------------------------------------------------------


let _ = require("lodash")


const DEFAULT_FILTERS = {
    manufacturerFilter: "",
    modelFilter: "",
    typeFilter: "",
    mobileFilter: {
        active: false,
        value: false,
    },
    detailsFilter: {
        width: "",
        height: "",
    },
    fovFilter: {
        h_min: "",
        h_max: "",
        v_min: "",
        v_max: "",
    },
    usedSort: {
        active: false,
        value: "des",
    },
}


//-----------------------------------------------------------------------------


export default {
    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    data: function() {
        return {
            cameras: [],
            filters: undefined,
        }
    },

    computed: {
        camerasFiltered: function() {
            let res = this.cameras

            res = this.applyManufacturerFilter(res)
            res = this.applyModelFilter(res)
            res = this.applyTypeFilter(res)
            res = this.applyMobileFilter(res)
            res = this.applyDetailsFilter(res)
            res = this.applyFOVFilters(res)
            res = this.applyAlphabeticSort(res)
            res = this.applyUsedSort(res)

            return res
        },

        hasFilters: function() {
            return !_.isEqual(DEFAULT_FILTERS, this.filters)
        },
    },

    beforeMount: function() {
        this.getCameras()
        this.resetFilters()
    },

    methods: {
        getCameras: function() {
            this.axios({
                method: "get",
                url: "evdesign/cameras_management/get_cameras",
                params: {},
                type: "json",
            })
                .then((response) => {
                    let data = response.data
                    this.cameras = data["cameras"]
                })
                .catch((error) => {
                    console.log("get cameras error", error)
                })
        },

        updateCamera: function(updatedCamera) {
            let index = _.findIndex(this.cameras, (camera) => {
                return camera.factory_camera_id === updatedCamera.factory_camera_id
            })
            this.cameras.splice(index, 1, updatedCamera)
        },

        createCamera: function(newCamera) {
            this.cameras.push(newCamera)
        },

        deleteCamera: function(cameraId) {
            this.cameras = _.remove(this.cameras, (camera) => {
                return camera.factory_camera_id !== cameraId
            })
        },

        getCaret: function() {
            if (this.displayFilter) {
                return "fa-caret-up"
            }
            return "fa-caret-down"
        },

        switchUsedSort: function() {
            if (this.filters.usedSort.value === "asc") {
                this.filters.usedSort.value = "des"
            } else {
                this.filters.usedSort.value = "asc"
            }
        },

        getSortArrow: function() {
            if (this.filters.usedSort.value === "asc") {
                return "fa-arrow-up"
            } else {
                return "fa-arrow-down"
            }
        },

        applyManufacturerFilter: function(res) {
            if (this.filters.manufacturerFilter) {
                res = _.filter(res, (camera) => {
                    return camera.manufacturer.toLowerCase().includes(this.filters.manufacturerFilter.toLowerCase())
                })
            }
            return res
        },

        applyModelFilter: function(res) {
            if (this.filters.modelFilter) {
                res = _.filter(res, (camera) => {
                    return camera.model.toLowerCase().includes(this.filters.modelFilter.toLowerCase())
                })
            }
            return res
        },

        applyTypeFilter: function(res) {
            if (this.filters.typeFilter) {
                res = _.filter(res, (camera) => {
                    return camera.sensor_type === parseInt(this.filters.typeFilter)
                })
            }
            return res
        },

        applyMobileFilter: function(res) {
            if (this.filters.mobileFilter.active) {
                res = _.filter(res, (camera) => {
                    return camera.is_mobile === this.filters.mobileFilter.value
                })
            }
            return res
        },

        applyDetailsFilter: function(res) {
            if (this.filters.detailsFilter.width) {
                let values = this.filters.detailsFilter.width.split(" - ")
                let min = parseInt(values[0])
                let max = parseInt(values[1])

                if (min && !max) {
                    res = _.filter(res, (camera) => {
                        return camera.sensor_width === min
                    })
                } else if (min) {
                    res = _.filter(res, (camera) => {
                        return camera.sensor_width >= min
                    })
                }

                if (max) {
                    res = _.filter(res, (camera) => {
                        return camera.sensor_width <= max
                    })
                }
            }

            if (this.filters.detailsFilter.height) {
                let values = this.filters.detailsFilter.height.split(" - ")
                let min = parseInt(values[0])
                let max = parseInt(values[1])

                if (min && !max) {
                    res = _.filter(res, (camera) => {
                        return camera.sensor_height === min
                    })
                } else if (min) {
                    res = _.filter(res, (camera) => {
                        return camera.sensor_height >= min
                    })
                }

                if (max) {
                    res = _.filter(res, (camera) => {
                        return camera.sensor_height <= max
                    })
                }
            }
            return res
        },

        applyFOVFilters: function(res) {
            if (this.filters.fovFilter.h_min) {
                let min = parseInt(this.filters.fovFilter.h_min)
                res = _.filter(res, (camera) => {
                    return camera.aov_h_min >= min
                })

                if (this.filters.fovFilter.h_max) {
                    let max = parseInt(this.filters.fovFilter.h_max)
                    res = _.filter(res, (camera) => {
                        return camera.aov_h_max >= max || camera.aov_h_min >= max
                    })
                }
            }

            if (this.filters.fovFilter.v_min) {
                let min = parseInt(this.filters.fovFilter.v_min)
                res = _.filter(res, (camera) => {
                    return camera.aov_v_min >= min
                })

                if (this.filters.fovFilter.v_max) {
                    let max = parseInt(this.filters.fovFilter.v_max)
                    res = _.filter(res, (camera) => {
                        return camera.aov_v_max >= max || camera.aov_v_min >= max
                    })
                }
            }

            return res
        },

        applyUsedSort: function(res) {
            if (this.filters.usedSort.active) {
                res = _.sortBy(res, (camera) => {
                    return camera.used_counter
                })

                if (this.filters.usedSort.value === "des") {
                    res = _.reverse(res)
                }
            }
            return res
        },

        applyAlphabeticSort: function(res) {
            res = _.sortBy(res, ["manufacturer", "model"])
            return res
        },

        resetFilters: function() {
            this.filters = _.cloneDeep(DEFAULT_FILTERS)
        },
    },

    components: {
        Camera,
        ToggleButton,
    },
}

</script>


<!-- ====================================================================== -->


<style scoped>

.table-header {
    background-color: rgb(190, 190, 190);
}

.table-filters {
    background-color: rgb(220, 220, 220);
}

.sensor-detail {
    width: 80px !important;
}

.w3-col {
    padding-left: 15px;
    padding-right: 15px;
}

.input-group,
.input-group-text {
    height: 46px;
}

.aov-info {
    width: 50% !important;
}

.aov-detail {
    width: 90px !important;
}

.action-btn {
    width: 50px !important;
}

.camera-table {
    overflow-y: scroll;
    max-height: calc(-350px + 100vh) !important;
}

.pair-row {
    background-color: rgb(245, 245, 245);
}

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
