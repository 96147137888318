<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/09/28
@copyright EVITECH
===============================================================================

Carte

=========================================================================== -->

<template>

    <div class="mapContainer">

        <GmapMap
            v-observe-visibility="isMapVisible"
            id="gmap"
            ref="gmap"
            :options="mapOptions"
            :center="center"
            @click="getClickedLocation"
        >

            <div v-if="isReady">

                <div
                    v-for="(camera, index) in cameras"
                    :key="camera.name"
                >

                    <MarkerWithLabel
                        ref="markerlabel"
                        :icon="getIcon(index)"
                        :position="camera.settings.marker.position"
                        :label-anchor="camera.settings.marker.anchor"
                        :label-content="getMarkerLabel(index)"
                        :label-visible="showCameraLabels"
                        :clickable="true"
                        :draggable="selectedCamera === index"
                        :raiseOnDrag="false"
                        :visible="!currentDragPoly"
                        @click="selectCamera($event, index)"
                        @dragstart="currentDrag = true"
                        @dragend="dragEndCallback($event.latLng, index)"
                        @drag="dragMarker($event.latLng, index)"
                    />

                    <GmapPolygon
                        ref="polygon"
                        :paths="[camera.settings.path]"
                        :editable="false"
                        :options="getZoneOptions(camera)"
                        :clickable="true"
                        @click="selectCamera($event, index)"
                        :draggable="true"
                        @dragstart="dragStartPolyCallback($event.latLng, index)"
                        @dragend="dragEndPolyCallback($event.latLng, index)"
                        @drag="dragPoly($event.latLng, index)"
                    >
                    </GmapPolygon>


                    <!-- <GmapCircle
                        :center="camera.settings.marker.position"
                        :radius="camera.settings.distance"
                        ref="rotatePath"
                        :visible="true"
                        :options="{fillColor: 'blue', fillOpacity: 0.5, strokeOpacity: 0.5,}"
                    >
                    </GmapCircle> -->


                    <!-- :icon="'static/img/test_rotate_marker.svg'" -->
                    <GmapMarker
                        ref="rotateMarker"
                        :position="camera.settings.rotateMarker"
                        :draggable="true"
                        :visible="selectedCamera === index && !currentDrag"
                        @drag="rotateMarker2($event.latLng, index)"
                        @dragend="onRotateMarkerDragEnd2(index)"
                        :raiseOnDrag="false"
                        :clickable="true"
                        @click="rotateMarkerClick"
                    />

                </div>

            </div>

        </GmapMap>

    </div>

</template>


<!-- ====================================================================== -->

<script>


import { getGoogleMapsAPI } from "gmap-vue"

import { FR } from "@/i18n/i18n_languages"

import MarkerWithLabel from "@/components/app_utils/marker_with_label/markerWithLabel"

import Options from "./map_options"
import translationFR from "./i18n/FR/translation"
import { computeDistances } from "./javascript/compute_camera_distance"


//-------------------------------------


let _ = require("lodash")


//-------------------------------------


export default {
    props: {
        cameras: {
            type: Array,
            default: function() {
                return []
            },
        },

        project: {
            type: Object,
            default: function() {
                return {}
            },
        },

        selectedCamera: {
            type: Number,
            default: 0,
        },

        isChoosingCameraLocation: {
            type: Boolean,
            default: false,
        },

        showCameraLabels: {
            type: Boolean,
            default: false,
        },

        projectSettingsDisplayed: {
            type: Boolean,
            default: false,
        },
    },

    data: function() {
        return {
            mapOptions: Options,
            center: {},
            zoneOptions: {
                strokeColor: "#0F93FF",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillOpacity: 0.35,
            },
            gmapLoaded: false,
            mapVisible: false,
            areasComputed: false,

            currentDrag: false,
            currentDragPoly: false,

            imageMap: undefined,
            imageBounds: undefined,
            image: undefined,
            imageWidth: 0,
            imageHeight: 0,
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    async beforeMount() {
        let self = this
        await this.$gmapApiPromiseLazy().then(() => {
            self.gmapLoaded = true
            self.initMapOptions()
            self.initAreas2()
        })
    },

    watch: {
        isChoosingCameraLocation: {
            handler(value) {
                this.currentDrag = value
            },
        },

        showCameraLabels: {
            handler() {
                this.cameras.forEach((camera, index) => {
                    this.$refs.markerlabel[index].$markerWithLabelObject.labelVisible = this.showCameraLabels
                    this.$refs.gmap.$mapObject.setZoom(this.$refs.gmap.$mapObject.getZoom())
                })
            },
        },

        selectedCamera: {
            handler() {
                let self = this
                setTimeout(() => {
                    self.computeCameraAov2(this.selectedCamera)
                    self.drawAOV(this.selectedCamera)
                    self.updateRotateMarker2(this.selectedCamera)
                }, 50)
            },
        },

        cameras: {
            handler() {
                let self = this
                setTimeout(() => {
                    self.cameras.forEach((camera, index) => {
                        self.setPolygonOptions(index, camera)
                        self.computeCameraAov2(index)
                        self.drawAOV(index)
                        if (self.selectedCamera === index) {
                            self.onRotateMarkerDragEnd2(index)
                        }
                    })
                }, 50)
            },
            deep: true,
            immediate: false,
        },

        project: {
            handler() {
                let self = this
                setTimeout(() => {
                    self.cameras.forEach((camera, index) => {
                        self.setPolygonOptions(index, camera)
                        self.computeCameraAov2(index)
                        self.drawAOV(index)
                        if (self.selectedCamera === index) {
                            self.onRotateMarkerDragEnd2(index)
                        }
                    })

                }, 50)
            },
            deep: true,
            immediate: false,
        },

        projectSettingsDisplayed: {
            handler(value) {
                this.updateRotateMarker2(this.selectedCamera)
                this.currentDrag = value
            },
        },
    },

    computed: {
        google: getGoogleMapsAPI,

        isReady: function() {
            let isReady = this.gmapLoaded && this.mapVisible && this.areasComputed
            return isReady
        },
    },

    methods: {
        getZoneOptions: function(camera) {
            let options = _.cloneDeep(this.zoneOptions)
            options.fillColor = camera.settings.fov_color
            return options
        },

        getMapFullRecap: function() {
            let bounds = this.getCamerasBounds()
            this.$refs.gmap.$mapObject.fitBounds(bounds)

            // let mapBounds = this.$refs.gmap.$mapObject.getBounds()

            // var m1 = new google.maps.Marker({
            //     position: mapBounds.getNorthEast(),
            //     title: "ne",
            // })
            // m1.setMap(this.$refs.gmap.$mapObject)

            // var m2 = new google.maps.Marker({
            //     position: mapBounds.getSouthWest(),
            //     title: "sw",
            // })
            // m2.setMap(this.$refs.gmap.$mapObject)

            // this.$refs.gmap.$mapObject


            let zoom = this.getReportZoom()
            let imageBounds = this.getImageBounds()

            let data
            if (this.project.settings.type === 1) {
                let ne = imageBounds.getNorthEast()
                let sw = imageBounds.getSouthWest()

                // eslint-disable-next-line
                let nw = new google.maps.LatLng({
                    lat: ne.lat(),
                    lng: sw.lng(),
                })
                // eslint-disable-next-line
                let se = new google.maps.LatLng({
                    lat: sw.lat(),
                    lng: ne.lng(),
                })

                // eslint-disable-next-line
                let backgroundWidthInMeter = google.maps.geometry.spherical.computeDistanceBetween(nw, ne)
                // eslint-disable-next-line
                let backgroundHeightInMeter = google.maps.geometry.spherical.computeDistanceBetween(se, ne)

                data = {
                    cameras: this.getCamerasData(),
                    imageURL: this.getMapImage(zoom, imageBounds),
                    // imageCenter: center.toJSON(),
                    zoom: zoom,
                    imageNEBound: ne.toJSON(),
                    imageSWBound: sw.toJSON(),
                    backgroundWidthInMeter: backgroundWidthInMeter,
                    backgroundHeightInMeter: backgroundHeightInMeter,
                    markerColor: "d4830b99", // copier/coller
                    edgesColor: this.zoneOptions.strokeColor.replaceAll("#", "").toLowerCase() + "cc",
                    // fillColor: this.zoneOptions.fillColor.replaceAll("#", "").toLowerCase() + "59",
                }
            } else if (this.project.settings.type === 2) {
                let ne = this.imageBounds.getNorthEast()
                let sw = this.imageBounds.getSouthWest()

                // eslint-disable-next-line
                let nw = new google.maps.LatLng({
                    lat: ne.lat(),
                    lng: sw.lng(),
                })
                // eslint-disable-next-line
                let se = new google.maps.LatLng({
                    lat: sw.lat(),
                    lng: ne.lng(),
                })


                // // eslint-disable-next-line
                // new google.maps.Marker({
                //     position: sw,
                //     title: "sw",
                // }).setMap(this.$refs.gmap.$mapObject)

                // // eslint-disable-next-line
                // new google.maps.Marker({
                //     position: ne,
                //     title: "ne",
                // }).setMap(this.$refs.gmap.$mapObject)

                // // eslint-disable-next-line
                // new google.maps.Marker({
                //     position: nw,
                //     title: "nw",
                // }).setMap(this.$refs.gmap.$mapObject)

                // // eslint-disable-next-line
                // new google.maps.Marker({
                //     position: se,
                //     title: "se",
                // }).setMap(this.$refs.gmap.$mapObject)


                // eslint-disable-next-line
                let backgroundWidthInMeter = google.maps.geometry.spherical.computeDistanceBetween(nw, ne)
                // eslint-disable-next-line
                let backgroundHeightInMeter = google.maps.geometry.spherical.computeDistanceBetween(se, ne)
                data = {
                    cameras: this.getCamerasData(),
                    imageURL: this.getMapImage(zoom, imageBounds),
                    imageNEBound: this.imageBounds.getNorthEast().toJSON(),
                    imageSWBound: this.imageBounds.getSouthWest().toJSON(),
                    backgroundWidthInMeter: backgroundWidthInMeter,
                    backgroundHeightInMeter: backgroundHeightInMeter,

                    markerColor: "d4830b99", // copier/coller
                    edgesColor: this.zoneOptions.strokeColor.replaceAll("#", "").toLowerCase() + "cc",
                    // fillColor: this.zoneOptions.fillColor.replaceAll("#", "").toLowerCase() + "59",
                }
            }

            return data
        },

        getReportZoom: function() {
            // On calcule le zoom nécessaire pour avoir l'ensemble des infos sur une image de 640x640

            let center = this.$refs.gmap.$mapObject.getCenter()
            let camerasBounds = this.getCamerasBounds()

            // // eslint-disable-next-line
            // new google.maps.Marker({
            //     position: camerasBounds.getSouthWest(),
            //     title: "sw",
            // }).setMap(this.$refs.gmap.$mapObject)

            // // eslint-disable-next-line
            // new google.maps.Marker({
            //     position: camerasBounds.getNorthEast(),
            //     title: "ne",
            // }).setMap(this.$refs.gmap.$mapObject)

            // eslint-disable-next-line
            let topX = new google.maps.LatLng({
                lat: center.lat(),
                lng: camerasBounds.getNorthEast().lng(),
            })
            // eslint-disable-next-line
            let metersX = google.maps.geometry.spherical.computeDistanceBetween(center, topX)

            // // eslint-disable-next-line
            // new google.maps.Marker({
            //     position: topX,
            //     title: "topx",
            // }).setMap(this.$refs.gmap.$mapObject)


            // eslint-disable-next-line
            let topY = new google.maps.LatLng({
                lat: camerasBounds.getNorthEast().lat(),
                lng: center.lng(),
            })
            // eslint-disable-next-line
            let metersY = google.maps.geometry.spherical.computeDistanceBetween(center, topY)

            // // eslint-disable-next-line
            // new google.maps.Marker({
            //     position: topY,
            //     title: "topY",
            // }).setMap(this.$refs.gmap.$mapObject)


            let meters = Math.max(metersX, metersY) / (640 / 2)
            let zoom = Math.floor(Math.log((156543.03392 * Math.cos(center.lat() * Math.PI / 180)) / meters) / Math.log(2))

            return zoom
        },

        getImageBounds: function() {
            let center = this.$refs.gmap.$mapObject.getCenter()
            let zoom = this.getReportZoom()

            // Pour  matcher avec l'image que renvoie GoogleMap Static
            let imageMetersPerPx = 156543.03392 * Math.cos(center.lat() * Math.PI / 180) / Math.pow(2, zoom)
            let imageMeters = Math.sqrt(2) * imageMetersPerPx * (640 / 2)
            // eslint-disable-next-line
            let imageBounds = new google.maps.LatLngBounds(
                // eslint-disable-next-line
                google.maps.geometry.spherical.computeOffset(center, imageMeters, -135),
                // eslint-disable-next-line
                google.maps.geometry.spherical.computeOffset(center, imageMeters, 45),
            )

            // // eslint-disable-next-line
            // new google.maps.Marker({
            //     position: imageBounds.getNorthEast(),
            //     title: "BOUNDS NE",
            // }).setMap(this.$refs.gmap.$mapObject)

            // // eslint-disable-next-line
            // new google.maps.Marker({
            //     position: imageBounds.getSouthWest(),
            //     title: "BOUNDS SW",
            // }).setMap(this.$refs.gmap.$mapObject)

            return imageBounds
        },

        getCamerasBounds: function() {
            let minLng
            let maxLng
            let minLat
            let maxLat

            this.cameras.forEach(cam => {
                cam.settings.maxPoints.forEach(point => {
                    if (minLng) {
                        if (minLng > point.lng()) {
                            minLng = point.lng()
                        }
                    } else {
                        minLng = point.lng()
                    }

                    if (maxLng) {
                        if (maxLng < point.lng()) {
                            maxLng = point.lng()
                        }
                    } else {
                        maxLng = point.lng()
                    }

                    if (minLat) {
                        if (minLat > point.lat()) {
                            minLat = point.lat()
                        }
                    } else {
                        minLat = point.lat()
                    }

                    if (maxLat) {
                        if (maxLat < point.lat()) {
                            maxLat = point.lat()
                        }
                    } else {
                        maxLat = point.lat()
                    }
                })

                if (minLng > cam.settings.marker.position.lng()) {
                    minLng = cam.settings.marker.position.lng()
                }

                if (maxLng < cam.settings.marker.position.lng()) {
                    maxLng = cam.settings.marker.position.lng()
                }

                if (minLat > cam.settings.marker.position.lat()) {
                    minLat = cam.settings.marker.position.lat()
                }

                if (maxLat < cam.settings.marker.position.lat()) {
                    maxLat = cam.settings.marker.position.lat()
                }
            })

            // eslint-disable-next-line
            let sw = new google.maps.LatLng({
                lng: minLng,
                lat: minLat,
            })

            // eslint-disable-next-line
            let ne = new google.maps.LatLng({
                lng: maxLng,
                lat: maxLat,
            })

            let boundsMargin = 50
            // eslint-disable-next-line
            let bounds = new google.maps.LatLngBounds(
                // eslint-disable-next-line
                google.maps.geometry.spherical.computeOffset(sw, boundsMargin, -135),
                // eslint-disable-next-line
                google.maps.geometry.spherical.computeOffset(ne, boundsMargin, 45),
            )


            // TODO CAS DE L'IMAGE
            // if (this.project.settings.type === 2) {


            // var m1 = new google.maps.Marker({
            //     position: bounds.getNorthEast(),
            //     title:"ne",
            // })
            // m1.setMap(this.$refs.gmap.$mapObject)

            // var m2 = new google.maps.Marker({
            //     position: bounds.getSouthWest(),
            //     title:"sw",
            // })
            // m2.setMap(this.$refs.gmap.$mapObject)

            // var m3 = new google.maps.Marker({
            //     position: {
            //         lng: bounds.getNorthEast().lng(),
            //         lat: bounds.getSouthWest().lat(),
            //     },
            //     title:"nw",
            // })
            // m3.setMap(this.$refs.gmap.$mapObject)

            // var m4 = new google.maps.Marker({
            //     position: {
            //         lng: bounds.getSouthWest().lng(),
            //         lat: bounds.getNorthEast().lat(),
            //     },
            //     title:"se",
            // })
            // m4.setMap(this.$refs.gmap.$mapObject)


            return bounds
        },

        getCamerasData: function() {
            let data = []
            for (let camera of this.cameras) {
                data.push({
                    "cameraID": camera.id,
                    "name": camera.settings.name,
                    "manufacturer": camera.manufacturer,
                    "model": camera.model,
                    "marker": camera.settings.marker.position.toJSON(),
                    "orientation": camera.settings.orientation,
                    "distanceMin": camera.settings.distanceMin,
                    "distanceMinTargetPixel": camera.settings.distanceMinTargetPixel,
                    "distanceMax": camera.settings.distance,
                    "distanceMaxTargetPixel": camera.settings.distanceMaxTargetPixel,
                    "aov_h": camera.settings.aov_h,
                    "aov_v": camera.settings.aov_v,
                    "height": camera.settings.height,
                    "tilt": camera.settings.tilt,
                    "sensor_width": camera.sensor_width,
                    "sensor_height": camera.sensor_height,
                    "is_in_portrait_position": camera.settings.is_in_portrait_position,
                    "path": camera.settings.path.map(x => x.toJSON()),
                    "fov_color": camera.settings.fov_color.replaceAll("#", "").toLowerCase() + "59",
                })
            }
            return data
        },

        getMapImage: function(zoom, imageBounds) {
            this.centerMap()

            let center = this.$refs.gmap.$mapObject.getCenter()

            let ne = imageBounds.getNorthEast()
            let sw = imageBounds.getSouthWest()

            let patchZoom = zoom + 1

            let lngStep = ne.lng() - center.lng()
            let latStep = (ne.lat() - center.lat()) / 2

            let patchs = {}
            for (let i in _.range(2)) {
                for (let j in _.range(3)) {
                    // eslint-disable-next-line
                    let localSW = new google.maps.LatLng({
                        lng: sw.lng() + (i * lngStep),
                        lat: sw.lat() + (j * latStep),
                    })
                    // eslint-disable-next-line
                    let localNE = new google.maps.LatLng({
                        lng: center.lng() + (i * lngStep),
                        lat: center.lat() + (j * latStep),
                    })

                    let title = i + "" + j

                    // eslint-disable-next-line
                    let patchBounds = new google.maps.LatLngBounds(
                        localSW,
                        localNE,
                    )

                    let patchMapURL = this.getMapStaticURL()
                    patchMapURL += this.getMapSizeParam()
                    patchMapURL += this.getMapTypeParam()
                    patchMapURL += this.getMapZoomParam(patchZoom)
                    // patchMapURL += this.getMapCameraParam(camera)
                    patchMapURL += this.getMapVisibleParam(patchBounds)
                    patchMapURL += this.getMapKeyParam()

                    patchs[title] = {
                        "url": patchMapURL,
                    }
                }
            }

            return patchs
        },

        getMapStaticURL: function() {
            return "https://maps.googleapis.com/maps/api/staticmap?"
        },

        getMapSizeParam: function() {
            let height = 640
            let width = 640

            return "size=" + width + "x" + height + "&"
        },

        getMapTypeParam: function() {
            return "maptype=" + this.$refs.gmap.$mapObject.getMapTypeId() + "&"
        },

        getMapZoomParam: function(zoom) {
            let mapURL = "zoom=" + zoom + "&"
            mapURL += "scale=1&"
            return mapURL
        },

        getMapVisibleParam: function(bounds) {
            let ne = bounds.getNorthEast()
            let sw = bounds.getSouthWest()
            let param = "visible=" +
            ne.lat() + "%2C" + ne.lng() +
            "|" +
            sw.lat() + "%2C" + sw.lng() +
            "&"
            // let center = this.$refs.gmap.$mapObject.getCenter()
            // let param = "center=" + center.lat() + "," + center.lng() + "&"
            return param
        },

        // getMapCameraParam: function(cam) {
        //     let camParam = "markers="
        //     camParam += "color:0xd4830b" + "99" + "|" // copier/coller
        //     camParam += "size:tiny" + "|"
        //     camParam += cam.settings.marker.position.lat() + "%2C" + cam.settings.marker.position.lng()
        //     camParam += "&"

        //     camParam += "path="
        //     camParam += "color:0x" + this.zoneOptions.strokeColor.replaceAll("#", "").toUpperCase() + "CC" + "|"
        //     camParam += "weight:" + this.zoneOptions.strokeWeight + "|"
        //     camParam += "fillcolor:0x" + this.zoneOptions.fillColor.replaceAll("#", "").toUpperCase() + "59"

        //     let path = cam.settings.path
        //     for (let point of path) {
        //         camParam += "|" + point.lat() + "%2C" + point.lng()
        //     }
        //     camParam += "&"

        //     return camParam
        // },

        getMapKeyParam: function() {
            return `key=${this.$refs.gmap.$gmapOptions.load.key}`
        },

        initMapOptions: function() {
            if (this.project.settings.type === 2) {
                this.mapOptions.mapTypeControl = false
                this.mapOptions.mapTypeId = "roadmap"

                this.mapOptions.maxZoom = 20
                this.mapOptions.minZoom = 12
                this.mapOptions.zoom = this.project.settings.scale

                // eslint-disable-next-line
                this.center = new google.maps.LatLng({
                    lat: 40.864738,
                    lng: -152.705974,
                })
                // this.center = new google.maps.LatLng({
                //     lat: this.project.coordinate.coordinate[0],
                //     lng: this.project.coordinate.coordinate[1],
                // })
            } else {
                this.mapOptions.mapTypeControl = true
                this.mapOptions.mapTypeId = "satellite"

                this.mapOptions.maxZoom = 20
                this.mapOptions.minZoom = 12
                this.mapOptions.zoom = this.project.settings.scale

                // eslint-disable-next-line
                this.center = new google.maps.LatLng({
                    lat: this.project.coordinate.coordinate[0],
                    lng: this.project.coordinate.coordinate[1],
                })
            }
        },

        loadImage: function() {
            let self = this
            // eslint-disable-next-line
            google.maps.event.addListener(self.$refs.gmap.$mapObject, "bounds_changed", function() {
                self.image = new Image()
                self.image.onload = function() {
                    self.imageWidth = this.width
                    self.imageHeight = this.height
                    self.imageBounds = self.$refs.gmap.$mapObject.getBounds()
                    self.setImageBounds()
                }
                let source
                if (self.axios.defaults.devMode) {
                    source = self.axios.defaults.mediaURL + self.project.settings.image
                } else {
                    source = self.axios.defaults.serverURL + self.project.settings.image
                }
                self.image.src = source
                // eslint-disable-next-line
                google.maps.event.clearListeners(self.$refs.gmap.$mapObject, "bounds_changed")
            })
            this.$refs.gmap.$mapObject.setZoom(this.$refs.gmap.$mapObject.getZoom())
        },

        setImageBounds: function() {
            // https://developers.google.com/maps/documentation/javascript/customoverlays
            // eslint-disable-next-line
            class USGSOverlay extends google.maps.OverlayView {
                constructor(bounds, image) {
                    super()
                    // Initialize all properties.
                    this.bounds_ = bounds
                    this.image_ = image
                    // Define a property to hold the image's div. We'll
                    // actually create this div upon receipt of the onAdd()
                    // method so we'll leave it null for now.
                    this.div_ = null
                }
                /**
                 * onAdd is called when the map's panes are ready and the overlay has been
                 * added to the map.
                 */
                onAdd() {
                    this.div_ = document.createElement("div")
                    this.div_.style.borderStyle = "none"
                    this.div_.style.borderWidth = "0px"
                    // this.div_.style.backgroundColor = "grey"
                    this.div_.style.position = "absolute"
                    // Create the img element and attach it to the div.
                    const img = document.createElement("img")
                    img.src = this.image_
                    img.style.width = "100%"
                    // img.style.height = "100%"
                    img.style.position = "absolute"
                    // img.style.backgroundColor = "green"
                    this.div_.appendChild(img)
                    // Add the element to the "overlayLayer" pane.
                    const panes = this.getPanes()
                    panes.overlayLayer.appendChild(this.div_)
                }
                draw() {
                    // We use the south-west and north-east
                    // coordinates of the overlay to peg it to the correct position and size.
                    // To do this, we need to retrieve the projection from the overlay.
                    const overlayProjection = this.getProjection()
                    // Retrieve the south-west and north-east coordinates of this overlay
                    // in LatLngs and convert them to pixel coordinates.
                    // We'll use these coordinates to resize the div.
                    const sw = overlayProjection.fromLatLngToDivPixel(
                        this.bounds_.getSouthWest()
                    )
                    const ne = overlayProjection.fromLatLngToDivPixel(
                        this.bounds_.getNorthEast()
                    )

                    // Resize the image's div to fit the indicated dimensions.
                    if (this.div_) {
                        this.div_.style.left = sw.x + "px"
                        this.div_.style.top = ne.y + "px"
                        this.div_.style.width = ne.x - sw.x + "px"
                        this.div_.style.height = sw.y - ne.y + "px"
                    }
                }
                /**
                 * The onRemove() method will be called automatically from the API if
                 * we ever set the overlay's map property to 'null'.
                 */
                onRemove() {
                    if (this.div_) {
                        this.div_.parentNode.removeChild(this.div_)
                        this.div_ = null
                    }
                }
            }

            let imageDistance = this.computeImageScale()

            // eslint-disable-next-line
            let origin = new google.maps.LatLng({
                lng: this.center.lng(),
                lat: this.center.lat(),
            })

            // eslint-disable-next-line
            let widthPosition = google.maps.geometry.spherical.computeOffset(origin, imageDistance.width, 90)

            // eslint-disable-next-line
            let heightPosition = google.maps.geometry.spherical.computeOffset(widthPosition, imageDistance.height, 0)

            // eslint-disable-next-line
            this.imageBounds = new google.maps.LatLngBounds(origin, heightPosition)

            this.imageBackground = new USGSOverlay(
                this.imageBounds,
                new URL(this.image.src),
            )

            this.imageBackground.setMap(this.$refs.gmap.$mapObject)

            // Restrictions de déplacements
            let self = this
            var lastValidCenter = this.$refs.gmap.$mapObject.getCenter()
            // eslint-disable-next-line
            google.maps.event.addListener(this.$refs.gmap.$mapObject, "center_changed", function() {
                if (self.imageBounds.contains(self.$refs.gmap.$mapObject.getCenter())) {
                    lastValidCenter = self.$refs.gmap.$mapObject.getCenter()
                    return
                }
                self.$refs.gmap.$mapObject.panTo(lastValidCenter)
            })

            this.$refs.gmap.$mapObject.fitBounds(this.imageBounds)
        },

        computeImageScale: function() {
            let distance = this.project.settings.imageScale

            let firstPointPos = {
                x: parseFloat(this.project.settings.imageScaleFirstPoint.x),
                y: parseFloat(this.project.settings.imageScaleFirstPoint.y),
            }

            let secondPointPos = {
                x: parseFloat(this.project.settings.imageScaleSecondPoint.x),
                y: parseFloat(this.project.settings.imageScaleSecondPoint.y),
            }

            let l = secondPointPos.x - firstPointPos.x
            let h = secondPointPos.y - firstPointPos.y
            let alpha = Math.atan(h / l)
            let projectionDistance = (Math.cos(alpha) * distance).toFixed(2)

            let imageWidthDistance = (100 * projectionDistance) / l
            let imageHeightDistance = (this.imageHeight * imageWidthDistance) / this.imageWidth

            return { width: imageWidthDistance, height: imageHeightDistance }
        },

        // NE PAS EFFACER !!!

        // updatePath2: function(index) {
        //     let polygonePos = this.$refs.polygon[index].$polygonObject.getPath().getAt(0)
        //     this.cameras[index].settings.path = polygonePos
        //     this.cameras[index].settings.marker.position = polygonePos
        //     // console.log("marker", this.$refs.markerlabel[index].$markerWithLabelObject.getPosition().lat(), this.$refs.markerlabel[index].$markerWithLabelObject.getPosition().lng())
        //     // console.log("polygonePos", polygonePos.lat(), polygonePos.lng())
        //     this.$refs.markerlabel[index].$markerWithLabelObject.setPosition(polygonePos)
        //     this.cameras[index].settings.location.coordinate = [polygonePos.lat(), polygonePos.lng()]
        // },

        // NE PAS EFFACER !!!


        isMapVisible: function(boolean) {
            if (boolean) {
                if (this.project.settings.type === 2) {
                    this.loadImage()
                }
                this.mapVisible = true
                this.addCustomControls()
            }
        },

        initAreas2: function() {
            this.cameras.forEach((camera, index) => {
                camera.settings.marker = {
                    // eslint-disable-next-line
                    position: new google.maps.LatLng({
                        lat: camera.settings.location.coordinate[0],
                        lng: camera.settings.location.coordinate[1],
                    }),
                    // eslint-disable-next-line
                    anchor: new google.maps.Point(-20, 0)
                }
                this.computeCameraAov2(index)
                this.updateRotateMarker2(index)
            })
            this.areasComputed = true
        },

        setDivCSS: function(controlUI) {
            controlUI.style.backgroundColor = "rgba(255, 255, 255, 0.95)"
            controlUI.style.color = "rgb(75, 75, 75)"
            controlUI.style.textAlign = "center"
            controlUI.style.fontFamily = "Roboto, Arial, sans-serif"

            return controlUI
        },

        setButtonCSS: function(controlUI) {
            controlUI.style.cursor = "pointer"
            return controlUI
        },

        setHoverCss: function(controlUI) {
            controlUI.addEventListener("mouseenter", () => {
                controlUI.style.color = "rgb(30, 30, 30)"
            })
            controlUI.addEventListener("mouseleave", () => {
                controlUI.style.color = "rgb(75, 75, 75)"
            })
            return controlUI
        },

        setTopCSS: function(controlUI) {
            controlUI.style.marginTop = "10px"
            return controlUI
        },

        setRightCSS: function(controlUI) {
            controlUI.style.marginRight = "10px"
            return controlUI
        },

        setBottomCSS: function(controlUI) {
            controlUI.style.marginBottom = "17px"
            return controlUI
        },

        setLeftCSS: function(controlUI) {
            controlUI.style.marginLeft = "10px"
            return controlUI
        },

        saveProjectDiv: function(div) {
            let controlUI = document.createElement("div")
            controlUI = this.setDivCSS(controlUI)
            controlUI = this.setButtonCSS(controlUI)
            controlUI = this.setHoverCss(controlUI)
            controlUI = this.setTopCSS(controlUI)
            controlUI.style.paddingTop = "5px"
            controlUI.style.paddingBottom = "5px"
            controlUI.title = this.$t("Save project")
            div.appendChild(controlUI)

            let controlText = document.createElement("div")
            controlText.innerHTML = "<i class='fas fa-3x fa-save' style='width: 50px'> </i>"
            controlUI.appendChild(controlText)

            controlUI.addEventListener("click", () => {
                this.$emit("saveProject")
            })
        },

        exportProjectDiv: function(div) {
            let controlUI = document.createElement("div")
            controlUI = this.setDivCSS(controlUI)
            controlUI = this.setButtonCSS(controlUI)
            controlUI = this.setHoverCss(controlUI)
            controlUI = this.setTopCSS(controlUI)
            controlUI = this.setLeftCSS(controlUI)
            controlUI.style.paddingTop = "5px"
            controlUI.style.paddingBottom = "5px"
            controlUI.title = this.$t("Print the folder")
            div.appendChild(controlUI)

            let controlText = document.createElement("div")
            controlText.innerHTML = "<i class='fas fa-3x fa-file-pdf' style='width: 50px'> </i>"
            controlUI.appendChild(controlText)

            let self = this
            controlUI.addEventListener("click", () => {
                self.$emit("exportFolder")
            })
        },

        exportMapDiv: function(div) {
            let controlUI = document.createElement("div")
            controlUI = this.setDivCSS(controlUI)
            controlUI = this.setButtonCSS(controlUI)
            controlUI = this.setHoverCss(controlUI)
            controlUI = this.setTopCSS(controlUI)
            controlUI = this.setLeftCSS(controlUI)
            controlUI.style.paddingTop = "5px"
            controlUI.style.paddingBottom = "5px"
            controlUI.title = this.$t("Print the picture")
            div.appendChild(controlUI)

            let controlText = document.createElement("div")
            controlText.innerHTML = "<i class='fas fa-3x fa-file-image' style='width: 50px'> </i>"
            controlUI.appendChild(controlText)

            let self = this
            controlUI.addEventListener("click", () => {
                self.$emit("exportPicture")
            })
        },

        projectSettingsDiv: function(div) {
            let controlUI = document.createElement("div")
            controlUI = this.setDivCSS(controlUI)
            controlUI = this.setButtonCSS(controlUI)
            controlUI = this.setHoverCss(controlUI)
            controlUI = this.setTopCSS(controlUI)
            controlUI = this.setLeftCSS(controlUI)
            controlUI.style.paddingTop = "5px"
            controlUI.style.paddingBottom = "5px"
            controlUI.title = this.$t("Project settings")
            div.appendChild(controlUI)

            let controlText = document.createElement("div")
            controlText.innerHTML = "<i class='fas fa-3x fa-cog' style='width: 50px'> </i>"
            controlUI.appendChild(controlText)

            controlUI.addEventListener("click", () => {
                this.$emit("showSettings")
            })
        },

        projectNameDiv: function(div) {
            let controlUI = document.createElement("div")
            controlUI = this.setDivCSS(controlUI)
            controlUI = this.setBottomCSS(controlUI)
            controlUI.style.paddingLeft = "5px"
            controlUI.style.paddingRight = "5px"
            div.appendChild(controlUI)

            let controlText = document.createElement("div")
            controlText.classList.add("project-name")
            let saveDate = new Date(Date.parse(this.project.last_saved_at)).toLocaleString()
            controlText.innerHTML = "<b>" + this.project.name + "</b>" + " - " + this.$t("Last saved : ") + saveDate
            controlText.style.fontSize = "16px"
            controlText.style.lineHeight = "38px"
            controlUI.appendChild(controlText)
        },

        projectLocationDiv: function(div) {
            if (this.project.coordinate.address) {
                let controlUI = document.createElement("div")
                controlUI = this.setDivCSS(controlUI)
                controlUI.style.paddingLeft = "5px"
                controlUI.style.paddingRight = "5px"
                div.appendChild(controlUI)

                let controlText = document.createElement("div")
                controlText.innerHTML = this.$t("Location : ") + " " + this.project.coordinate.address
                controlUI.appendChild(controlText)
            }
        },

        recenterDiv: function(div) {
            let controlUI = document.createElement("div")
            controlUI = this.setDivCSS(controlUI)
            controlUI = this.setButtonCSS(controlUI)
            controlUI = this.setHoverCss(controlUI)
            controlUI = this.setRightCSS(controlUI)
            controlUI.title = this.$t("Center")
            div.appendChild(controlUI)

            let controlText = document.createElement("div")
            controlText.innerHTML = "<i class='fas fa-3x fa-compress-arrows-alt'> </i>"
            controlText.style.padding = "5px"
            controlUI.appendChild(controlText)

            controlUI.addEventListener("click", () => {
                this.centerMap()
            })
        },

        addCustomControls: function() {
            let saveProjectDiv = document.createElement("div")
            this.saveProjectDiv(saveProjectDiv)

            let projectNameDiv = document.createElement("div")
            this.projectNameDiv(projectNameDiv)

            let projectLocationDiv = document.createElement("div")
            this.projectLocationDiv(projectLocationDiv)

            let exportProjectDiv = document.createElement("div")
            this.exportProjectDiv(exportProjectDiv)

            let exportMapDiv = document.createElement("div")
            this.exportMapDiv(exportMapDiv)

            let projectSettingsDiv = document.createElement("div")
            this.projectSettingsDiv(projectSettingsDiv)

            let centerDiv = document.createElement("div")
            this.recenterDiv(centerDiv)

            // eslint-disable-next-line
            this.$refs.gmap.$mapObject.controls[google.maps.ControlPosition.TOP_CENTER].push(saveProjectDiv)

            // eslint-disable-next-line
            this.$refs.gmap.$mapObject.controls[google.maps.ControlPosition.BOTTOM_LEFT ].push(projectNameDiv)

            // eslint-disable-next-line
            this.$refs.gmap.$mapObject.controls[google.maps.ControlPosition.TOP_RIGHT ].push(projectLocationDiv)

            // eslint-disable-next-line
            this.$refs.gmap.$mapObject.controls[google.maps.ControlPosition.TOP_CENTER ].push(exportProjectDiv)

            // eslint-disable-next-line
            this.$refs.gmap.$mapObject.controls[google.maps.ControlPosition.TOP_CENTER ].push(exportMapDiv)

            // eslint-disable-next-line
            this.$refs.gmap.$mapObject.controls[google.maps.ControlPosition.TOP_CENTER].push(projectSettingsDiv)

            // eslint-disable-next-line
            this.$refs.gmap.$mapObject.controls[google.maps.ControlPosition.RIGHT_CENTER].push(centerDiv)
        },

        getClickedLocation: function(event) {
            if (this.isChoosingCameraLocation) {
                this.$emit("isChoosingLocation", event)
            }
        },

        getIcon: function(index) {
            let icon = {
                // eslint-disable-next-line
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: "#d4830b",
                fillOpacity: 0.5,
                strokeColor: "#ffffff",
                strokeOpacity: 0.5,
                strokeWeight: 1,
                scale: 6,
            }

            if (index === this.selectedCamera) {
                icon = {
                    // eslint-disable-next-line
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: "#ff9a03",
                    fillOpacity: 0.9,
                    strokeColor: "#459fff",
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                    scale: 6,
                }
            }

            return icon
        },

        getMarkerLabel: function(index) {
            return "<div class='camera-label bg-white'> <div class='fw-bold'>" + this.cameras[index].settings.name + "</div></div>"
        },

        selectCamera: function(e, index) {
            if (this.isChoosingCameraLocation) {
                this.getClickedLocation(e)
            } else {
                this.$emit("selectCamera", index)
            }
        },

        dragEndCallback: function(event, index) {
            this.getMarkerPosition2Callback(event, index)
            // this.onRotateMarkerDragEnd2(index)
            this.currentDrag = false
        },

        dragStartPolyCallback: function(event, index) {
            // eslint-disable-next-line
            let position = new google.maps.LatLng({
                lat: event.lat(),
                lng: event.lng()
            })
            this.cameras[index].dragPolyStartPosition = position
            this.currentDrag = true
            this.currentDragPoly = true
        },

        dragEndPolyCallback: function(event, index) {
            let camera = this.cameras[index]

            let start = camera.dragPolyStartPosition
            // eslint-disable-next-line
            let eventPosition = new google.maps.LatLng({
                lat: event.lat(),
                lng: event.lng()
            })

            let diffLat = eventPosition.lat() - start.lat()
            let diffLng = eventPosition.lng() - start.lng()

            let markerPosition = camera.settings.marker.position
            // eslint-disable-next-line
            let position = new google.maps.LatLng({
                lat: markerPosition.lat() + diffLat,
                lng: markerPosition.lng() + diffLng
            })

            this.getMarkerPosition2(position, index)
            this.onRotateMarkerDragEnd2(index)
            this.currentDrag = false
            this.currentDragPoly = false
        },

        getMarkerPosition2: function(position, index) {
            this.cameras[index].settings.marker.position = position
            this.cameras[index].settings.location.coordinate = [position.lat(), position.lng()]
            this.computeCameraAov2(index)
            this.drawAOV(index)
        },

        getMarkerPosition2Callback: function(event, index) {
            // eslint-disable-next-line
            var position = new google.maps.LatLng({
                lat: event.lat(),
                lng: event.lng()
            })

            this.getMarkerPosition2(position, index)
        },

        computeCameraAov2: function(cameraIndex) {
            let camera = this.cameras[cameraIndex]
            let coordinate = camera.settings.marker.position
            let path = this.drawTriangle2(coordinate, camera)
            // let path = this.drawTriangle3(coordinate, camera)
            this.cameras[cameraIndex].settings.path = path
        },

        drawAOV: function(cameraIndex) {
            let path = this.cameras[cameraIndex].settings.path
            this.$refs.polygon[cameraIndex].$polygonObject.setPaths(path)
        },

        drawTriangle2: function(origin, camera) {
            let aovHeight = camera.settings.aov_v
            let aovWidth = camera.settings.aov_h
            let sensorHeight = camera.sensor_height
            if (camera.settings.is_in_portrait_position) {
                aovHeight = camera.settings.aov_h
                aovWidth = camera.settings.aov_v
                sensorHeight = camera.sensor_width
            }

            let distances
            try {
                distances = computeDistances(
                    sensorHeight,
                    aovHeight,
                    this.project.settings.target_height,
                    this.project.settings.target_pixels,
                    camera.settings.height,
                    camera.settings.tilt,
                )
            } catch(error) {
                console.log(error)
            }

            camera.settings.distanceMin = distances.distanceMin
            camera.settings.distance = distances.distanceMax
            camera.settings.distanceMinTargetPixel = distances.distanceMinTargetPixels
            camera.settings.distanceMaxTargetPixel = distances.distanceMaxTargetPixels


            let triangleCoords = []
            triangleCoords.push(origin)

            let angleStep = 8
            let begin = camera.settings.orientation - aovWidth
            let end = camera.settings.orientation
            let maxPoints = []

            // Cas pour les petits angles <8deg
            if (end - angleStep < begin) {
                angleStep = 1
            }

            for (let angle = begin; angle <= end - angleStep; angle++) {
                // eslint-disable-next-line
                let point = new google.maps.geometry.spherical.computeOffset(origin, distances.distanceMax, angle)
                triangleCoords.push(point)
                maxPoints.push(point)
                angle += (angleStep - 1)
            }
            // eslint-disable-next-line
            let final = new google.maps.geometry.spherical.computeOffset(origin, distances.distanceMax, end)
            triangleCoords.push(final)
            maxPoints.push(final)

            camera.settings.maxPoints = maxPoints


            for (let angle = end; angle >= begin + angleStep; angle--) {
                // eslint-disable-next-line
                let point = new google.maps.geometry.spherical.computeOffset(origin, distances.distanceMin, angle)
                triangleCoords.push(point)
                angle -= (angleStep + 1)
            }
            // eslint-disable-next-line
            let finalDead = new google.maps.geometry.spherical.computeOffset(origin, distances.distanceMin, begin)
            triangleCoords.push(finalDead)


            triangleCoords.push(origin)
            // eslint-disable-next-line
            let deadTriangle1 = new google.maps.geometry.spherical.computeOffset(origin, distances.distanceMin, end)
            triangleCoords.push(deadTriangle1)

            return triangleCoords
        },

        dragMarker: function(event, index) {
            this.$emit("selectCamera", index)
            this.getMarkerPosition2Callback(event, index)
        },

        dragPoly: function(event, index) {
            this.$emit("selectCamera", index)
        },

        setPolygonOptions: function(index, camera) {
            this.$refs.polygon[index].$polygonObject.setOptions({
                fillColor: camera.settings.fov_color
            })
        },

        updateRotateMarker2: function(index) {
            let camera = this.cameras[index]
            let pointIndex = camera.settings.maxPoints.length / 2

            let firstPoint
            let secondPoint
            if (Number.isInteger(pointIndex)) {
                firstPoint = camera.settings.maxPoints[pointIndex]
                secondPoint = camera.settings.maxPoints[pointIndex - 1]
            } else {
                firstPoint = camera.settings.maxPoints[Math.floor(pointIndex)]
                secondPoint = camera.settings.maxPoints[Math.ceil(pointIndex)]
            }

            // eslint-disable-next-line
            let turnMarkerPosition = new google.maps.LatLng({
                lat: (firstPoint.lat() + secondPoint.lat()) / 2,
                lng: (firstPoint.lng() + secondPoint.lng()) / 2
            })
            this.cameras[index].settings.rotateMarker = turnMarkerPosition
        },

        rotateMarker2: function(event, index) {
            this.$refs.rotateMarker[index].$markerObject.setOpacity(0)

            // eslint-disable-next-line
            let position = new google.maps.LatLng({
                lat: event.lat(),
                lng: event.lng()
            })

            // eslint-disable-next-line
            let heading = google.maps.geometry.spherical.computeHeading(
                position,
                // this.$refs.rotatePath[index].$circleObject.center
                this.$refs.markerlabel[index].$markerWithLabelObject.getPosition(),
            )

            this.cameras[index].settings.orientation = (heading - 180 + this.cameras[index].settings.aov_h / 2) - 360 * Math.floor(heading / 360)
            this.computeCameraAov2(index)
            this.drawAOV(index, this.cameras[index])
        },

        onRotateMarkerDragEnd2: function(index) {
            this.updateRotateMarker2(index)
            this.$refs.rotateMarker[index].$markerObject.setPosition(this.cameras[index].settings.rotateMarker)
            this.$refs.rotateMarker[index].$markerObject.setOpacity(1)
        },

        centerMap: function() {
            if (this.project.settings.type === 1) {
                if (this.cameras.length !== 0) {
                    let bounds = this.getCamerasBounds()
                    this.$refs.gmap.$mapObject.fitBounds(bounds)
                } else {
                    this.$refs.gmap.$mapObject.setZoom(this.project.settings.scale)
                    this.$refs.gmap.$mapObject.panTo(this.center)
                }
            } else if (this.project.settings.type === 2) {
                this.$refs.gmap.$mapObject.fitBounds(this.imageBounds)
            }
        },

        rotateMarkerClick: function(e) {
            if (this.isChoosingCameraLocation) {
                this.getClickedLocation(e)
            }
        },

        getSaveData: function() {
            let res = {
                center: this.$refs.gmap.$mapObject.getCenter(),
                zoom: this.$refs.gmap.$mapObject.getZoom(),
            }
            return res
        },
    },

    components: {
        MarkerWithLabel,
    }
}
</script>

<!-- ====================================================================== -->

<style scoped>

.mapContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.vue-map-container,
.vue-map-container .vue-map {
    flex: 1 1 auto;
    height: calc(100vh - 52px);
    z-index: 1;
    position: relative;
    min-block-size: 0;
}

</style>


<style>

.map-btn.map-btn-disabled {
    color: rgb(75, 75, 75);
    cursor: not-allowed !important;
}

.map-btn.map-btn-disabled div{
    background-color: grey;
}

.map-btn:not(.map-btn-disabled) {
    color: rgb(75, 75, 75);
    cursor: pointer;
}

.map-btn:not(.map-btn-disabled) div:hover {
    color: rgb(30, 30, 30);
}

.map-btn:not(.map-btn-disabled).rotateDiv img {
    opacity: 0.7
}

.map-btn:not(.map-btn-disabled).rotateDiv:hover img {
    opacity: 1.0;
}

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
