//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/09/20 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"


//------------------------------------------------------------------------------


export function setupAxios() {
    Vue.use(VueAxios, axios)
}


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
