//-----------------------------------------------------------------------------
// @author Thibault, tsoret@e-vitech.com
// @date 2020/06/30
// @copyright EVITECH
//-----------------------------------------------------------------------------


import Vue from "vue"
import Router from "vue-router"

import HomePage from "@/components/app_home/homepage"
import ProjectsDashboard from "@/components/app_dashboard/projects_dashboard"
import UserProfile from "@/components/app_user_profile/user_profile"
import VerifyUser from "@/components/app_authentification/verify_user/verify_user"
import ResetPassword from "@/components/app_authentification/reset_password/reset_password"
import EditProject from "@/components/project/edit_project/edit_project"
import Admin from "@/components/admin/admin"
import CGU from "@/components/cgu/cgu"


import store from "../store"


//-----------------------------------------------------------------------------


Vue.use(Router)


//-----------------------------------------------------------------------------

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters["authentification_evdesign/isAuthenticated"]) {
        next()
        return
    }

    next(store.state.applicationRootURL)
}


const ifAuthenticated = (to, from, next) => {
    if (store.getters["authentification_evdesign/isAuthenticated"]) {
        next()
        return
    }

    next("/")
}

const isAdmin = (to, from, next) => {
    if (store.getters["authentification_evdesign/isAdmin"]) {
        next()
        return
    }

    next("/")
}

//------------------------------------------------------------------------------

const router = new Router({
    mode: "hash",
    routes:
    [
        {
            path: store.state.applicationHomeURL,
            component: HomePage,
            name: "Homepage",
            beforeEnter: ifNotAuthenticated

        },
        {
            path: store.state.applicationDashboardURL,
            component: ProjectsDashboard,
            name: "projects",
            beforeEnter: ifAuthenticated
        },
        {
            path: "/profile",
            component: UserProfile,
            name: "profile",
            beforeEnter: ifAuthenticated

        },
        {
            path: "/verify/:key",
            component: VerifyUser,
            name: "verifyemail",
            beforeEnter: ifNotAuthenticated
        },
        {
            path: "/resetpassword/:token",
            component: ResetPassword,
            name: "resetpassword",
            beforeEnter: ifNotAuthenticated
        },
        {
            path: "/project/:id",
            component: EditProject,
            name: "editproject",
            beforeEnter: ifAuthenticated
        },
        {
            path: "/admin",
            name: "admin",
            component: Admin,
            beforeEnter: isAdmin
        },
        {
            path: store.state.applicationCGUURL,
            name: "CGU",
            component: CGU,
            beforeEnter: ifNotAuthenticated
        },
    ],
})


//-----------------------------------------------------------------------------


export default router


//-----------------------------------------------------------------------------
// End of file
//-----------------------------------------------------------------------------

