<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/08
@copyright EVITECH
===============================================================================
Page pour vérifier un compte
=========================================================================== -->

<template>

<div>

    <transition name="fade">

        <div class="m-2 text-center">

            <div
                class="mt-4 text-center"
            >

                <Loading v-if="loading"/>


                <Message
                    v-else
                    :message="message"
                    :messageType="messageType"
                />

            </div>

        </div>

    </transition>

</div>

</template>

<!-- ======================================================================= -->


<script>

import SweetalertIcon from "vue-sweetalert-icons"

import NavBar from "@/components/navbar/navbar"
import Message from "@/components/app_utils/message_handler/message_handler"
import Loading from "@/components/app_utils/loading/loading"

import { FR } from "@/i18n/i18n_languages"
import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------


export default {
    data: function() {
        return {
            loading: true,
            messageType: "",
            message: "",
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    mounted: function() {
        this.verify()
    },

    methods: {
        verify: function() {
            this.axios({
                method: "post",
                url: "evdesign/users_management/verify_account",
                data: {
                    key: this.$route.params.key,
                },
                type: "json"
            })
                .then(() => {
                    this.messageType = "success"
                    this.message = this.$t("Your account has been successfully verified! You can now log in")
                })
                .catch((error) => {
                    if (error.response) {
                        let errorMessage = error.response.data.data

                        if (errorMessage.includes("TE")) {
                            this.messageType = "error"
                            this.message = this.$t("The link is invalid or expired, please try again by requesting a new confirmation email.")

                        } else if (errorMessage.includes("EDNE")) {
                            this.messageType = "error"
                            this.message = this.$t("Wrong confirmation key, please contact us.")

                        } else if (errorMessage.includes("UAC")) {
                            this.messageType = "info"
                            this.message = this.$t("Your account is already confirmed, please proceed to login.")
                        }
                    } else {
                        this.messageType = "error"
                        this.message = this.$t("Could not contact server, please try again")
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },

    components: {
        NavBar,
        SweetalertIcon,
        Message,
        Loading
    }
}

</script>


<!-- ======================================================================= -->


<style scoped>
</style>


<!-- ======================================================================= -->
<!-- End of file -->
<!-- ======================================================================= -->
