<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <div>
        <div class="navbar">

            <ul class="nav navbar-right">

                <li>
                    <a
                        name="openLogin"
                        class="btn evdesign-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#authModal"
                        @click="showLoginModal()"
                    >
                        {{ $t('Login') }}
                    </a>
                </li>


                <li>
                    <a
                        name="openRegister"
                        class="btn evdesign-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#authModal"
                        @click="showRegisterModal()"
                    >
                        {{ $t('Register') }}
                    </a>
                </li>

            </ul>

        </div>

    </div>

</template>

<!-- ====================================================================== -->

<script>

import { FR } from "@/i18n/i18n_languages"

import translationFR from "../i18n/FR/translation"


//-----------------------------------------------------------------------------


export default {
    beforeCreate() {
        this.$i18n.add(FR, translationFR)
    },

    methods: {
        showRegisterModal() {
            this.$bus.$emit("clickedItem", "register")
        },

        showLoginModal() {
            this.$bus.$emit("clickedItem", "login")
        },
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>
</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
