<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/09/03
@copyright EVITECH
=========================================================================== -->

<template>

<div>

    <ValidationObserver v-slot="{ invalid }">

        <transition name="fade">

            <Alert
                v-if="showAlert"
                :message="message"
                alertType="danger"
            />

        </transition>


        <div class="validation-error m-0">
            {{ $t("All fields are required.") }}
        </div>



        <h6 class="mt-0 fw-bold">
            {{ $t("Personal details") }}
        </h6>


        <div class="border rounded p-2">

            <div class="row">

                <div class="col-6">

                    <label>
                        {{ $t("First name") }}
                    </label>


                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                    >

                        <input
                            type="text"
                            :class="getInputClass(errors)"
                            v-model="firstName"
                            :placeholder="$t('First name')"
                        />


                        <div class="validation-error">
                            {{ $t(errors[0]) }}
                        </div>

                    </ValidationProvider>

                </div>


                <div class="col-6">

                    <label>
                        {{ $t("Last name") }}
                    </label>


                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                    >

                        <input
                            type="text"
                            :class="getInputClass(errors)"
                            v-model="lastName"
                            :placeholder="$t('Last name')"
                        />


                        <div class="validation-error">
                            {{ $t(errors[0]) }}
                        </div>

                    </ValidationProvider>

                </div>

            </div>


            <div class="container pt-2 p-0">

                <label>
                    {{ $t("Phone number") }}
                </label>


                <ValidationProvider rules="required" v-slot="{ errors }">

                    <VuePhoneNumberInput
                        v-model="phone"
                        :class="getInputClass(errors)"
                        :default-country-code="'FR'"
                        @update="getPhoneNumber($event)"
                    />


                    <div
                        v-if="!isPhoneValid || errors.length !== 0"
                        class="validation-error"
                    >
                        {{ $t("Invalid phone number") }}
                    </div>

                </ValidationProvider>

            </div>


            <!-- SOCIETE ET FONCTION -->

            <div class="row pt-2">

                <!-- SOCIETE -->

                <div class="col-6">

                    <label>
                        {{ $t("Company") }}
                    </label>


                    <ValidationProvider rules="required" v-slot="{ errors }">

                            <input
                                type="text"
                                :placeholder="$t('Company')"
                                name="company"
                                :class="getInputClass(errors)"
                                v-model="company"
                            />


                            <div class="validation-error">
                                {{ $t(errors[0]) }}
                            </div>

                    </ValidationProvider>

                </div>


                <!-- FONCTION -->

                <div class="col-6">

                    <label>
                        {{ $t("Job role") }}
                    </label>


                    <ValidationProvider rules="required" v-slot="{ errors }">

                            <input
                                type="text"
                                :placeholder="$t('Job role')"
                                name="job_role"
                                :class="getInputClass(errors)"
                                v-model="jobRole"
                            />


                            <div class="validation-error">
                                {{ $t(errors[0]) }}
                            </div>

                    </ValidationProvider>

                </div>


            </div>

        </div>


        <!-- INFORMATIONS DE CONNEXION -->

        <h6 class="fw-bold">
            {{ $t("Login details") }}
        </h6>


        <div class="border rounded p-2 mt-2">

            <!--EMAIL ET NOM D'UTILISATEUR -->

            <div class="row">

                <!-- EMAIL -->

                <div class="col-6">

                    <label>
                        {{ $t("Email address") }}
                    </label>


                    <ValidationProvider rules="required|email" v-slot="{ errors }">

                            <input
                                type="text"
                                :placeholder="$t('Email address')"
                                :class="getInputClass(errors)"
                                v-model="email"
                            />


                            <div class="validation-error">
                                {{ $t(errors[0]) }}
                            </div>

                    </ValidationProvider>

                </div>


                <!-- NOM D'UTILISATEUR -->

                <div class="col-6">

                    <label>
                        {{ $t("Username") }}
                    </label>


                    <ValidationProvider rules="required|minUserLength" v-slot="{ errors }">

                            <input
                                type="text"
                                :placeholder="$t('Username')"
                                :class="getInputClass(errors)"
                                v-model="username"
                            />


                            <div class="validation-error">
                                {{ $t(errors[0]) }}
                            </div>

                    </ValidationProvider>

                </div>

            </div>


            <!-- MOT DE PASSE ET VALIDATION -->

            <div class="row">

                <!-- MOT DE PASSE -->

                <div class="col-6">

                    <label>
                        {{ $t("Password") }}
                    </label>


                    <ValidationProvider rules="required|minPasswordLength" v-slot="{ errors }">

                        <password
                            :badge="false"
                            type="password"
                            :placeholder="$t('Password')"
                            :class="getInputClass(errors)"
                            v-model="password"
                        />


                        <div class="validation-error">
                            {{ $t(errors[0]) }}
                        </div>

                    </ValidationProvider>

                </div>


                <!-- CONFIRMATION MOT DE PASSE -->

                <div class="col-6">

                    <label>
                        {{ $t("Password confirmation") }}
                    </label>


                    <ValidationProvider rules="required|minPasswordLength" v-slot="{ errors }">

                        <password
                            :badge="false"
                            type="password"
                            :placeholder="$t('Password confirmation')"
                            :class="getInputClass(errors)"
                            v-model="passwordConfirm"
                        />

                        <div
                            class="validation-error"
                            v-if="password != passwordConfirm && password != ''"
                        >
                            {{ $t("Passwords do not match")}}
                        </div>

                    </ValidationProvider>

                </div>

            </div>

        </div>


        <!-- CGU -->

        <div>

            {{ $t("By clicking 'Create an account', you agree to our ") }}

            <a
                href="#"
                @click="goToCGU()"
            >
                {{ $t("Terms") }}
            </a>

        </div>


        <!-- CAPTCHA -->

        <div
            v-if="!devMode"
            class="recaptcha container ps-0 mt-3"
        >

            <vue-recaptcha
                ref="recaptcha"
                :sitekey="recaptchaKey"
                :loadRecaptchaScript="true"
                @verify="captchaVerified"
                @expired="captchaExpired"
            >
            </vue-recaptcha>

        </div>


        <!-- BOUTON D'ENREGISTREMENT
            maj bootstrap5 necessite le close modal
        -->

        <button
            class="btn evdesign-btn w-100 mt-3 mb-0 mx-0"
            :disabled="invalid || !captchaValidated"
            @click="register"
        >
            {{ $t("Create an account") }}
        </button>


        <button
            ref="closeModal"
            class="d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
        >
        </button>

    </ValidationObserver>

</div>

</template>


<!-- ====================================================================== -->


<script>

import Password from "vue-password-strength-meter"

import VuePhoneNumberInput from "vue-phone-number-input"
import "vue-phone-number-input/dist/vue-phone-number-input.css"

import VueRecaptcha from "vue-recaptcha"

import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate"

import Alert from "@/components/app_utils/alert/alert"

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------


export default {
    props: {
        recaptchaKey: {
            type: String
        }
    },

    data() {
        return {
            username: "",
            password: "",
            passwordConfirm: "",
            message: "",
            showAlert: "",
            firstName: "",
            lastName: "",
            company: "",
            jobRole: "",
            email: "",
            phone: "",
            countryCallingCode: "33",
            isPhoneValid: true,
            captchaValidated: false,
            cguChecked: false,
            devMode: undefined,
        }
    },

    beforeCreate() {
        this.$i18n.add(FR, translationFR)
    },

    beforeMount: function() {
        this.devMode = this.axios.defaults.devMode
        this.resetCaptcha()
    },

    methods: {
        register: function() {
            this.$emit("request")

            let username = this.username

            // A revoir comment gérer les numéros de téléphone, idéalement il faut les stocker
            // au format international (ex: 33625451525), ici j'enlève le 1er "0" dans "0625451525"
            // mais ce n'est pas judicieux, l'utilisateur peut rentrer "625451525" et le numéro sera
            // considéré comme valide, en revanche avec cette ligne en dessous, on stockera "3325451525"
            let phone = this.countryCallingCode + this.phone.replace(/ /g, "").substring(1)

            let requestObject = {
                username: username,
                password: this.password,
                first_name: this.firstName,
                last_name: this.lastName,
                company: this.company,
                job_role: this.jobRole,
                email: this.email,
                phone: phone
            }

            let request = {
                method: "post",
                url: "/evdesign/users_management/create_user",
                data: {
                    data: requestObject
                },
                type: "json"
            }

            this.axios(request)
                .then(() => {
                    this.showAlert = false
                    this.showRegisterConfirmation()
                    this.$emit("success")
                    this.reset()
                })
                .catch((error) => {
                    let userMessage

                    if (error.response) {
                        let errorMessage = error.response.data.data
                        if (errorMessage.includes("UCF")) {
                            userMessage = this.$t("Creation error, please contact us")
                        } else {
                            userMessage = this.$t("Unknown error, please contact us")
                        }
                    } else {
                        userMessage = this.$t("Could not contact server, please try again")
                    }

                    this.error(userMessage)
                })
                .finally(() => {
                    this.resetCaptcha()
                })
        },

        getPhoneNumber: function(event) {
            this.countryCallingCode = event.countryCallingCode
            this.isPhoneValid = event.isValid
            this.phone = event.nationalNumber
        },

        showRegisterConfirmation: function() {
            this.$swal({
                icon: "success",
                text: this.$t("Your account has been created successfully, please verify it by visiting the link provided in the sent email"),
                showCancelButton: false,
                showConfirmButton: true,
            })

            this.closeModal()
        },

        closeModal: function() {
            this.$refs.closeModal.click()
        },

        emitError: function() {
            this.showAlert = true
            this.$emit("error")
        },

        error(message) {
            this.message = message
            this.emitError()
        },

        captchaVerified: function() {
            this.captchaValidated = true
        },

        captchaExpired: function() {
            this.captchaValidated = false
        },

        getInputClass: function(errorsList) {
            if (errorsList.length !== 0) {
                return "w-100 rounded border border-danger"
            }
            return "w-100 rounded border"
        },

        reset: function() {
            Object.assign(this.$data, this.$options.data.apply(this))
            this.devMode = this.axios.defaults.devMode
            this.resetCaptcha()
        },

        resetCaptcha: function() {
            if (this.devMode) {
                this.captchaValidated = true
            } else {
                this.captchaValidated = false
                this.$refs.recaptcha.reset()
            }
        },

        goToCGU: function() {
            this.closeModal()
            this.$emit("goToCGU")
        },

        resetComponent: function() {
        },
    },

    components: {
        ValidationProvider,
        ValidationObserver,
        VueRecaptcha,
        Password,
        VuePhoneNumberInput,
        Alert,
    },
}
</script>

<!---------------------------------------------------------------------------->

<style scoped>

a {
    color: #007bff;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
