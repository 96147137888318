// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-f15f48fb63/0/cache/css-loader-npm-6.11.0-d945f9f4c0-5c8d35975a.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-f15f48fb63/0/cache/css-loader-npm-6.11.0-d945f9f4c0-5c8d35975a.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* The switch - the box around the slider */
.switchButton {
 position: relative;
 display: inline-block;
 width: 40px;
 height: 18px;
}

/* Hide default HTML checkbox */
.switchButton input {display:none;}

/* The slider */
.switchSlider {
 position: absolute;
 cursor: pointer;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background-color: #ccc;
 -webkit-transition: .3s;
 transition: .3s;
 border-radius: 18px;
}

.switchSlider:before {
 position: absolute;
 content: "";
 height: 10px;
 width: 12px;
 left: 4px;
 bottom: 4px;
 background-color: white;
 -webkit-transition: .3s;
 transition: .3s;
 border-radius: 50%;
}

input:checked + .switchSlider {
 background-color: #4CAF50;
}

input:focus + .switchSlider {
 box-shadow: 0 0 1px #2196F3;
}

input:checked + .switchSlider:before {
 -webkit-transform: translateX(20px);
 -ms-transform: translateX(20px);
 transform: translateX(20px);
}

input:disabled  + .switchSlider {
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./static/styles/switchButton.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;CACC,kBAAkB;CAClB,qBAAqB;CACrB,WAAW;CACX,YAAY;AACb;;AAEA,+BAA+B;AAC/B,qBAAqB,YAAY,CAAC;;AAElC,eAAe;AACf;CACC,kBAAkB;CAClB,eAAe;CACf,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,sBAAsB;CACtB,uBAAuB;CACvB,eAAe;CACf,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,WAAW;CACX,SAAS;CACT,WAAW;CACX,uBAAuB;CACvB,uBAAuB;CACvB,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,2BAA2B;AAC5B;;AAEA;CACC,mCAAmC;CACnC,+BAA+B;CAC/B,2BAA2B;AAC5B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["/* The switch - the box around the slider */\n.switchButton {\n position: relative;\n display: inline-block;\n width: 40px;\n height: 18px;\n}\n\n/* Hide default HTML checkbox */\n.switchButton input {display:none;}\n\n/* The slider */\n.switchSlider {\n position: absolute;\n cursor: pointer;\n top: 0;\n left: 0;\n right: 0;\n bottom: 0;\n background-color: #ccc;\n -webkit-transition: .3s;\n transition: .3s;\n border-radius: 18px;\n}\n\n.switchSlider:before {\n position: absolute;\n content: \"\";\n height: 10px;\n width: 12px;\n left: 4px;\n bottom: 4px;\n background-color: white;\n -webkit-transition: .3s;\n transition: .3s;\n border-radius: 50%;\n}\n\ninput:checked + .switchSlider {\n background-color: #4CAF50;\n}\n\ninput:focus + .switchSlider {\n box-shadow: 0 0 1px #2196F3;\n}\n\ninput:checked + .switchSlider:before {\n -webkit-transform: translateX(20px);\n -ms-transform: translateX(20px);\n transform: translateX(20px);\n}\n\ninput:disabled  + .switchSlider {\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
