<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/09/25
@copyright EVITECH
===============================================================================

Composant affichant les détails de la caméra selectionnée

=========================================================================== -->
<template>

<div class="px-3 py-2 text-break container-fluid">

    <div class="d-flex align-items-center align-self-center py-1 me-1">

        <div class="label fw-bold">
            {{ $t("Model") }}
        </div>


        <div class="ps-1 flex-fill">

            {{ selectedCamera.manufacturer }} - {{ selectedCamera.model }}

            <i
                class="far fa-question-circle text-info"
                data-toggle="tooltip"
                data-placement="top"
                :title="cameraDetailsTooltip"
            ></i>

        </div>

    </div>


    <div class="d-flex align-items-center align-self-center py-1 me-1">

        <div class="label fw-bold">
            {{ $t("Name") }}
        </div>


        <div class="flex-fill">

            <input
                class="border rounded"
                type="text"
                :value="selectedCamera.settings.name"
                @input="setName"
            >

        </div>

    </div>


    <div class="d-flex align-items-center align-self-center py-1 me-1">

        <div class="label fw-bold">
            {{ $t("Height (m)") }}
        </div>


        <input
            class="border rounded py-2 flex-fill"
            type="number"
            min="0"
            max="50"
            step="0.1"
            :value="selectedCamera.settings.height"
            @change="setHeight"
        >

    </div>


    <div class="d-flex align-items-center align-self-center py-1 me-1">

        <div class="label fw-bold">
            {{ $t("Tilt (°)") }}
        </div>


        <input
            class="border rounded py-2 flex-fill"
            type="number"
            min="0"
            max="90"
            step="1"
            :value="selectedCamera.settings.tilt"
            @change="setTilt"
        >

    </div>


    <div class="d-flex align-items-center align-self-center py-1 me-1">

        <div class="label fw-bold">
            {{ $t("Position") }}
        </div>


        <toggle-button
            class="my-auto mx-auto"
            :value="selectedCamera.settings.is_in_portrait_position"
            :sync="true"
            :labels="{checked: $t('Portrait'), unchecked: $t('Landscape')}"
            :color="{checked: '#75C791', unchecked: '#75C791'}"
            :width="125"
            :height="25"
            :font-size="15"
            @change="setPosition"
        />

    </div>


    <div class="d-flex align-items-center align-self-center py-1 me-1">

        <div class="label fw-bold">
            {{ $t("FOV color") }}
        </div>


        <input
            class="mx-auto"
            type="color"
            :value="selectedCamera.settings.fov_color"
            @change="setFOVColor"
        >

    </div>


    <div
        v-if="selectedCamera.aov_v_max !== null"
        class="d-flex align-items-center align-self-center py-1 me-1"
    >

        <div class="label fw-bold">
            {{ $t("FOV") }}
        </div>


        <div class="ps-1 pe-0 flex-fill">

            <div class="w3-row">

                <div class="w3-col border-right" style="width: 100px;">

                    <div class="row pe-4">

                        <div class="col-6 px-0 text-start">
                            {{ $t("vert") }}
                        </div>

                        <div class="col-6 px-0 text-end">
                            {{ selectedCamera.settings.aov_v }}
                        </div>

                    </div>


                    <div class="row pe-4">

                        <div class="col-6 px-0 text-start">
                            {{ $t("horizon") }}
                        </div>

                        <div class="col-6 px-0 text-end">
                            {{ selectedCamera.settings.aov_h }}
                        </div>

                    </div>

                </div>


                <div class="w3-rest px-2" style="padding-top: 13px;">

                    <vue-slider
                        tooltip="none"
                        :useKeyboard=true
                        :min="selectedCamera.aov_v_min"
                        :max="selectedCamera.aov_v_max"
                        :interval="0.1"
                        :value="selectedCamera.settings.aov_v"
                        @change="setAOV"
                    />

                </div>

            </div>

        </div>

    </div>

</div>

</template>


<!-- ====================================================================== -->


<script>

import VueSlider from "vue-slider-component"
import { ToggleButton } from "vue-js-toggle-button"

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------


export default {
    props: {
        selectedCamera: {
            type: Object,
            default: () => { return {} },
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    watch: {
        selectedCamera: {
            handler: function() {},
            deep: true,
        },
    },

    computed: {
        cameraDetailsTooltip: function() {
            let details = ""

            details += this.$t("Type : ") + this.cameraType + "\n"
            details += this.$t("Focal length : ") + this.selectedCamera.sensor_width
            details += " x " + this.selectedCamera.sensor_height

            return details
        },

        cameraType: function() {
            let type = ""

            if (this.selectedCamera.sensor_type === 1) {
                type = this.$t("Visible")
            } else if (this.selectedCamera.sensor_type === 2) {
                type = this.$t("Thermal")
            } else if (this.selectedCamera.sensor_type === 3) {
                type = this.$t("Thermographic")
            } else {
                type = this.$t("Unknown")
            }
            return type
        },
    },

    methods: {
        setName: function(event) {
            this.$emit("setName", event.target.value)
        },

        setHeight: function(event) {
            let value = parseFloat(event.target.value)
            if (value > 50.0) {
                value = 50
            }
            if (value < 0.0 || Number.isNaN(value)) {
                value = 0
            }


            if (!Number.isInteger(value)) {
                value = Number(value.toFixed(1))
            }

            this.$emit("setHeight", value)
            event.target.value = value
        },

        setTilt: function(event) {
            let value = parseFloat(event.target.value)
            if (value > 90.0) {
                value = 90.0
            }
            if (value < 0.0 || Number.isNaN(value)) {
                value = 0
            }


            if (!Number.isInteger(value)) {
                value = Number(value.toFixed(2))
            }

            this.$emit("setTilt", value)
            event.target.value = value
        },

        setPosition: function(event) {
            let value = event.value
            this.$emit("setPosition", value)
        },

        setAOV: function(value) {
            let ratio = this.selectedCamera.sensor_width / this.selectedCamera.sensor_height
            this.$emit("setAOV", {
                aov_v: value.toFixed(2),
                aov_h: (value * ratio).toFixed(2),
            })
        },

        setFOVColor: function(event) {
            let color = event.target.value
            this.$emit("setFOVColor", color)
        }
    },

    components: {
        VueSlider,
        ToggleButton,
    }
}

</script>


<!-- ====================================================================== -->


<style scoped>

.label {
    width: 110px;
}

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->

