//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/07/16 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------

// DEBUT DE REFACTO DANS LE REPERTOIRE DRAWING, INCOMPLET POUR LE MOMENT
// NE PAS SUPPRIMER CAR EVDESIGN EN DEPEND

//------------------------------------------------------------------------------


let paper = require("paper")


//------------------------------------------------------------------------------


const STROKE_WIDTH = 4

const DIR_TOOL_ANGLE = 10
const DIR_TOOL_TOLERANCE = 5


//------------------------------------------------------------------------------


export function computeCoord(coords, dimension) {
    let displayX = Number(((coords.x * dimension.x) / 100).toFixed(2))
    let displayY = Number(((coords.y * dimension.y) / 100).toFixed(2))
    return { x: displayX, y: displayY }
}


// //-------------------------------------


function uncomputeCoord(coords, dimension) {
    let displayX = Number(((coords.x * 100) / dimension.x).toFixed(2))
    let displayY = Number(((coords.y * 100) / dimension.y).toFixed(2))
    return { x: displayX, y: displayY }
}


// //------------------------------------------------------------------------------


export function initCanvas(canvasName, vueEl, tolerance) {
    let canvas = document.getElementById(canvasName)
    paper.setup(canvas)
    paper.project.vueElement = vueEl
    initMouseEvents(tolerance)
}


// //-------------------------------------


export function clearCanvas(canvasName) {
    // Effacer les éléments du canvas fait planter le canvas si on reinitialise
    // un second projet juste après avec vuejs.
    // solution trouvée: initialiser un nouveau projet

    let canvas = document.getElementById(canvasName)
    paper.setup(canvas)
}


// //-------------------------------------


export function removeCanvas() {
    paper.projects = []
    paper.tools = []
}


//-------------------------------------


export function getCanvasDimension() {
    return { x: paper.view.viewSize.width, y: paper.view.viewSize.height }
}


//------------------------------------------------------------------------------


export function initMouseEvents(tolerance) {
    let hitOptions = {
        segments: true,
        stroke: true,
        tolerance: tolerance,
    }

    let noToleranceHitOptions = {
        segments: true,
        stroke: true,
        tolerance: 0,
    }

    let tools = new paper.Tool()

    tools.onMouseDown = function(e) {
        let noToleranceHitResult = paper.project.hitTest(e.point, noToleranceHitOptions)
        if (noToleranceHitResult) {
            if (noToleranceHitResult.item.selectable) {
                noToleranceHitResult.item.bringToFront()
            }
        } else {
            let hitResult = paper.project.hitTest(e.point, hitOptions)
            if (hitResult) {
                if (hitResult.item.selectable) {
                    hitResult.item.bringToFront()
                    tools.selectedItem = hitResult.item
                    if (tools.selectedItem.responds("mousedown")) {
                        tools.selectedItem.emit("mousedown", e)
                    }
                }
            }
        }
    }

    tools.onMouseDrag = function(e) {
        if (tools.selectedItem) {
            if (tools.selectedItem.responds("mousedrag")) {
                tools.selectedItem.emit("mousedrag", e)
            }
        }
    }

    tools.onMouseUp = function(e) {
        if (tools.selectedItem) {
            if (tools.selectedItem.responds("mouseup")) {
                tools.selectedItem.emit("mouseup", e)
            }
            tools.selectedItem.selected = false
            tools.selectedItem = null
        }
    }

    tools.onMouseMove = function(e) {
        paper.project.activeLayer.selected = false
        let hitResult = paper.project.hitTest(e.point, hitOptions)
        if (hitResult) {
            if (hitResult.item.selectable) {
                document.body.style.cursor = "pointer"
                e.item = hitResult.item
                e.item.selected = e.item.selectable
            }
        } else {
            document.body.style.cursor = "default"
        }
    }
}


//------------------------------------------------------------------------------


export function initRectangle(rectangle, color = "orange") {
    let dimension = getCanvasDimension()

    let points = []
    rectangle.forEach(function(point) {
        let paperPoint = new paper.Point(computeCoord(point, dimension))
        points.push(paperPoint)
    })


    // let topLeft = new paper.Point(computeCoord(rectangle.topLeft, dimension))
    let topLeft = points.reduce(paper.Point.min)
    // let bottomRight = new paper.Point(computeCoord(rectangle.bottomRight, dimension))
    let bottomRight = points.reduce(paper.Point.max)


    let rectanglePath = new paper.Path.Rectangle(topLeft, bottomRight)

    rectanglePath.strokeColor = color
    rectanglePath.strokeWidth = STROKE_WIDTH
    rectanglePath.evtType = "Rectangle"
    rectanglePath.strokeScaling = false
    rectanglePath.selectable = true

    paper.view.draw()

    return rectanglePath
}


//-------------------------------------


export function getRectangle(rectangleID) {
    let dimension = getCanvasDimension()
    let pointList = []

    // Récupération des points
    paper.project.layers.forEach(function(layer) {
        layer.getItems().forEach(function(item) {
            if (item.id === rectangleID) {
                for (let segment of item.segments) {
                    let point = {
                        x: segment.point.x,
                        y: segment.point.y
                    }
                    let newPoint = uncomputeCoord(point, dimension)
                    pointList.push(newPoint)
                }
            }
        })
    })

    return pointList
}


//------------------------------------------------------------------------------


export function initArrow(arrow, color = "blue") {
    let dimension = getCanvasDimension()

    let top = new paper.Point(computeCoord(arrow.top, dimension))
    let bottom = new paper.Point(computeCoord(arrow.bottom, dimension))

    let base = new paper.Path.Line({ x: -1, y: bottom.y }, { x: dimension.x + 1, y: bottom.y }, { insert: false })
    base.strokeColor = color
    base.strokeWidth = 0.5
    base.evtType = "ArrowBase"
    base.strokeScaling = false
    base.selectable = false
    paper.project.activeLayer.appendBottom(base)


    let arrowPath = new paper.Path.Line(top, bottom)

    arrowPath.strokeColor = color
    arrowPath.strokeWidth = STROKE_WIDTH
    arrowPath.evtType = "Arrow"
    arrowPath.strokeScaling = false
    arrowPath.selectable = true

    arrowPath.base = base

    paper.view.draw()

    return arrowPath
}


//-------------------------------------


export function getArrow(arrowID) {
    let dimension = getCanvasDimension()
    let pointList = []

    // Récupération des points
    paper.project.layers.forEach(function(layer) {
        layer.getItems().forEach(function(item) {
            if (item.id === arrowID) {
                for (let segment of item.segments) {
                    let point = {
                        x: segment.point.x,
                        y: segment.point.y
                    }
                    let newPoint = uncomputeCoord(point, dimension)
                    pointList.push(newPoint)
                }
            }
        })
    })

    let newTop = { x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER }
    let newBottom = { x: Number.MIN_SAFE_INTEGER, y: Number.MIN_SAFE_INTEGER }
    pointList.forEach(function(point) {
        if (point.y <= newTop.y) {
            newTop = point
        }
        if (point.y >= newBottom.y) {
            newBottom = point
        }
    })

    return {
        bottom: newBottom,
        top: newTop
    }
}


//------------------------------------------------------------------------------


export function initZone(zone, color = "red") {
    let dimension = getCanvasDimension()

    let zonePath = new paper.Path()

    zone.pointList.forEach(function(point) {
        let tmpPoint = new paper.Point(computeCoord(point, dimension))
        zonePath.add(tmpPoint)
    })

    zonePath.jaguarId = zone.jaguarId
    zonePath.name = zone.name
    zonePath.closed = true
    zonePath.strokeColor = color
    zonePath.strokeWidth = STROKE_WIDTH
    zonePath.evtType = "Zone"
    zonePath.strokeScaling = false
    zonePath.selectable = true

    paper.view.draw()

    return zonePath
}


//-------------------------------------


export function getZone(zoneID) {
    let dimension = getCanvasDimension()

    let pointList = []

    paper.project.layers.forEach(function(layer) {
        layer.getItems().forEach(function(item) {
            if (item.id === zoneID) {
                for (let segment of item.segments) {
                    let point = {
                        x: segment.point.x,
                        y: segment.point.y
                    }
                    let newPoint = uncomputeCoord(point, dimension)
                    pointList.push(newPoint)
                }
            }
        })
    })

    return pointList
}


//------------------------------------------------------------------------------


export function initFilter(subtractItem = "", color = "black", opacity = 0.5) {
    let dimension = getCanvasDimension()
    let filter = null

    let rectangle = {
        topLeft: {
            x: -1,
            y: -1,
        },
        bottomRight: {
            x: dimension.x + 1,
            y: dimension.y + 1,
        }
    }

    if (subtractItem) {
        filter = new paper.Path.Rectangle(rectangle).exclude(subtractItem, { insert: false })
    } else {
        filter = new paper.Path.Rectangle(rectangle)
    }

    filter.subItem = subtractItem
    filter.evtType = "Filter"
    filter.fillColor = color
    filter.opacity = opacity
    filter.strokeWidth = 0
    filter.selectable = false

    paper.project.activeLayer.appendBottom(filter)

    paper.view.draw()

    return filter
}


//-------------------------------------


export function initDirectionTool(dirAngle, toleranceAngle) {
    let dimension = getCanvasDimension()

    let center = new paper.Point(computeCoord({ x: 50, y: 50 }, dimension))

    let radius = dimension.y / 2 - 5

    let circle = new paper.Path.Circle(center, radius)
    circle.opacity = 0.8
    circle.strokeColor = "black"
    circle.strokeWidth = 1
    // circle.dashArray = [10, 15]


    let dirPoint = new paper.Point(center.x + radius, center.y)

    let dirLine = new paper.Path.Line(center, dirPoint)
    dirLine.strokeColor = "red"
    dirLine.strokeWidth = 3


    dirLine.circle = circle
    dirLine.radius = radius


    let tmpArrow = new paper.Point(dirPoint.x - 15, dirPoint.y)

    let topArrow = new paper.Path.Line(dirPoint, tmpArrow)
    topArrow.strokeColor = "red"
    topArrow.strokeWidth = 2
    topArrow.strokeCap = "round"
    topArrow.rotate(35, dirPoint)

    let backArrow = new paper.Path.Line(dirPoint, tmpArrow)
    backArrow.strokeColor = "red"
    backArrow.strokeWidth = 2
    backArrow.strokeCap = "round"
    backArrow.rotate(-35, dirPoint)

    dirLine.topArrow = topArrow
    dirLine.backArrow = backArrow


    let tolerancePlus = new paper.Path.Line(center, dirPoint)
    tolerancePlus.strokeColor = "blue"
    tolerancePlus.opacity = 0.8
    tolerancePlus.strokeWidth = 2

    let toleranceMinus = tolerancePlus.clone()

    tolerancePlus.rotate(-20, center)
    toleranceMinus.rotate(20, center)

    dirLine.tolerancePlusLine = tolerancePlus
    dirLine.toleranceMinusLine = toleranceMinus


    let arcShadow = initDirectionToolShadow(dirLine)

    dirLine.arcShadow = arcShadow


    initDirectionToolEvent(dirLine)


    if (dirAngle) {
        dirLine.angleRotate(dirAngle)
    }

    if (toleranceAngle) {
        dirLine.toleranceRotate(toleranceAngle)
    }

    return dirLine
}


//-------------------------------------


export function disableDirectionTool(obj) {
    obj.opacity = 0.4
    obj.strokeColor = "grey"

    obj.topArrow.opacity = 0.4
    obj.topArrow.strokeColor = "grey"

    obj.backArrow.opacity = 0.4
    obj.backArrow.strokeColor = "grey"

    obj.arcShadow.opacity = 0.1

    obj.circle.opacity = 0.2
    obj.circle.strokeColor = "grey"

    obj.tolerancePlusLine.opacity = 0.2
    obj.tolerancePlusLine.strokeColor = "grey"

    obj.toleranceMinusLine.opacity = 0.2
    obj.toleranceMinusLine.strokeColor = "grey"
}


//-------------------------------------


export function enableDirectionTool(obj) {
    obj.opacity = 1
    obj.strokeColor = "red"

    obj.topArrow.opacity = 1
    obj.topArrow.strokeColor = "red"

    obj.backArrow.opacity = 1
    obj.backArrow.strokeColor = "red"

    obj.arcShadow.opacity = 0.3

    obj.circle.opacity = 0.8
    obj.circle.strokeColor = "black"

    obj.tolerancePlusLine.opacity = 0.8
    obj.tolerancePlusLine.strokeColor = "blue"

    obj.toleranceMinusLine.opacity = 0.8
    obj.toleranceMinusLine.strokeColor = "blue"
}


//-------------------------------------


function initDirectionToolEvent(obj) {
    obj.angleRotate = function(value) {
        obj.rotate(value, obj.firstSegment.point)
        obj.topArrow.rotate(value, obj.firstSegment.point)
        obj.backArrow.rotate(value, obj.firstSegment.point)
        obj.arcShadow.rotate(value, obj.firstSegment.point)
        obj.tolerancePlusLine.rotate(value, obj.firstSegment.point)
        obj.toleranceMinusLine.rotate(value, obj.firstSegment.point)
    }

    obj.anglePlus = function(value = -DIR_TOOL_ANGLE) {
        obj.angleRotate(value)
    }

    obj.angleMinus = function(value = DIR_TOOL_ANGLE) {
        obj.angleRotate(value)
    }


    obj.toleranceRotate = function(value) {
        obj.tolerancePlusLine.rotate(-value, obj.firstSegment.point)
        obj.toleranceMinusLine.rotate(value, obj.firstSegment.point)

        if (obj.tolerancePlusLine.lastSegment.point.isClose(obj.toleranceMinusLine.lastSegment.point, Math.abs(value))) {
            obj.tolerancePlusLine.rotate(value, obj.firstSegment.point)
            obj.toleranceMinusLine.rotate(-value, obj.firstSegment.point)
        } else {
            obj.arcShadow.remove()

            let arcShadow = initDirectionToolShadow(obj)

            obj.arcShadow = arcShadow
        }
    }

    obj.tolerancePlus = function(value = DIR_TOOL_TOLERANCE) {
        obj.toleranceRotate(value)
    }

    obj.toleranceMinus = function(value = -DIR_TOOL_TOLERANCE) {
        obj.toleranceRotate(value)
    }
}


//-------------------------------------


export function getDirectionToolInfo(obj) {
    let baseVector = {
        x: obj.radius,
        y: 0,
    }

    let baseDist = Math.sqrt(Math.pow(baseVector.x, 2) + Math.pow(baseVector.y, 2))


    let dirVector = {
        x: obj.lastSegment.point.x - obj.firstSegment.point.x,
        y: obj.lastSegment.point.y - obj.firstSegment.point.y,
    }

    let dirDist = Math.sqrt(Math.pow(dirVector.x, 2) + Math.pow(dirVector.y, 2))


    let toleranceVector = {
        x: obj.tolerancePlusLine.lastSegment.point.x - obj.firstSegment.point.x,
        y: obj.tolerancePlusLine.lastSegment.point.y - obj.firstSegment.point.y,
    }

    let toleranceDist = Math.sqrt(Math.pow(toleranceVector.x, 2) + Math.pow(toleranceVector.y, 2))


    let baseDotDir = baseVector.x * dirVector.x + baseVector.y * dirVector.y
    let cosDirAngle = baseDotDir / (baseDist * dirDist)
    let dirAngle = Math.acos(cosDirAngle) / (Math.PI / 180)
    let dirAngleDirected = dirVector.y > baseVector.y ? -dirAngle : dirAngle


    let dirDotTolerance = dirVector.x * toleranceVector.x + dirVector.y * toleranceVector.y
    let cosToleranceAngle = dirDotTolerance / (dirDist * toleranceDist)
    let toleranceAngle = Math.acos(cosToleranceAngle) / (Math.PI / 180)


    return {
        angle: Math.round(dirAngleDirected),
        tolerance: Math.round(toleranceAngle),
    }
}


//-------------------------------------


function initDirectionToolShadow(obj) {
    let arcShadow = new paper.Path()
    arcShadow.strokeWidth = 0
    arcShadow.fill = true
    arcShadow.fillColor = "grey"
    arcShadow.opacity = 0.3

    arcShadow.add(obj.tolerancePlusLine.lastSegment)
    arcShadow.add(obj.tolerancePlusLine.firstSegment)
    arcShadow.add(obj.toleranceMinusLine.lastSegment)

    let oppositeDir = obj.clone()
    oppositeDir.visible = false
    oppositeDir.rotate(180, obj.firstSegment.point)

    let arc = new paper.Path.Arc(obj.tolerancePlusLine.lastSegment.point, oppositeDir.lastSegment.point, obj.toleranceMinusLine.lastSegment.point)

    arcShadow.join(arc)

    return arcShadow
}


//------------------------------------------------------------------------------


export function canvasConstraint(segment, e, minX = 0, maxX = 100, minY = 0, maxY = 100) {
    let dimension = getCanvasDimension()

    let posX = segment.point.x + e.delta.x
    let posY = segment.point.y + e.delta.y

    let tolerance = 3

    minX = Math.max(dimension.x * (minX / 100), tolerance)
    maxX = Math.min(dimension.x * (maxX / 100), dimension.x - tolerance)
    minY = Math.max(dimension.y * (minY / 100), tolerance)
    maxY = Math.min(dimension.y * (maxY / 100), dimension.y - tolerance)

    if (posX <= minX || posX >= maxX || posY <= minY || posY >= maxY) {
        return false
    }

    return true
}


//-------------------------------------


export function canvasConstraintObject(object, e, minX = 0, maxX = 100, minY = 0, maxY = 100) {
    let movable = true
    object.segments.forEach(function(pathSegment) {
        movable &= canvasConstraint(pathSegment, e, minX, maxX, minY, maxY)
    })

    return movable
}


//-------------------------------------


export function zoneConstraint(object) {
    if (object.clickHitResult.type === "segment") {
        return true
    }
    return false
}


//-------------------------------------


export function baseConstraint(object, e) {
    if (object.clickHitResult.segment.point.y + e.delta.y >= object.lastSegment.point.y) {
        return false
    }
    return true
}


//-------------------------------------


export function topConstraint(object, e) {
    if (object.clickHitResult.segment.point.y + e.delta.y <= object.firstSegment.point.y) {
        return false
    }
    return true
}


//------------------------------------------------------------------------------


export function basicMove(segment, e) {
    segment.point.x += e.delta.x
    segment.point.y += e.delta.y
}


//-------------------------------------


export function basicMoveObject(object, e) {
    object.segments.forEach(function(pathSegment) {
        basicMove(pathSegment, e)
    })
}


//-------------------------------------


export function moveBase(object, e) {
    object.base.segments.forEach(function(baseSegment) {
        baseSegment.point.y += e.delta.y
    })
}


//-------------------------------------


export function moveRectangle(object, e) {
    object.segments.forEach(function(pathSegment) {
        if (object.clickHitResult.segment.point.x === pathSegment.point.x) {
            pathSegment.point.x += e.delta.x
        }

        if (object.clickHitResult.segment.point.y === pathSegment.point.y) {
            pathSegment.point.y += e.delta.y
        }
    })

    object.clickHitResult.point.x += e.delta.x
    object.clickHitResult.point.y += e.delta.y
}


//-------------------------------------


export function moveFilter(object) {
    object.filter.remove()
    object.filter = initFilter(object.filter.subItem, object.filter.color, object.filter.opacity)
}


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
