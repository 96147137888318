<!-- ===========================================================================
@author Thibault Soret, tsoret@e-vitech.com
@date 2018/05/11 by Thibault Soret
@copyright EVITECH
================================================================================

============================================================================ -->


<template>

    <div
        class="modal fade"
        id="changeLanguageModal"
        tabindex="-1"
        aria-hidden="true"
    >

        <div class="modal-dialog modal-dialog-centered" style="max-width: 500px;">

            <div class="modal-content">

                <div
                    class="modal-header"
                    :class="headerTheme"
                >

                    <div class="modal-title">
                        <b>{{ $t("Change language") }}</b>
                    </div>


                    <button
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        type="button"
                    >
                    </button>

                </div>


                <div class="modal-body">

                    <div class="container">

                        <div class="list-group">

                            <button
                                v-for="{id, label} in allLanguage"
                                :key="id"
                                class="list-group-item list-group-item-action languageButton text-center"
                                :class="isActive(id)"
                                @click="setLanguage(id)"
                                :style="{ 'border-color': borderTheme }"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >

                                {{ label }}

                            </button>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>


<!-- ======================================================================= -->


<script>


import { LANGUAGES } from "@/i18n/i18n_languages"


//-------------------------------------


export default {
    props: {
        headerTheme: {
            type: String,
            default: () => { return "w3-theme-d2" },
        },
        selectTheme: {
            type: String,
            default: () => { return "w3-theme" },
        },
        borderTheme: {
            type: String,
            default: () => { return "#777777" },
        }
    },

    computed: {
        allLanguage() {
            return LANGUAGES
        },
    },

    methods: {
        isActive: function(id) {
            if (this.$i18n.locale() === id) {
                return this.selectTheme
            }
            return ""
        },

        setLanguage: function(value) {
            this.$i18n.set(value)
            if (window.kendo) {
                window.kendo.culture(value)
            }
            this.$emit("change")
        }
    },
}

</script>


<!-- ======================================================================= -->


<style scoped>

.languageButton:hover {
    border-right: 5px solid;
    border-left: 5px solid;
}

</style>


<!-- ======================================================================= -->
<!-- End of file -->
<!-- ======================================================================= -->
