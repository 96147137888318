<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2021/04/01
@copyright EVITECH
===============================================================================

=========================================================================== -->


<template>

<div class="container-fluid">

    <h1 class="text-center">
        {{ $t("Terms of use") }}
    </h1>


    <h3>
        {{ $t("Warning") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("This warning is brought to the attention of visitors and users of the EVDesign website (hereinafter: the site, or the application). Therefore, by using the application, the visitor acknowledges having had the opportunity to be informed of it.") }}
        </p>


        <p class="text-justify">
            {{ $t("By using EVDesign, you agree to the Terms of Use. If you are executing the Terms of Use on behalf of an entity, you declare that you have the authority to legally bind that entity. If you do not agree with these Terms of Use, or do not have such authority, you are not authorized to use the Application.") }}
        </p>


        <p class="text-justify">
            {{ $t("The application presents and provides a tool for modelling the layout of CCTV cameras to monitor a site. Once an account has been created, and one or more locations defined, the user places cameras in these locations and can generate a documentary report, one per location.") }}
        </p>


        <p class="text-justify">
            {{ $t("EVITECH strives to provide a consistent software tool for placing cameras on a map and visualizing their monitored areas on the ground, in order to help the user to obtain a reasonable coverage of the environment to be monitored, assuming that the ground is flat (2D visualization and design on flat ground), and that there are no obstacles above the ground. The software tool is under the responsibility of the user and is limited to the editing of the model (placement of the cameras on the plan) and the feature of generating a documentary report. It is then up to the user to check the relevance of this documentary report, in particular by verifying it on the ground (roughness of the ground, obstacles to the view), but also on other aspects (beginning and end of zones seen by the cameras) before using it in an approach that would incur any responsibility on their part.") }}
        </p>


        <p class="text-justify">
            {{ $t("EVITECH reserves the right to correct or modify its content, at any time and without notice. Moreover, EVITECH cannot guarantee the accuracy or the exhaustiveness of the information and calculation algorithms proposed on the site. It is up to the user to check the information produced by making the calculations himself and by visiting the modelled site.") }}
        </p>


        <p class="text-justify">
            {{ $t("The user allows EVITECH to keep his e-mail address, the name of his company, and the data he has entered on the site for a period of 3 years after his last use, so as to allow him to use the site in the long term and to keep his projects there. The user is free to delete his account and/or his projects from the site if he does not wish to keep them. EVITECH will automatically delete the accounts of people who have not logged in after 3 years.") }}
        </p>

    </div>


    <h3>
        {{ $t("Terms and conditions of use") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("The use of this site is reserved for a strictly personal use. The content and the structure of this site and of the EVITECH company's sites which are linked to it by hypertext links, are protected by copyright and intellectual property rights. Any reproduction or representation, in whole or in part, of any document present on the sites concerned (in particular any text, image, iconographic or photographic representation, brand or logo) on any medium whatsoever for purposes other than personal use is prohibited. Failure to comply with this prohibition constitutes an infringement that may incur the civil and criminal liability of the infringer.") }}
        </p>


        <p class="text-justify">
            {{ $t("Only the use of reports generated by the site, and screen copies of plans made by the user, as well as copies or reproductions strictly reserved for the private use of the copier and not intended for collective use, as well as analyses and short quotations justified by the critical, educational or informative nature of the work in which they are incorporated, are authorised.") }}
        </p>


        <p class="text-justify">
            {{ $t("Consequently, you may not reproduce, represent, copy, modify, distribute, transmit, broadcast, publish, license, transfer or sell any information/data obtained from this site, nor create works derived from the aforementioned elements without having obtained the prior written consent of EVITECH. ") }}
        </p>


        <p class="text-justify">
            {{ $t("All logos, trademarks, distinctive signs, etc., present on the EVDesign site and on www.evitech.com are also protected by the Intellectual Property rights and by the trademark rights held by EVITECH SAS.") }}
        </p>


        <p class="text-justify">
            {{ $t("Any fraudulent use of all or part of this site (hacking, counterfeiting, etc.) may result in legal action.") }}
        </p>


        <p class="text-justify">
            {{ $t("The information contained in the site is non-contractual and subject to change without notice.") }}
        </p>


        <p class="text-justify">
            {{ $t("The characteristics and availability of the products and services mentioned in this site, and in particular the cameras, are indicative and reflect products that were available from certain manufacturers at the time the site was created; they are not necessarily always up to date. It is the user's responsibility to check this information.") }}
        </p>

    </div>


    <h3>
        {{ $t("Restriction of use of the content of the site") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("This site is owned and operated by EVITECH. No content from the EVITECH site or other websites owned, operated, licensed or controlled by EVITECH may be copied, reproduced, republished, uploaded, transmitted or distributed in any way. You may download a single copy of our content onto a single computer for your own personal, non-commercial use only, provided you leave all copyright and proprietary notices intact. Modification of the content or use of the content for any other purpose is a violation of EVITECH's copyright and other intellectual property rights. In the event that you download software from this site, the software, including any files incorporating the images and databases accompanying the software (collectively, the \"Software\"), is licensed to you by EVITECH. EVITECH hereby does not transfer any ownership of its software. EVITECH retains full rights to the Software and all intellectual property rights in the Software. You may not distribute, sell, decompile, reverse engineer, or dissociate the software.") }}
        </p>


        <p class="text-justify">
            {{ $t("When you use the EVDesign tool from this site, you are granted by EVITECH a license to use the generated image files and data. EVITECH does not hereby transfer to you the right to the technology. You may not distribute, sell, decompile, reverse engineer, or dissociate the software.") }}
        </p>

    </div>


    <h3>
        {{ $t("Jurisdiction") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("The EVITECH website is hosted in Europe but French law is applicable.") }}
        </p>


        <p class="text-justify">
            {{ $t("The EVITECH brand, EVDesign and all other EVITECH products, names and codes are registered trademarks of EVITECH SAS.") }}
        </p>


        <p class="text-justify">
            {{ $t("All other brands and product names are trademarks of the companies to which they belong.") }}
        </p>

    </div>


    <h3>
        {{ $t("Limitation of Liability") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("EVITECH commits itself to implement the necessary means for a peaceful and continuous use of this site, but cannot however commit itself to the permanent availability of the features available on the site, to the immediate correction of any errors that might be noticed, to the absence of viruses or any other component that would threaten the integrity of the user's data") }}
        </p>


        <p class="text-justify">
            {{ $t("EVITECH relies on the user's judgement as to the suitability of the solutions proposed on the site for his personal situation. The user therefore assumes the consequences that could result from the inappropriate use of the tools proposed by EVITECH. IN NO EVENT SHALL EVITECH BE LIABLE FOR ANY DAMAGES WHATSOEVER RESULTING FROM THE USE OR MISUSE OF THE CONTENT OF THIS SITE, EVEN IF EVITECH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.") }}
        </p>


        <p class="text-justify">
            {{ $t("In a general way, EVITECH cannot be held responsible of:") }}

            <ul>

                <li>
                    {{ $t("any imprecision, inaccuracy or omission related to the information published on the site;") }}
                </li>


                <li>
                    {{ $t("any damage resulting from a fraudulent intrusion of a third party having led to a modification of the information disseminated on the site;") }}
                </li>


                <li>
                    {{ $t("any damage, direct or indirect, whatever the causes, origins, nature or consequences, resulting from the use of its site or the impossibility of accessing it, or from the use of other sites which are linked to it by hypertext links.") }}
                </li>

            </ul>

        </p>

    </div>


    <h3>
        {{ $t("Use of background images") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("The EVDesign application allows the user to embed a Google Maps background map or a personal image (hereinafter referred to as the images) that are either the property of Google Maps or images for which the user has the right to use. As a user of this product, you agree not to use images for which you do not have the rights of use.") }}
        </p>


        <p class="text-justify">
            {{ $t("The data you submit using the Tool cannot exceed 1GB.") }}
        </p>


        <p class="text-justify">
            {{ $t("You cannot :") }}

            <ol>

                <li>
                    {{ $t("Create images of a scandalous, obscene, defamatory, immoral or public order nature, or to use these images for any other purpose that is prohibited by law.") }}
                </li>


                <li>
                    {{ $t("Use or permit any use of these images in whole or in part as a trademark or service mark or claim any ownership rights of any kind in the images.") }}
                </li>


                <li>
                    {{ $t("Use these images in online electronic formats, or in multimedia applications.") }}
                </li>


                <li>
                    {{ $t("Renting, licensing, lending or copying the images to any other person or entity, unless the origin of the images is explicitly stated.") }}
                </li>


                <li>
                    {{ $t("Make any use other than as expressly permitted by this license.") }}
                </li>

            </ol>


            {{ $t("Please contact EVITECH if you have any comments or questions about this site.") }}

        </p>

    </div>


    <h3>
        {{ $t("Parental consent") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("EVITECH invites children under the age of 18 to seek their parents' consent before providing any information or data about themselves or their family's consumer profile.") }}
        </p>


        <p class="text-justify">
            {{ $t("Furthermore, EVITECH encourages parents to supervise their children's web activity and wants children to obtain permission before registering on the site.") }}
        </p>

    </div>


    <h3>
        {{ $t("Exemption and limitation of liability") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("(a) EVITECH cannot be responsible for any viruses or other contamination of your computer system or other terminals used to access the Site in connection with your use of the Site. The Site (which includes without limitation all content, software, features, documents and information made available or described on or accessible through the Site) is provided on an \"as is\" basis, without any representations or warranties of any kind, either express or implied, including but not limited to warranties of merchantability or fitness for a particular purpose, title, compatibility, security, accuracy or non-infringement. Without limiting the foregoing, EVITECH makes no representations or warranties, express or implied, as to uninterrupted or defect-free access to the operations and activities of the Site. EVITECH, its suppliers, or any other third party involved in creating, producing or publishing the contents of the site shall not be liable for the truthfulness, accuracy or completeness of the information on this site, for errors or mistakes made by humans or machines, or for any actual, special, indirect, direct or consequential damages arising out of the use of, or the inability to use, the site and/or the materials and information contained or made available through the site. The limitations contained herein may not apply in some jurisdictions.") }}
        </p>


        <p class="text-justify">
            {{ $t("(b) You agree to rely on any advice, opinions, statements, or other information displayed or distributed through the Site at your own risk. EVITECH reserves the right, in its sole discretion and without notice, to deny access to the Site at any time to any person. Neither EVITECH nor its affiliates shall be liable for your reliance on any information posted on the Site.") }}
        </p>


        <p class="text-justify">
            {{ $t("(c) Use of the Site is at your own risk. You are entirely responsible for the risk of loss resulting from your downloading and/or use of any files, other communications or materials accessible or obtained through the Site.") }}
        </p>


        <p class="text-justify">
            {{ $t("(d) We cannot guarantee the confidentiality of any communication or information transmitted through the Site or sites linked to the Site. We cannot be responsible for the confidentiality of e-mail addresses, registration and identification data, disk space, communications, confidential information or trade secrets, or other content stored on your equipment, transmitted over networks accessible through the Site or otherwise connected with your use of the Site. ") }}
        </p>

    </div>


    <h3>
        {{ $t("Compensation") }}
    </h3>


    <div class="mx-3">
        {{ $t("You agree to indemnify and hold harmless EVITECH, its parents, subsidiaries, affiliates, agents, directors and employees from and against any claims or demands, including legal fees, made by any third party due to or arising out of your use of the Site, your breach of these Terms and Conditions, or your violation of any intellectual property or other rights of any person or entity. ") }}
    </div>


    <h3>
        {{ $t("Applicable legislation") }}
    </h3>


    <div class="mx-3">
        {{ $t("EVITECH operates the Site from its offices in France and makes no hypotheesis that materials on the Site are appropriate or available for use in other locations. Access to the Site from territories where the content is illegal is prohibited. If you choose to access the Site from other locations, you do so at your own risk and you are solely responsible for compliance with applicable laws and regulations. The display of the Site does not bind EVITECH to any specific jurisdiction. You may not use or export the materials and information on the Site in violation of applicable EU export laws and regulations. If any of the provisions contained herein shall be deemed invalid, unenforceable, for whatever reason, then that provision shall be deemed separate and shall not affect the validity and enforceability of any remaining terms or conditions. Claims arising out of the use of the Site or the Materials/Information on the Site shall be governed by, construed and enforced in accordance with French law. The parties agree and acknowledge that all disputes and legal actions arising in connection with the site or its contents shall be brought before the competent court in Paris, France. Communications made via the site's messaging system and e-mail do not constitute notice or a basis for EVITECH, its agents, employees, officers or representatives to give any notice required by contract, law, rule or regulation.") }}
    </div>


    <h3>
        {{ $t("RGPD compliance") }}
    </h3>


    <div class="mx-3">

        <p class="text-justify">
            {{ $t("\"The information collected about you is processed by EVITECH's Operations Department") }}
        </p>


        <p class="text-justify">
            {{ $t("For the following purpose: storage of site locations for perimeter protection") }}
        </p>


        <p class="text-justify">
            {{ $t("The recipients of this data are: the EVITECH sales team") }}
        </p>


        <p class="text-justify">
            {{ $t("The duration of data retention is 3 years from the last connection of the user to the EVDesign website") }}
        </p>


        <p class="text-justify">
            {{ $t("You have a right of access, rectification, portability, deletion or limitation of processing (deletion of your personal information: name, first name, telephone number, email, company).") }}
        </p>


        <p class="text-justify">
            {{ $t("You may object to the processing of your data and have the right to withdraw your consent at any time by contacting: rgpd-gdpr@e-vitech.com") }}
        </p>


        <p class="text-justify">
            {{ $t("You have the right to lodge a complaint with a supervisory authority.\"") }}
        </p>

    </div>

</div>

</template>


<!-- ====================================================================== -->


<script>

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------


export default {
    beforeCreate() {
        this.$i18n.add(FR, translationFR)
    },
}

</script>


<!-- ====================================================================== -->


<style scoped>

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
