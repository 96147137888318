<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <div>

        <div
            class="text-center"
            id="features1"
        >
            <div class="col-12">

                <h2 class="title">
                    {{ $t("Why use EVDesign ?") }}
                </h2>


                <h5 class="description">
                    {{ $t("EVDesign is a modern and user-friendly web based application to easily and accurately choose and place cameras when deploying perimeter protection using video analytics, so as to optimize and guarantee optimal performance.") }}
                </h5>

            </div>


            <div class="row">

                <div class="col-4">

                    <div class="info">

                        <div class="icon icon-info">
                            <i class="fa fa-camera"></i>
                        </div>

                        <p>
                            {{ $t("Compatibility with the commonly used cameras manufacturers and models on the market, plus ability to add your own cameras.") }}
                        </p>

                    </div>

                </div>


                <div class="col-md-4">

                    <div class="info">

                        <div class="icon icon-success">
                            <i class="fa fa-bullhorn"></i>
                        </div>


                        <p>
                            {{ $t("Automatic visualization of uncovered areas and expected detection ranges with our applications.") }}
                        </p>

                    </div>

                </div>


                <div class="col-md-4">

                    <div class="info">

                        <div class="icon icon-danger">
                            <i class="fa fa-building"></i>
                        </div>


                        <p>
                            {{ $t("Easy export of plans and camera lists as images or PDF documents, ready to insert and use in your projects or tender responses.") }}
                        </p>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<!-- ====================================================================== -->

<script>

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------


export default {
    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>

@import "../css/style.css";

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
