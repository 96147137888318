<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2021/03/04
@copyright EVITECH
===============================================================================

=========================================================================== -->


<template>

<div class="cameras-factory">

    <div>
        {{ $t("Filters : {display} / {total}", {display: camerasFilteredListSize, total: allCamerasListSize}) }}
    </div>


    <div class="row mx-0">

        <div class="col px-0">

            <div class="input-group">

                <span
                    id="manufacturerFilterID"
                    class="input-group-text man-mod-detail"
                >
                    {{ $t("Manufacturer") }}
                </span>


                <input
                    type="text"
                    :placeholder="$t('Manufacturer')"
                    class="form-control border text-input"
                    aria-describedby="manufacturerFilterID"
                    v-model="filter.manufacturer"
                />

            </div>

        </div>


        <div class="col px-0">

            <div class="input-group">

                <span
                    id="modelFilterID"
                    class="input-group-text man-mod-detail"
                >
                    {{ $t("Model") }}
                </span>


                <input
                    type="text"
                    :placeholder="$t('Model')"
                    class="form-control border text-input"
                    aria-describedby="modelFilterID"
                    v-model="filter.model"
                />

            </div>

        </div>


        <div class="col px-0">

            <div class="input-group">

                <span
                    id="typeFilterID"
                    class="input-group-text man-mod-detail"
                >
                    {{ $t("Sensor type") }}
                </span>


                <select
                    id="typeFilterID"
                    class="form-select"
                    v-model="filter.sensor_type"
                >

                    <option :value="undefined">
                        {{ $t("No filter") }}
                    </option>


                    <option value="1">
                        {{ $t("Visible") }}
                    </option>


                    <option value="2">
                        {{ $t("Thermal") }}
                    </option>


                    <option value="3">
                        {{ $t("Thermographic") }}
                    </option>

                </select>

            </div>

        </div>

    </div>


    <div class="row mx-0 border">

        <div class="col model-col px-0">

            <div
                v-for="(camera, index) in camerasFiltered"
                :key=camera.id
                :name="camera.camera_factory_id"
                class="model-row"
                :class="{'pair-row': index % 2 === 0}"
                :style="getModelRowBackground(index, camera)"
                @click="select(camera)"
            >
                {{ getCameraLabel(camera) }}
            </div>

        </div>


        <div class="col">

            <div v-if="selectedCamera && !creationMode">

                <div class="input-group">

                    <span
                        id="manufacturerID"
                        class="input-group-text man-mod-detail"
                    >
                        {{ $t("Manufacturer") }}
                    </span>


                    <input
                        type="text"
                        :placeholder="$t('Manufacturer')"
                        class="form-control border text-input"
                        aria-describedby="manufacturerID"
                        v-model="selectedCamera.manufacturer"
                        disabled
                    />

                </div>


                <div class="input-group">

                    <span
                        id="modelID"
                        class="input-group-text man-mod-detail"
                    >
                        {{ $t("Model") }}
                    </span>


                    <input
                        type="text"
                        :placeholder="$t('Model')"
                        class="form-control border text-input"
                        aria-describedby="modelID"
                        v-model="selectedCamera.model"
                        disabled
                    />

                </div>


                <div class="input-group">

                    <span
                        id="typeID"
                        class="input-group-text man-mod-detail"
                    >
                        {{ $t("Sensor type") }}
                    </span>


                    <select
                        id="typeID"
                        class="form-select"
                        v-model="selectedCamera.sensor_type"
                        disabled
                    >

                        <option :value="undefined">
                            {{ $t("Choose a sensor type") }}
                        </option>


                        <option value="1">
                            {{ $t("Visible") }}
                        </option>


                        <option value="2">
                            {{ $t("Thermal") }}
                        </option>


                        <option value="3">
                            {{ $t("Thermographic") }}
                        </option>

                    </select>

                </div>


                <div>

                    <div>
                        {{ $t("Sensor details") }}
                    </div>


                    <div class="ms-3">

                        <div class="form-inline">

                            <div class="input-group aov-info">

                                <span
                                    id="widthID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Width") }}
                                </span>


                                <input
                                    type="text"
                                    :placeholder="$t('Width')"
                                    class="form-control border text-input"
                                    aria-describedby="widthID"
                                    v-model="selectedCamera.sensor_width"
                                    disabled
                                />

                            </div>


                            <div class="input-group aov-info">

                                <span
                                    id="heightID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Height") }}
                                </span>


                                <input
                                    type="text"
                                    :placeholder="$t('Height')"
                                    class="form-control border text-input"
                                    aria-describedby="heightID"
                                    v-model="selectedCamera.sensor_height"
                                    disabled
                                />

                            </div>

                        </div>

                    </div>

                </div>


                <div>

                    <div>
                        {{ $t("FOV") }}
                    </div>


                    <div class="ms-3">

                        <div class="form-inline d-flex">

                            <div class="input-group aov-info">

                                <span
                                    id="AOVHMinID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Horiz min") }}
                                </span>


                                <input
                                    type="text"
                                    :placeholder="'-'"
                                    class="form-control border text-input"
                                    aria-describedby="AOVHMinID"
                                    v-model="selectedCamera.aov_h_min"
                                    disabled
                                />

                            </div>


                            <div class="input-group aov-info">

                                <span
                                    id="AOVHMaxID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Horiz max") }}
                                </span>


                                <input
                                    type="text"
                                    :placeholder="'-'"
                                    class="form-control border text-input"
                                    aria-describedby="AOVHMaxID"
                                    v-model="selectedCamera.aov_h_max"
                                    disabled
                                />

                            </div>

                        </div>


                        <div class="form-inline d-flex">

                            <div class="input-group aov-info">

                                <span
                                    id="AOVVMinID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Vert min") }}
                                </span>


                                <input
                                    type="text"
                                    :placeholder="'-'"
                                    class="form-control border text-input"
                                    aria-describedby="AOVVMinID"
                                    v-model="selectedCamera.aov_v_min"
                                    disabled
                                />

                            </div>


                            <div class="input-group aov-info">

                                <span
                                    id="AOVVMaxID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Vert max") }}
                                </span>


                                <input
                                    type="text"
                                    :placeholder="'-'"
                                    class="form-control border text-input"
                                    aria-describedby="AOVVMaxID"
                                    v-model="selectedCamera.aov_v_max"
                                    disabled
                                />

                            </div>

                        </div>

                    </div>

                </div>

            </div>


            <div v-if="creationMode && !selectedCamera">

                <div class="input-group">

                    <span
                        id="manufacturerID"
                        class="input-group-text man-mod-detail"
                    >
                        {{ $t("Manufacturer") }}
                    </span>


                    <input
                        type="text"
                        :placeholder="$t('Manufacturer')"
                        class="form-control border text-input"
                        aria-describedby="manufacturerID"
                        v-model="newCamera.manufacturer"
                    />

                </div>


                <div class="input-group">

                    <span
                        id="modelID"
                        class="input-group-text man-mod-detail"
                    >
                        {{ $t("Model") }}
                    </span>


                    <input
                        type="text"
                        :placeholder="$t('Model')"
                        class="form-control border text-input"
                        aria-describedby="modelID"
                        v-model="newCamera.model"
                    />

                </div>


                <div class="input-group">

                    <span
                        id="typeID"
                        class="input-group-text man-mod-detail"
                    >
                        {{ $t("Sensor type") }}
                    </span>


                    <select
                        id="typeID"
                        class="form-select"
                        v-model="newCamera.sensor_type"
                    >

                        <option :value="undefined" disabled>
                            {{ $t("Choose a sensor type") }}
                        </option>


                        <option value="1">
                            {{ $t("Visible") }}
                        </option>


                        <option value="2">
                            {{ $t("Thermal") }}
                        </option>


                        <option value="3">
                            {{ $t("Thermographic") }}
                        </option>

                    </select>

                </div>


                <div>

                    <div>
                        {{ $t("Sensor details") }}
                    </div>


                    <div class="ms-3">

                        <div class="form-inline d-flex">

                            <div class="input-group aov-info">

                                <span
                                    id="widthID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Width") }}
                                </span>


                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    :placeholder="$t('Width')"
                                    class="form-control border text-input"
                                    aria-describedby="widthID"
                                    v-model="newCamera.sensor_width"
                                />

                            </div>

                        </div>


                        <div class="form-inline d-flex">

                            <div class="input-group aov-info">

                                <span
                                    id="heightID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Height") }}
                                </span>


                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    :placeholder="$t('Height')"
                                    class="form-control border text-input"
                                    aria-describedby="heightID"
                                    v-model="newCamera.sensor_height"
                                />

                            </div>

                        </div>

                    </div>

                </div>


                <div>

                    <div>
                        {{ $t("FOV") }}
                    </div>


                    <div class="ms-3">

                        <div class="form-inline d-flex">

                            <div class="input-group aov-info">

                                <span
                                    id="AOVHMinID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Horiz min") }}
                                </span>


                                <input
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    :placeholder="'-'"
                                    class="form-control border text-input"
                                    aria-describedby="AOVHMinID"
                                    v-model="newCamera.aov_h_min"
                                />

                            </div>


                            <div class="input-group aov-info">

                                <span
                                    id="AOVHMaxID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Horiz max") }}
                                </span>


                                <input
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    :placeholder="'-'"
                                    class="form-control border text-input"
                                    aria-describedby="AOVHMaxID"
                                    v-model="newCamera.aov_h_max"
                                />

                            </div>

                        </div>


                        <div class="form-inline d-flex">

                            <div class="input-group aov-info">

                                <span
                                    id="AOVVMinID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Vert min") }}
                                </span>


                                <input
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    :placeholder="'-'"
                                    class="form-control border text-input"
                                    aria-describedby="AOVVMinID"
                                    v-model="newCamera.aov_v_min"
                                />

                            </div>


                            <div class="input-group aov-info">

                                <span
                                    id="AOVVMaxID"
                                    class="input-group-text aov-detail"
                                >
                                    {{ $t("Vert max") }}
                                </span>


                                <input
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    :placeholder="'-'"
                                    class="form-control border text-input"
                                    aria-describedby="AOVVMaxID"
                                    v-model="newCamera.aov_v_max"
                                />

                            </div>

                        </div>

                    </div>

                </div>


                <div class="mt-1">

                    <button
                        class="btn evdesign-btn m-auto"
                        @click="addCustomCamera"
                        :disabled="!newCameraValidator"
                    >
                        {{ $t("Add this custom camera to the list") }}
                    </button>

                </div>

            </div>


            <div v-if="!selectedCamera && !creationMode">
                {{ $t("Select the model of the camera by clicking in the list on the left") }}
            </div>

        </div>

    </div>


    <div>

        <i class="far fa-question-circle text-info"></i>

        {{ $t("The model needed is not in the list ? Send a mail to support@evdesign.com or create your own custom camera") }}

        <button
            class="btn evdesign-btn d-inline"
            :disabled="creationMode"
            @click="setCreationMode"
        >
            {{ $t("Add a custom model") }}
        </button>

    </div>

</div>

</template>


<!-- ====================================================================== -->


<script>

import {
    uniqBy,
    sortBy,
    findIndex,
    filter,
} from "lodash"

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-----------------------------------------------------------------------------


export default {
    data: function() {
        return {
            cameras: [],
            filter: {
                manufacturer: "",
                model: "",
                sensor_type: undefined,
            },
            selectedCamera: undefined,
            creationMode: false,
            newCamera: {
                manufacturer: "",
                model: "",
                sensor_type: undefined,
                sensor_height: "",
                sensor_width: "",
                aov_h_min: "",
                aov_h_max: "",
                aov_v_min: "",
                aov_v_max: "",
            },
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    beforeMount: function() {
        this.getCameras()
    },

    computed: {
        cameraManufacturers: function() {
            return uniqBy(this.cameras, "manufacturer")
        },


        allCamerasListSize: function() {
            return this.cameras.length
        },

        camerasFiltered: function() {
            let res = this.cameras

            res = this.applyManufacturerFilter(res)
            res = this.applyModelFilter(res)
            res = this.applyTypeFilter(res)
            // res = this.applyMobileFilter(res)
            // res = this.applyDetailsFilter(res)
            // res = this.applyFOVFilters(res)
            res = this.applyAlphabeticSort(res)
            // res = this.applyUsedSort(res)

            return res
        },

        camerasFilteredListSize: function() {
            return this.camerasFiltered.length
        },

        newCameraValidator: function() {
            let res = true

            res &= this.newCamera.manufacturer !== ""
            res &= this.newCamera.model !== ""
            res &= this.newCamera.sensor_type !== undefined

            res &= this.newCamera.sensor_height !== ""
            res &= !Number.isNaN(Number(this.newCamera.sensor_height))

            res &= this.newCamera.sensor_width !== ""
            res &= !Number.isNaN(Number(this.newCamera.sensor_width))

            res &= this.newCamera.aov_h_min !== ""
            res &= !Number.isNaN(Number(this.newCamera.aov_h_min))

            res &= this.newCamera.aov_v_min !== ""
            res &= !Number.isNaN(Number(this.newCamera.aov_v_min))

            if (this.newCamera.aov_h_max || this.newCamera.aov_v_max) {
                res &= this.newCamera.aov_h_max !== ""
                res &= !Number.isNaN(Number(this.newCamera.aov_h_max))

                res &= this.newCamera.aov_v_max !== ""
                res &= !Number.isNaN(Number(this.newCamera.aov_v_max))
            }


            return res
        }
    },

    methods: {
        getCameras: function() {
            let username = this.axios.defaults.headers.common["Authorization"]

            let data = {
                username: username,
            }

            let request = {
                method: "post",
                url: "evdesign/projects/cameras/get_factory_cameras",
                data: data,
                type: "json"
            }

            this.axios(request)
                .then(response => {
                    this.cameras = response.data.data
                })
                .catch((error) => {
                    console.log("Error: ", error)
                })
        },

        applyManufacturerFilter: function(res) {
            if (this.filter.manufacturer) {
                res = filter(res, (camera) => {
                    return camera.manufacturer.toLowerCase().includes(this.filter.manufacturer.toLowerCase())
                })
            }
            return res
        },

        applyModelFilter: function(res) {
            if (this.filter.model) {
                res = filter(res, (camera) => {
                    return camera.model.toLowerCase().includes(this.filter.model.toLowerCase())
                })
            }
            return res
        },

        applyTypeFilter: function(res) {
            if (this.filter.sensor_type) {
                res = filter(res, (camera) => {
                    return camera.sensor_type === parseInt(this.filter.sensor_type)
                })
            }
            return res
        },

        applyAlphabeticSort: function(res) {
            res = sortBy(res, ["manufacturer", "model"])
            return res
        },

        getModelRowBackground: function(index, camera) {
            if (this.selectedCamera) {
                if (camera.model === this.selectedCamera.model) {
                    return {
                        "background-color": "#c27e19",
                        "color": "white",
                    }
                }
            }
            return ""
        },

        select: function(camera) {
            this.creationMode = false
            this.selectedCamera = camera
            this.$emit("selectedCamera", this.selectedCamera)
        },

        reset: function() {
            this.selectedCamera = undefined
            this.creationMode = false
        },

        getCameraLabel: function(camera) {
            let label = camera.manufacturer + " - " + camera.model
            return label
        },

        getSensorType: function(camera) {
            let typeLabel = ""

            if (camera.sensor_type === 1) {
                typeLabel = this.$t("Visible")
            } else if (camera.sensor_type === 2) {
                typeLabel = this.$t("Thermal")
            } else if (camera.sensor_type === 3) {
                typeLabel = this.$t("Thermographic")
            } else {
                typeLabel = this.$t("Unknown type")
            }

            return typeLabel
        },

        setCreationMode: function() {
            this.selectedCamera = undefined
            this.creationMode = true
        },

        addCustomCamera: function() {
            let formData = {
                factory_id: "",
                manufacturer: this.newCamera.manufacturer,
                model: this.newCamera.model,
                sensor_type: this.newCamera.sensor_type,
                sensor_width: this.newCamera.sensor_width,
                sensor_height: this.newCamera.sensor_height,
                aov_h_min: this.newCamera.aov_h_min,
                aov_h_max: this.newCamera.aov_h_max,
                aov_v_min: this.newCamera.aov_v_min,
                aov_v_max: this.newCamera.aov_v_max,
                is_mobile: false,
                username: this.axios.defaults.headers.common["Authorization"],
            }

            this.axios({
                method: "post",
                url: "evdesign/projects/cameras/create_custom_camera",
                type: "json",
                data: formData,
            })
                .then(response => {
                    let data = response.data
                    this.getCameras()
                    setTimeout(() => {
                        let newCameraID = data.new_camera_id
                        this.select(this.cameras[findIndex(this.cameras, cam => { return cam.factory_camera_id === newCameraID })])
                    }, 200)
                })
                .catch((error) => {
                    console.log("ERROR", error)
                })
        },
    },
}

</script>


<!-- ====================================================================== -->


<style scoped>

.model-col {
    overflow-y: scroll;
    height: 338px;
}

.model-row:hover {
    cursor: pointer;
    background-color: #df8e14 !important;
    color: white !important;
}

.pair-row {
    background-color: rgb(245, 245, 245);
}


.input-group,
.input-group-text {
    height: 35.7667px;
}

.aov-info {
    width: 50% !important;
}

.aov-detail {
    width: 90px !important;
}

.man-mod-detail {
    width: 135px !important
}

.text-input {
    padding: 17px 12px;
    height: 1em;
}

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
