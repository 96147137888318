import { render, staticRenderFns } from "./app_menu.vue?vue&type=template&id=3ca035b6&scoped=true"
import script from "./app_menu.vue?vue&type=script&lang=js"
export * from "./app_menu.vue?vue&type=script&lang=js"
import style0 from "./app_menu.vue?vue&type=style&index=0&id=3ca035b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-4326269b5f/0/cache/vue-loader-npm-15.11.1-829412283a-509a816d45.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ca035b6",
  null
  
)

export default component.exports