//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/05/11 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


import axios from "axios"


//------------------------------------------------------------------------------


const token = sessionStorage.getItem("user-token") || ""


//------------------------------------------------------------------------------


export function autoAuthorization(token) {
    if (token) {
        axios.defaults.headers.common.Authorization = token
    }
}


//------------------------------------------------------------------------------


export default { token }


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
