<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/09/16
@copyright EVITECH
===============================================================================
Alert
=========================================================================== -->

<template>

    <div
        class="alert w-100"
        :class="getAlertClass"
    >

        {{ $t(message) }}

        <slot></slot>

    </div>

</template>

<!-- ====================================================================== -->


<script>

export default {
    props: {
        message: {
            type: String,
            default: "",
        },
        alertType: {
            type: String,
            default: "danger",
        },
    },

    computed: {
        getAlertClass: function() {
            return "alert-" + this.alertType
        }
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>
</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
