<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/08/20
@copyright EVITECH
===============================================================================

Composant pour ajouter une camÃ©ra

=========================================================================== -->

<template>

<div class="modal projectSettingsModal fade">

    <div class="modal-dialog animated modal-lg">

        <div class="modal-content">

            <div class="modal-header">

                <div class="modal-title fw-bold">
                    {{ $t("Change project settings") }}
                </div>


                <button
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="button"
                >
                </button>

            </div>


            <div class="modal-body">

                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >

                    <div class="d-flex align-items-center align-self-center">

                        <div class="label">

                            <div>
                                {{ $t("Project name") }}
                            </div>

                            <div style="font-size: .7em;">
                                {{ $t("(max: 30 characters)") }}
                            </div>

                        </div>


                        <div class="w-100">

                            <input
                                type="text"
                                name="name"
                                class="border"
                                maxlength="30"
                                v-model.trim="project.name"
                                :placeholder="$t('Project name')"
                                :class="[(errors.length > 0 ) ? 'border-danger' : '']"
                            />

                        </div>


                        <div class="validation-error">
                            {{ $t(errors[0]) }}
                        </div>

                    </div>

                </ValidationProvider>


                <div class="projectSettings mt-2">

                    <div class="">
                        {{ $t("Project default properties") }}
                    </div>


                    <div class="ms-3">

                        <div>

                            <div class="d-flex align-items-center align-self-center">

                                <div class="label">
                                    {{ $t("Default camera height :") }}
                                </div>


                                <input
                                    class="p-2 m-0 border rounded"
                                    type="number"
                                    step="0.1"
                                    min="0.0"
                                    max="50.0"
                                    :value="project.settings.default_cam_height"
                                    @change="setDefaultHeight"
                                />


                                <div class="ms-2">
                                    {{ $t("meters") }}
                                </div>

                            </div>


                            <vue-slider
                                class="mx-3"
                                tooltip="hover"
                                :useKeyboard=false
                                :tooltip-formatter="project.settings.default_cam_height+' m'"
                                :interval="0.1"
                                :min="0.1"
                                :max="50"
                                :value="project.settings.default_cam_height"
                                @change="setDefaultHeightSlider"
                            />

                        </div>


                        <div class="d-flex align-items-center align-self-center">

                            <div class="label">
                                {{ $t("Default target height :") }}
                            </div>


                            <input
                                class="p-2 m-0 border rounded"
                                type="number"
                                min="0.1"
                                max="10.0"
                                step="0.1"
                                :value="project.settings.target_height"
                                @change="setTargetHeight"
                            />


                            <div class="ms-2">
                                {{ $t("meters") }}
                            </div>

                        </div>


                        <div class="d-flex align-items-center align-self-center">

                            <div class="label">
                                {{ $t("Target's height in pixels :") }}
                            </div>


                            <input
                                class="p-2 m-0 border rounded"
                                type="number"
                                step="any"
                                min="1"
                                :value="project.settings.target_pixels"
                                @change="setTargetPixel"
                            />


                            <div class="ms-2">
                                {{ $t("pixels") }}
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

</template>

<!-- ====================================================================== -->

<script>

import $ from "jquery"
import VueSlider from "vue-slider-component"
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate"
import { ToggleButton } from "vue-js-toggle-button"

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------

export default {
    props: {
        project: {
            type: Object,
            required: true,
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    mounted: function() {
        $(".projectSettingsModal").on("hidden.bs.modal", function() {
            this.$emit("closeModal")
        }.bind(this))
    },

    methods: {
        setDefaultHeightSlider: function(e) {
            this.project.settings.default_cam_height = e
        },


        setDefaultHeight: function(e) {
            let value = parseFloat(e.target.value)
            if (value > 50.0) {
                value = 50
            }
            if (value < 0.0 || Number.isNaN(value)) {
                value = 0
            }

            if (!Number.isInteger(value)) {
                value = Number(value.toFixed(1))
            }

            this.project.settings.default_cam_height = value
            e.target.value = this.project.settings.default_cam_height
        },

        setTargetHeight: function(e) {
            let value = parseFloat(e.target.value)
            if (value < 0.1 || Number.isNaN(value)) {
                value = 0.1
            }

            if (!Number.isInteger(value)) {
                value = Number(value.toFixed(1))
            }

            this.project.settings.target_height = value
            e.target.value = this.project.settings.target_height
        },

        setTargetPixel: function(e) {
            let value = parseInt(e.target.value)
            if (value < 1 || Number.isNaN(value)) {
                value = 1
            }

            this.project.settings.target_pixels = value
            e.target.value = this.project.settings.target_pixels
        },
    },

    components: {
        ValidationProvider,
        ValidationObserver,
        VueSlider,
        ToggleButton,
    },
}
</script>

<!-- ====================================================================== -->

<style scoped>

.label {
    width: 250px;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
