//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/07/23 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


import { FR, EN } from "@/i18n/i18n_languages"


//------------------------------------------------------------------------------


let moment = require("moment")


//------------------------------------------------------------------------------


let defaultDateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
}


//------------------------------------------------------------------------------


export function convertDate(date, locale, dateOptions = defaultDateOptions) {
    return date.toLocaleDateString(locale, dateOptions)
}


//-------------------------------------


export function convertTime(time, locale) {
    return time.toLocaleTimeString(locale)
}


//-------------------------------------


export function convertDuration(duration, locale) {
    // duration must be milliseconds

    let durationSeconds = Math.floor(duration / 1000)

    let days = Math.floor(durationSeconds / 86400)
    let hours = Math.floor((durationSeconds % 86400) / 3600)
    let minutes = Math.floor((durationSeconds % 3600) / 60)
    let seconds = durationSeconds % 60

    let dayFormat
    let hourFormat
    let minuteFormat
    let secondFormat
    if (locale === FR) {
        dayFormat = "j"
        hourFormat = "h"
        minuteFormat = "min"
        secondFormat = "s"
    } else if (locale === EN) {
        dayFormat = "d"
        hourFormat = "h"
        minuteFormat = "m"
        secondFormat = "s"
    } else {
        console.log("ERROR - convertDuration - Unknown locale")
        dayFormat = "d"
        hourFormat = "h"
        minuteFormat = "m"
        secondFormat = "s"
    }

    let separator = " "
    let res = ""
    if (days > 0) {
        res += days + dayFormat + separator
    }
    if (hours > 0) {
        res += hours + hourFormat + separator
    }
    if (minutes > 0) {
        res += minutes + minuteFormat + separator
    }
    if (seconds > 0) {
        res += seconds + secondFormat + separator
    }

    return res
}


//-------------------------------------


export function date0UTC(date) {
    let utcDate = moment.utc(date)
    utcDate.set({
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate(),
        hour: 0,
        minute: 0,
        second: 0,
    })
    return new Date(utcDate.format())
}


//-------------------------------------


export function dateUTCISO(date) {
    let utcDate = moment.utc(date)
    utcDate.set({
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
        milliseconds: 0,
    })
    return utcDate.toISOString()
}


//-------------------------------------


export function convertObjectDates(obj) {
    // Convertie toutes les dates d'un objet en dates UTC
    // sans prendre en compte les GTM+X
    // A utiliser lors des requêtes vers le serveur

    let clone = {}
    for (let key in obj) {
        let value = obj[key]
        if (value instanceof Object) {
            if (value instanceof Date) {
                value = dateUTCISO(value)
            } else {
                value = convertObjectDates(value)
            }
        }
        clone[key] = value
    }
    return clone
}


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
