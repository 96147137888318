//------------------------------------------------------------------------------
// @author Eric Paul, epaul@evitech.com
// @date 2018/02/20 by Eric Paul
// @copyright EVITECH
//------------------------------------------------------------------------------


import Vue from "vue"
import { sync } from "vuex-router-sync"
import { defineI18n } from "@/i18n/setup"
import { setupNoty } from "@/noty/setup"
import { setupAxios } from "@/axios/setup"
import { setupBus } from "@/bus/setup"

import VueObserveVisibility from "vue-observe-visibility"

import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "w3-css/w3.css"
import "@fortawesome/fontawesome-free/css/all.css"


// L'application est définie via le composant Application


import Application from "./Application"


// On utilise le routeur définit dans le répertoire "router"


import router from "./router"


// On utilise le "store" vuex définie dans le répertoire "store"


import store from "./store"


// Auto Authentification


import token, { autoAuthorization } from "./authentification/setup"


//------------------------------------------------------------------------------
// Gestion des routes dans le modèle ("store")


sync(store, router)


// Gestion du plugin d'intertionalisation de l'application


defineI18n(store)


// Gestion de l'auto Authentification


autoAuthorization(token)


// Packages de notifications


setupNoty()


// Packages de requêtes HTTP


setupAxios()


// Plugin permettant d'ajouter un event si un composant devient visible


Vue.use(VueObserveVisibility)


// Plugin implémentant un bus événementiel


setupBus()


// autofocus on v-if:input with v-focus attribute


Vue.directive("focus", {
    // When the bound element is inserted into the DOM...
    inserted: function(el) {
        el.focus()
    }
})


// trigger event with 'v-click-outside' directive
// https://stackoverflow.com/questions/36170425/detect-click-outside-element


Vue.directive("click-outside", {
    bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener("click", el.clickOutsideEvent)
    },
    unbind: function(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent)
    },
})


//------------------------------------------------------------------------------
// Vue de l'application,à laquelle on associe le routeur et le modèle


const application = new Vue(
    {
        router,
        store,
        el: "#application",
        components: { Application },
        template: "<Application/>"
    }
)


//------------------------------------------------------------------------------
// Export des éléments de l'application


export { application, router, store }


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
