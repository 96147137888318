//-----------------------------------------------------------------------------
// @author Thibault, tsoret@e-vitech.com
// @date 2022/11/08
// @copyright EVITECH
//-----------------------------------------------------------------------------


export function computeDistances(
    sensorHeight,
    aovVertical,
    targetHeight,
    targetPixels,
    cameraHeight,
    tilt,
) {
    aovVertical = _processAovVertical(aovVertical)
    tilt = _processTilt(tilt)

    if (cameraHeight < targetHeight) {
        throw _computeErrorMessage("camera not high enough, not implemented now")
    }

    if (_isLookingTheSky(aovVertical, tilt)) {
        throw _computeErrorMessage("is looking the sky")
    }

    if (_isLookingYourFeet(tilt)) {
        throw _computeErrorMessage("is looking your feet")
    }

    let distanceMin = _computeDistanceMinToCamera(aovVertical, cameraHeight, targetHeight, tilt)
    distanceMin = Math.ceil(distanceMin)

    let distanceMax
    if (!_hasNoMax(aovVertical, tilt)) {
        distanceMax = _computeDistanceMaxToCamera(aovVertical, targetHeight, cameraHeight, tilt)
        distanceMax = Math.trunc(distanceMax)
    }

    if (distanceMin > distanceMax) {
        throw _computeErrorMessage("can't see the target completely")
    }

    let distanceMaxCameraTarget = _computeDistanceMax(sensorHeight, targetPixels, targetHeight, cameraHeight, aovVertical, tilt)
    distanceMaxCameraTarget = Math.trunc(distanceMaxCameraTarget)

    if (distanceMax && distanceMaxCameraTarget > distanceMax) {
        distanceMaxCameraTarget = distanceMax
    }

    if (distanceMin > distanceMaxCameraTarget) {
        throw _computeErrorMessage("fov negative")
    }

    let distanceMinTargetPixels = _computePixelsSize(sensorHeight, aovVertical, cameraHeight, targetHeight, distanceMin, tilt)
    distanceMinTargetPixels = Math.trunc(distanceMinTargetPixels)

    let distanceMaxTargetPixels = _computePixelsSize(sensorHeight, aovVertical, cameraHeight, targetHeight, distanceMaxCameraTarget, tilt)
    distanceMaxTargetPixels = Math.trunc(distanceMaxTargetPixels)

    return {
        distanceMin: distanceMin,
        distanceMax: distanceMaxCameraTarget,
        distanceMinTargetPixels: distanceMinTargetPixels,
        distanceMaxTargetPixels: distanceMaxTargetPixels,
    }
}


//-----------------------------------------------------------------------------


function _processAovVertical(aovVertical) {
    aovVertical = _convertDegreesToRadians(aovVertical)
    return aovVertical
}


//-------------------------------------


function _processTilt(tilt) {
    tilt = _convertDegreesToRadians(tilt)
    return tilt
}


//-------------------------------------


function _isLookingTheSky(aovVertical, tilt) {
    return tilt <= (-aovVertical / 2)
}


//-------------------------------------


function _isLookingYourFeet(tilt) {
    return tilt >= (Math.PI / 2)
}


//-------------------------------------


function _hasNoMax(aovVertical, tilt) {
    return tilt <= aovVertical / 2
}


//-----------------------------------------------------------------------------


function _computeDistanceMinToCamera(aovVertical, cameraHeight, targetHeight, tilt) {
    let cameraDistanceMin = cameraHeight / Math.tan(tilt + (aovVertical / 2))
    let targetSeenDistanceMin = (cameraHeight - targetHeight) / Math.tan(tilt + (aovVertical / 2))

    return Math.max(cameraDistanceMin, targetSeenDistanceMin)
}


//-------------------------------------


function _computeDistanceMaxToCamera(aovVertical, targetHeight, cameraHeight, tilt) {
    let cameraDistanceMax = (cameraHeight - targetHeight) / Math.tan(tilt - aovVertical / 2)
    let targetSeenDistanceMax = cameraHeight / Math.tan(tilt - aovVertical / 2)

    return Math.min(cameraDistanceMax, targetSeenDistanceMax)
}


//-------------------------------------


function _computeDistanceMax(sensorHeight, targetPixel, targetHeight, cameraHeight, aovVertical, tilt) {
    let distance = _computeDistance(sensorHeight, targetPixel, targetHeight, aovVertical)
    let tanTilt = Math.tan(tilt)
    let cos2Tilt = Math.cos(tilt) ** 2

    let delta = (targetHeight * tanTilt + distance / cos2Tilt) ** 2 - (4 * distance * cameraHeight * tanTilt) / cos2Tilt
    // let distanceCameraTarget
    let distanceMaxCameraTarget

    if (delta >= 0) {
        let minus = distance / cos2Tilt - (2 * cameraHeight - targetHeight) * tanTilt
        let sqrtDelta = Math.sqrt(delta)

        // distanceCameraTarget = (minus - sqrtDelta) / 2
        distanceMaxCameraTarget = (minus + sqrtDelta) / 2
    }

    return distanceMaxCameraTarget
}


//-------------------------------------


function _computeDistance(sensorHeight, targetPixel, targetHeight, aovVertical) {
    return sensorHeight * targetHeight / (2 * targetPixel * Math.tan(aovVertical / 2))
}


//-------------------------------------


function _computePixelsSize(
    sensorHeight,
    aovVertical,
    cameraHeight,
    targetHeight,
    distance,
    tilt
) {
    let to = sensorHeight / (2 * Math.tan(aovVertical / 2))
    let sup = distance * Math.tan(tilt) - (cameraHeight - targetHeight)
    let inf = distance + Math.tan(tilt) * (cameraHeight - targetHeight)
    let top = 1 + (sup / inf) * Math.tan(tilt)
    let bot = distance + cameraHeight * Math.tan(tilt)
    let res = to * (top / bot) * targetHeight
    return res
}


//-------------------------------------


function _convertDegreesToRadians(angle) {
    return angle * Math.PI / 180
}


//-----------------------------------------------------------------------------


function _computeErrorMessage(message) {
    return `Compute distance error : ${message}`
}


//-----------------------------------------------------------------------------
// End of file
//-----------------------------------------------------------------------------
