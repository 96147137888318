<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/08/13
@copyright EVITECH
===============================================================================

Composant pour modifier un projet

=========================================================================== -->

<template>

<div class="position-relative no-overflow">

    <div
        v-if="loading"
        class="loading position-absolute"
    >

        <div class="position-relative h-100 w-100">

            <Loading
                class="position-absolute top-50 start-50 translate-middle"
            />

        </div>

    </div>


    <div v-if="projectLoaded">

        <div class="d-flex">

            <Map
                ref="mapref"
                :project="project"
                :cameras="cameras"
                :isChoosingCameraLocation="isChoosingCameraLocation"
                :selectedCamera="selectedCamera"
                :showCameraLabels="showCameraLabels"
                :projectSettingsDisplayed="showProjectSettings"

                @selectCamera="selectCamera"

                @saveProject="saveProject"
                @showSettings="showSettings"
                @exportFolder="generateExport"
                @exportPicture="generatePlan"

                @setPath="setPath"

                @sortCameras="sortCameras"

                @isChoosingLocation="isChoosingLocation"
            />


            <div
                @click="switchSideBar"
                class="d-flex p-1 evdesign-color"
                style="cursor: pointer;"
            >

                <i
                    class="fa fa-2x align-self-center icon"
                    :class="getSideBarIconClass"
                    aria-hidden="true"
                ></i>

            </div>


            <CamerasList
                v-show="showSideBar"
                class="sidebar"
                :cameras="cameras"
                :selectedCamera="selectedCamera"
                :showCameraLabels="showCameraLabels"
                @toggleCameraLabels="showCameraLabels = !showCameraLabels"

                @selectedCamera="selectCamera"
                @sortCameras="sortCameras"

                @createCamera="displayAddCameraModal"

                @cloneCamera="cloneCamera"
                @deleteCamera="deleteCamera"

                @setName="setName"
                @setHeight="setHeight"
                @setTilt="setTilt"
                @setPosition="setPosition"
                @setAOV="setAOV"
                @setFOVColor="setFOVColor"
            />

        </div>

    </div>


    <button
        ref=triggerAddCamera
        class="d-none"
        data-bs-toggle="modal"
        data-bs-target="#addCameraModal"
    >
    </button>


    <AddCamera
        id="addCameraModal"
        ref="addCameraModal"
        :projectId="project.id"
        :defaultCameraHeight="projectDefaultHeight"
        @chooseLocation="chooseLocation"
        @success="cameraAdded"
        @error="cameraNotAdded"
    />


    <button
        ref=triggerSettings
        class="d-none"
        data-bs-toggle="modal"
        data-bs-target="#projectSettingsModal"
    >
    </button>


    <ProjectSettings
        v-if="projectLoaded"
        id="projectSettingsModal"
        :project="project"
    />


    <div class="display-none">
        <a
            ref="downloader"
            download
            target="_blank"
        ></a>
    </div>

</div>

</template>


<!-- ====================================================================== -->

<script>

import $ from "jquery"
import { mapState } from "vuex"

import {
    findIndex,
    isEqual,
} from "lodash"

import { AUTH_MODULE_NAME } from "@/components/app_authentification/login/actions/auth"

import Loading from "@/components/app_utils/loading/loading"
import CamerasList from "@/components/project/edit_project/cameras_list/cameras_list"
import Map from "@/components/project/edit_project/map/map_no_mode"
import AddCamera from "@/components/project/edit_project/add_camera/add_camera"
import ProjectSettings from "@/components/project/edit_project/project_settings/project_settings"


//-------------------------------------


export default {
    data: function() {
        return {
            project: {},
            projectLoaded: false,
            loading: true,
            cameras: [],
            camerasToDelete: [],
            showSideBar: true,
            showProjectSettings: false,
            isChoosingCameraLocation: false,
            selectedCamera: 0,
            showCameraLabels: false,
        }
    },

    computed: {
        getSideBarIconClass: function() {
            if (this.showSideBar) {
                return "fa-caret-right"
            } else {
                return "fa-caret-left"
            }
        },

        ...mapState(AUTH_MODULE_NAME, [
            "user",
        ]),

        projectDefaultHeight: function() {
            if (this.project.settings) {
                return this.project.settings.default_cam_height
            } else {
                return 5
            }
        },
    },

    mounted: function() {
        this.id = this.$route.params.id
        this.getProject()
    },

    methods: {
        displayAddCameraModal: function() {
            this.$refs.triggerAddCamera.click()
        },

        getProject: function() {
            this.loading = true

            this.axios({
                method: "post",
                url: "evdesign/projects/get_project",
                data: {
                    id: this.id,
                },
                type: "json"
            }).then(response => {
                let status = response.data.data
                if (status === 0) {
                    // this.loading = false
                } else {
                    this.project = response.data.data.project
                    this.cameras = response.data.data.cameras
                    this.cameras.forEach(camera => {
                        if (camera.settings.aov_h === null) {
                            camera.settings.aov_h = camera.aov_h_min
                        }
                        if (camera.settings.aov_v === null) {
                            camera.settings.aov_v = camera.aov_v_min
                        }
                    })
                    this.projectLoaded = true
                }
            })
                .catch(() => {})
                .finally(() => {
                    this.loading = false
                })
        },

        generateExport: function() {
            let mapData = this.$refs.mapref.getMapFullRecap()
            let formData = {
                lang: this.$i18n.locale(),
                projectID: this.project.id,
                ...mapData,
            }

            this.loading = true

            this.axios({
                method: "post",
                url: "evdesign/projects/create_project_report",
                data: formData,
            })
                .then(response => {
                    let source
                    if (this.axios.defaults.devMode) {
                        source = this.axios.defaults.mediaURL + response.data.path
                    } else {
                        source = response.data.path
                    }
                    this.$refs.downloader.href = source
                    this.$refs.downloader.download = source.split("/").reverse()[0]
                    this.$refs.downloader.click()
                })
                .catch((error) => {
                    console.log("ERROR", error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        generatePlan: function() {
            let mapData = this.$refs.mapref.getMapFullRecap()
            let formData = {
                lang: this.$i18n.locale(),
                projectID: this.project.id,
                ...mapData,
            }

            this.loading = true

            this.axios({
                method: "post",
                url: "evdesign/projects/create_project_plan",
                data: formData,
            })
                .then(response => {
                    let source
                    if (this.axios.defaults.devMode) {
                        source = this.axios.defaults.mediaURL + response.data.path
                    } else {
                        source = response.data.path
                    }
                    this.$refs.downloader.href = source
                    this.$refs.downloader.download = source.split("/").reverse()[0]
                    this.$refs.downloader.click()
                })
                .catch((error) => {
                    console.log("ERROR", error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        showSettings: function() {
            this.$refs.triggerSettings.click()
        },

        saveProject: function() {
            this.saveProjectSettings()
            // this.saveCameras()
        },

        saveCameras: function() {
            let request = {
                method: "post",
                url: "evdesign/projects/cameras/save_cameras",
                data: {
                    project_id: this.project.id,
                    cameras: this.cameras,
                    camerasToDelete: this.camerasToDelete,
                },
                type: "json",
            }

            this.axios(request)
                .then((response) => {
                    this.camerasToDelete = []

                    let data = response.data
                    let updatedCameras = data.updated_cameras

                    updatedCameras.forEach(updatedCam => {
                        let index = findIndex(this.cameras, cam => {
                            let IDCheck = cam.id === updatedCam.id
                            if (IDCheck) {
                                return IDCheck
                            } else {
                                let factoryCheck = cam.factory_id === updatedCam.factory_id
                                let nameCheck = cam.settings.name === updatedCam.settings.name
                                let posCheck = isEqual(cam.settings.location.coordinate, updatedCam.settings.location.coordinate)
                                let orientationCheck = cam.settings.orientation === updatedCam.settings.orientation
                                let tiltCheck = cam.settings.tilt === updatedCam.settings.tilt
                                let posListCheck = cam.settings.position_in_list === updatedCam.settings.position_in_list

                                return factoryCheck && nameCheck && posCheck && orientationCheck && tiltCheck && posListCheck
                            }
                        })
                        this.cameras[index].id = updatedCam.id
                    })

                    this.project.last_saved_at = new Date()
                    $(".project-name").html("<b>" + this.project.name + "</b>" + " - " + this.$t("Last saved : ") + this.project.last_saved_at.toLocaleString())
                    this.$noty.success(this.$t("Your project has been updated"))
                })
                .catch((error) => {
                    console.log("Error : ", error)
                    this.$noty.error(this.$t("Error while updating your project"))
                })
        },

        // MR: Il faut rajouter la gestion d'erreur / affichage de message réussite/erreur avec $noty
        // Je l'ai pas fait car il faut le gérer en fonction de la fonction ci dessus également
        saveProjectSettings: function() {
            let mapData = this.$refs.mapref.getSaveData()
            let request = {
                method: "post",
                url: "evdesign/projects/save_project",
                data: {
                    project: this.project,
                    map: mapData,
                },
                type: "json",
            }

            this.loading = true

            this.axios(request)
                .then(response => {
                    // Récupérer la date depuis le serveur...
                    this.project.last_saved_at = new Date()
                    let saveDate = this.project.last_saved_at.toLocaleString()
                    $(".project-name").html("<b>" + this.project.name + "</b>" + " - " + this.$t("Last saved : ") + saveDate)

                    this.saveCameras()
                })
                .catch(() => {})
                .finally(() => {
                    this.loading = false
                })
        },

        chooseLocation: function() {
            // this.showSideBar = false
            this.isChoosingCameraLocation = true
        },

        isChoosingLocation(event) {
            this.$refs.addCameraModal.addCamera([
                event.latLng.lat(),
                event.latLng.lng()
            ], this.cameras.length)
            this.isChoosingCameraLocation = false
        },

        cameraAdded: function(camera) {
            this.cameras.push(camera)
            // this.$refs.mapref.computeCameraAov2(camera.settings.position_in_list, camera)
            // this.$refs.mapref.resetMap()
            this.$refs.addCameraModal.reset()
            this.displaySideBar()
            this.selectCamera(this.cameras.length - 1)
            // this.$noty.success(this.$t("Your camera has been added"))
        },

        cameraNotAdded: function() {
            this.displaySideBar()
            this.$noty.error(this.$t("An error has occured"))
        },

        switchSideBar: function() {
            this.showSideBar = !this.showSideBar
        },

        displaySideBar: function() {
            this.showSideBar = true
        },

        selectCamera(index) {
            this.selectedCamera = index
        },

        sortCameras: function() {
            this.cameras.forEach((camera) => {
                let positionInList = this.cameras.findIndex(x => x.settings.name === camera.settings.name)
                camera.settings.position_in_list = positionInList
            })
        },

        deleteCamera(cameraIndex) {
            if (this.cameras[cameraIndex].id) {
                this.camerasToDelete.push(this.cameras[cameraIndex])
            }
            this.cameras.splice(cameraIndex, 1)
            setTimeout(() => {
                this.selectCamera(this.cameras.length - 1)
            }, 5)
        },

        cloneCamera(cameraIndex) {
            this.displayAddCameraModal()
            let cameraToClone = this.cameras[cameraIndex]
            this.$refs.addCameraModal.duplicate(cameraToClone)
        },

        setName: function(value) {
            this.cameras[this.selectedCamera].settings.name = value
        },

        setHeight: function(value) {
            this.cameras[this.selectedCamera].settings.height = value
        },

        setTilt: function(value) {
            this.cameras[this.selectedCamera].settings.tilt = value
        },

        setPosition: function(value) {
            this.cameras[this.selectedCamera].settings.is_in_portrait_position = value
        },

        setAOV: function(value) {
            this.cameras[this.selectedCamera].settings.aov_v = value.aov_v
            this.cameras[this.selectedCamera].settings.aov_h = value.aov_h
        },

        setFOVColor: function(value) {
            this.cameras[this.selectedCamera].settings.fov_color = value
        },

        setPath: function(value) {
            this.cameras[this.selectedCamera].settings.path = value
        },
    },

    components: {
        Loading,
        AddCamera,
        Map,
        CamerasList,
        ProjectSettings,
    },
}
</script>

<!-- ====================================================================== -->

<style scoped>

.loading {
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 1;
    background-color: rgba(150, 150, 150, 0.3);
}

.sidebar {
    min-width: 25vw;
    width: 45%;
    height: calc(100vh - 52px);
}

.no-overflow {
    overflow: hidden;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
