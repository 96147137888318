<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/09/03
@copyright EVITECH
=========================================================================== -->

<template>

<div>
    <ValidationObserver v-slot="{ invalid }">

        <transition name="fade">

            <Alert
                v-if="displayUserMessage"
                :message="userMessage"
                :alertType="userMessageType"
            />

        </transition>


        <ValidationProvider
            rules="required|email"
            v-slot="{ errors }"
        >

            <label>
                {{ $t("Email address") }}
            </label>


            <input
                type="text"
                :class="getInputClass(errors)"
                v-model="email"
                :placeholder="$t('Email address')"
            />


            <div class="validation-error">
                {{ $t(errors[0]) }}
            </div>

        </ValidationProvider>


        <button
            class="btn evdesign-btn w-100 mt-3 mb-0 mx-0"
            :disabled="invalid"
            @click="sendResetPasswordEmail"
        >
            {{ $t("Reset password") }}
        </button>

    </ValidationObserver>

</div>

</template>


<!-- ====================================================================== -->


<script>

import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate"

import Alert from "@/components/app_utils/alert/alert"

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------


export default {
    data: function() {
        return {
            email: undefined,

            displayUserMessage: false,
            userMessageType: "",
            userMessage: "",
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    methods: {
        getInputClass: function(errorsList) {
            if (errorsList.length !== 0) {
                return "border-danger"
            }
            return "border"
        },

        // ----------------------------

        sendResetPasswordEmail: function() {
            this.axios({
                method: "post",
                url: "/evdesign/users_management/reset_password_request",
                data: {
                    email: this.email,
                },
                type: "json",
            })
                .then(() => {
                    this.success()
                })
                .catch(() => {
                    this.error()
                })
        },

        // ----------------------------

        error: function() {
            this.userMessageType = "danger"
            this.userMessage = this.$t("There has been an error sending the mail, please try again")
            this.displayUserMessage = true
        },

        success: function() {
            this.userMessageType = "success"
            this.userMessage = this.$t("An email has been sent to the associated email address if it is found to be associated to an account")
            this.displayUserMessage = true
        },

        // ----------------------------

        resetEmail: function() {
            this.email = undefined
        },

        resetUserMessage: function() {
            this.displayUserMessage = false
            this.userMessageType = ""
            this.userMessage = ""
        },

        resetComponent: function() {
            this.resetEmail()
            this.resetUserMessage()
        },
    },

    components: {
        ValidationProvider,
        ValidationObserver,
        Alert,
    },
}
</script>


<!-- ====================================================================== -->


<style scoped>
</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
