<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/08/25
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <div class="text-center mt-5">

        <sweetalert-icon :icon="messageType">
        </sweetalert-icon>


        <h4>
            {{ $t(message) }}
        </h4>

    </div>

</template>


<!-- ====================================================================== -->


<script>


import SweetalertIcon from "vue-sweetalert-icons"


//-------------------------------------------------------------------------------


const MESSAGE_TYPES = {
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error"
}


//-------------------------------------------------------------------------------

export default {
    props: {
        message: {
            type: String,
            default: ""
        },
        messageType: {
            type: String,
            default: "error"
        }
    },

    computed: {
        getIconClass: function() {
            // A voir si on veut rajouter des icônes différentes pour INFO, SUCCESS et WARNING

            if (this.messageType === MESSAGE_TYPES.ERROR) {
                return "fa fa-sync"
            } else {
                return "fas fa-arrow-left"
            }
        }
    },

    methods: {
        rerender: function() {
            this.$emit("rerender")
        }
    },

    components: {
        SweetalertIcon,
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>

.fa-sync:hover::before {
    transform: rotate(360deg);
}

.fa-sync:before {
    display: inline-block;
    transition: 1s all;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
