<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/07
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <div>

        <div
            class="page-header header-filter"
            style="background-image: url('./static/img/bg.jpg');"
        >

            <div class="container">

                <div class="d-flex justify-content-center text-center">

                    <div class="">

                        <h2 class="title">
                           {{ $t("Design and implementation of cameras for the deployment of video analysis towards the protection of sensitive sites.") }}
                        </h2>


                        <a
                            href="#features1"
                            class="btn evdesign-btn"
                        >
                            {{ $t("Discover the advantages") }}
                        </a>

                    </div>


                    <div
                        v-if="false"
                        class="col-md-5 col-md-offset-0"
                    >

                        <div class="iframe-container">

                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/3TGLnrKoKbY"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            >
                            </iframe>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<!-- ====================================================================== -->

<script>

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------


export default {
    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>

@import "../css/style.css";

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

</style>
