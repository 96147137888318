//-----------------------------------------------------------------------------
// @author Thibault, tsoret@e-vitech.com
// @date 2020/06/30
// @copyright EVITECH
//-----------------------------------------------------------------------------


import { extend } from "vee-validate"
import {
    required,
    email,
} from "vee-validate/dist/rules"


//-----------------------------------------------------------------------------


const USERNAME_MIN_SIZE = 3
const PASSWORD_MIN_SIZE = 5


//-----------------------------------------------------------------------------


extend("required", {
    ...required,
    message: "This field is required",
})


extend("email", {
    ...email,
    message: "Invalid address",
})


extend("minUserLength", value => {
    if (value.length >= USERNAME_MIN_SIZE) {
        return true
    }
    let errorMessage = "The username should contain at least 3 characters"
    return errorMessage
})


extend("minPasswordLength", value => {
    if (value.length >= PASSWORD_MIN_SIZE) {
        return true
    }
    let errorMessage = "The password must contain at least 5 characters"
    return errorMessage
})


//-----------------------------------------------------------------------------
// End of file
//-----------------------------------------------------------------------------
