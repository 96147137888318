<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2020/10/15
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <div
        class="modal fade"
        v-observe-visibility="mountVisibility"
    >

        <div class="modal-dialog modal-lg mt-1">

            <div class="modal-content">

                <div class="modal-header">

                    <div class="modal-title fw-bold">
                        {{ title }}
                    </div>


                    <button
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        type="button"
                    >
                    </button>


                </div>


                <div
                    v-if="user"
                    class="modal-body pt-0"
                >

                    <div>

                        <h3 class="w-100 text-center">
                            {{ $t("Details") }}
                        </h3>


                        <div class="row">

                            <div class="col-4">
                                {{ $t("First name : ") }} {{ user.first_name }}
                            </div>


                            <div class="col-4">
                                {{ $t("Last name : ") }} {{ user.last_name }}
                            </div>


                            <div class="col-4">
                                {{ $t("Phone number : ") }} {{ user.phone }}
                            </div>


                            <div class="col-4">
                                {{ $t("Email : ") }} {{ user.email }}
                            </div>


                            <div class="col-4">
                                {{ $t("Company : ") }} {{ user.company }}
                            </div>


                            <div class="col-4">
                                {{ $t("Job role : ") }} {{ user.job_role }}
                            </div>

                        </div>


                        <div class="row align-items-center py-3">

                            <div class="col-9">

                                <div>
                                    {{ $t("Created :") }} {{ created }}
                                </div>


                                <div>
                                    {{ $t("Last login :") }} {{ lastLogin }}
                                </div>


                                <div v-if="gridRowData.project_data">

                                    {{
                                        $t("Last project changed : \"{projectName}\" at {projectDate}", {
                                            projectName: gridRowData.project_data,
                                            projectDate: lastChange,
                                        })
                                    }}

                                </div>

                            </div>


                            <div class="col-3">

                                <div class="w-100 text-center">

                                    <toggle-button
                                        class="my-auto"
                                        :value="!user.disabled"
                                        :sync="true"
                                        :labels="{checked: $t('Active'), unchecked: $t('Disabled')}"
                                        :width="120"
                                        :height="25"
                                        :font-size="15"
                                        @change="switchDisable"
                                    />

                                </div>

                            </div>

                        </div>

                    </div>


                    <div class="border-top border-themed">

                        <h3 class="w-100 text-center pt-2">
                            {{ $t("Projects") }}
                        </h3>


                        <div
                            v-if="hasProjects"
                            class="overflow-auto"
                            style="max-height: 500px;"
                        >

                            <ProjectRow
                                v-for="project in projects"
                                :key="project.id"
                                :project="project"
                                :users="users"
                                @reassigned="getUserDetails()"
                                @deleted="getUserDetails()"
                                @goToProject="goToProject"
                            />

                        </div>


                        <div
                            v-else
                            class="text-center"
                        >

                            {{ $t("No project") }}

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>


<!-- ====================================================================== -->


<script>


import { ToggleButton } from "vue-js-toggle-button"

import { convertDate, convertTime } from "@/javascript/helpers/datetime"

import ProjectRow from "./project_row"


//-----------------------------------------------------------------------------


let _ = require("lodash")


//-----------------------------------------------------------------------------


export default {
    props: {
        gridRowData: {
            type: Object,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        }
    },

    data: function() {
        return {
            user: {},
            projects: [],
        }
    },

    computed: {
        locale: function() {
            return this.$store.state.i18n.locale
        },

        title: function() {
            return this.$t("{userData} details", {
                userData: this.gridRowData.user,
            })
        },

        created: function() {
            if (this.user) {
                return this.formatDate(this.user.created)
            } else {
                return ""
            }
        },

        lastLogin: function() {
            if (this.user) {
                return this.formatDate(this.user.last_login)
            } else {
                return ""
            }
        },

        lastChange: function() {
            return this.formatDate(this.gridRowData.last_change)
        },

        hasProjects: function() {
            return !_.isEmpty(this.projects)
        }
    },

    methods: {
        mountVisibility: function(isVisible) {
            if (isVisible) {
                this.getUserDetails()
            } else {
                this.$emit("close-modal")
            }
        },

        getUserDetails: function() {
            let requestParams = {
                "username": this.gridRowData.username,
            }

            this.axios({
                method: "get",
                url: "evdesign/users_management/get_user_details",
                params: requestParams,
                type: "json",
            })
                .then((response) => {
                    let data = response.data
                    this.user = data.user
                    this.projects = data.projects
                })
                .catch((error) => {
                    console.log("getUserDetails error", error)
                })
        },

        switchDisable: function(event) {
            let requestParams = {
                "username": this.gridRowData.username,
                "disable": !event.value,
            }

            this.axios({
                method: "get",
                url: "evdesign/users_management/disable_user",
                params: requestParams,
                type: "json",
            })
                .then((response) => {
                    let data = response.data
                    this.user.disabled = data.disabled
                })
                .catch((error) => {
                    console.log("getUserDetails error", error)
                })
        },

        goToProject: function(projectID) {
            // $(".modal").modal("hide")
            this.$router.push("/project/" + projectID)
        },

        formatDate: function(datetime) {
            datetime = new Date(datetime)
            let date = convertDate(datetime, this.locale)
            let time = convertTime(datetime, this.locale)
            return date + " - " + time
        },
    },

    components: {
        ToggleButton,
        ProjectRow,
    },
}

</script>


<!-- ====================================================================== -->


<style scoped>

.modal-disappear {
    visibility: hidden !important;
}

</style>


<!-- ====================================================================== -->
<!-- End of File -->
<!-- ====================================================================== -->
