const Options = {
    mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "satellite"],
        position: 0,
        style: 2
    },
    streetViewControl: false,
    scaleControl: true,
    rotateControl: false,
    mapTypeId: "satellite",
    tilt: 0,
    styles: [
        {
            featureType: "administrative.land_parcel",
            elementType: "labels",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi.business",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi.park",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "road.local",
            elementType: "labels",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        }
    ]
}

export default Options
