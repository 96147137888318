//-----------------------------------------------------------------------------
// @author Thibault, tsoret@e-vitech.com
// @date 2020/06/30
// @copyright EVITECH
//-----------------------------------------------------------------------------


import Vue from "vue"
import Vuex from "vuex"

import evdesignauth from "@/components/app_authentification/login/modules/auth"


//-----------------------------------------------------------------------------


Vue.use(Vuex)


//-----------------------------------------------------------------------------


const state = {
    applicationDashboardURL: "/projects",
    applicationCGUURL: "/CGU",
    applicationHomeURL: "/",
}


//-----------------------------------------------------------------------------


const mutations = {
}


//-----------------------------------------------------------------------------


const store = new Vuex.Store({
    modules: {
        [evdesignauth.moduleName]: evdesignauth
    },
    state,
    mutations,
})


//-----------------------------------------------------------------------------


export default store


//-----------------------------------------------------------------------------
// End of file
//-----------------------------------------------------------------------------
