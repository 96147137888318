<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

<div>

    <div class="container-fluid pt-3">

        <div class="card bg-secondary shadow container-fluid">

            <div class="card-header border-0">

                <h4>
                    {{ $t('My profile') }}
                </h4>
            </div>


            <Loading
                v-if="loading"
            />


            <Message
                v-if="showMessage"
                :message="message"
                :messageType="messageType"
                @rerender="forceRerender"
            />


            <div
                v-if="!loading && !showMessage"
                class="card-body pt-0"
            >

                <ValidationObserver v-slot="{ invalid }">

                    <h6 class="heading-small text-muted mb-2">
                        {{ $t('Personal details') }}
                    </h6>


                    <div class="ps-md-4">

                        <div class="row">

                            <div class="col-md-6">

                                <div class="form-group focused">

                                    <label
                                        class="form-control-label"
                                        for="input-username"
                                    >
                                        {{ $t('Username') }}
                                    </label>


                                    <input
                                        type="text"
                                        class="form-control form-control-alternative"
                                        v-model="username"
                                        disabled
                                    >

                                </div>

                            </div>


                            <div class="col-md-6">

                                <ValidationProvider rules="required|email" v-slot="{ errors }">

                                    <div class="form-group">

                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                        >
                                            {{ $t('Email address') }}
                                        </label>


                                        <input
                                            type="email"
                                            :class="getInputClass(errors)"
                                            v-model="email"
                                            disabled
                                        >

                                        <div class="validation-error">
                                            {{ $t(errors[0]) }}
                                        </div>

                                    </div>

                                </ValidationProvider>

                            </div>

                        </div>


                        <div class="row">

                            <div class="col-md-6">

                                <div class="form-group focused">

                                    <label
                                        class="form-control-label"
                                        for="input-first-name"
                                    >
                                        {{ $t('First name') }}
                                    </label>


                                    <input
                                        type="text"
                                        class="form-control form-control-alternative"
                                        v-model="firstName"
                                        disabled
                                    >

                                </div>

                            </div>


                            <div class="col-md-6">

                                <div class="form-group focused">

                                    <label
                                        class="form-control-label"
                                        for="input-last-name"
                                    >
                                        {{ $t('Last name') }}
                                    </label>


                                    <input
                                        type="text"
                                        class="form-control form-control-alternative"
                                        v-model="lastName"
                                        disabled
                                    >

                                </div>

                            </div>

                        </div>

                    </div>


                    <div class="ps-md-4">

                        <div class="row">

                            <div class="col-md-6">

                                <ValidationProvider rules="required" v-slot="{ errors }">

                                    <div class="form-group focused">

                                        <label
                                            class="form-control-label"
                                            for="input-city"
                                        >
                                            {{ $t('Company') }}
                                        </label>


                                        <input
                                            type="text"
                                            :class="getInputClass(errors)"
                                            v-model="company"
                                        >


                                        <div class="validation-error">
                                            {{ $t(errors[0]) }}
                                        </div>

                                    </div>

                                </ValidationProvider>


                                <div class="form-group focused">

                                    <label
                                        class="form-control-label"
                                        for="input-city"
                                    >
                                        {{ $t('Phone number') }}
                                    </label>


                                    <input
                                        type="text"
                                        class="form-control form-control-alternative"
                                        v-model="phone"
                                        disabled
                                    >

                                </div>

                            </div>


                            <div class="col-md-6">

                                <ValidationProvider rules="required" v-slot="{ errors }">

                                    <div class="form-group focused">

                                        <label
                                            class="form-control-label"
                                            for="input-city"
                                        >
                                            {{ $t('Job role') }}
                                        </label>


                                        <input
                                            type="text"
                                            :class="getInputClass(errors)"
                                            v-model="jobRole"
                                        >


                                        <div class="validation-error">
                                            {{ $t(errors[0]) }}
                                        </div>

                                    </div>

                                </ValidationProvider>

                            </div>

                        </div>

                    </div>


                    <hr class="my-4">


                    <div>

                        <h6 class="heading-small text-muted mb-2">
                            {{ $t('Change password') }}
                        </h6>


                        <div class="ps-md-4">

                            <div class="row">

                                <div class="col-md-6">

                                    <div class="form-group focused">

                                        <label
                                            class="form-control-label"
                                            for="input-city"
                                        >
                                            {{ $t('New password') }}
                                        </label>


                                        <password
                                            :badge="false"
                                            class="ms-0 me-0"
                                            type="password"
                                            :placeholder="$t('New password')"
                                            v-model="newPassword"
                                        />

                                    </div>

                                </div>


                                <div class="col-md-6">

                                    <div class="form-group focused">

                                        <label
                                            class="form-control-label"
                                            for="input-city"
                                        >
                                            {{ $t('Confirm new password') }}
                                        </label>


                                        <password
                                            :badge="false"
                                            class="ms-0 me-0"
                                            type="password"
                                            :placeholder="$t('Confirm new password')"
                                            v-model="newPasswordConfirm"
                                        />

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>


                    <hr class="mb-4 mt-1">


                    <div>

                        <div>

                            <div>
                                {{ $t("To save modifications, please enter your current password then click to 'Save'") }}
                            </div>


                            <div>

                                <ValidationProvider rules="required" v-slot="{ errors }">

                                    <input
                                        type="password"
                                        :class="getInputClass(errors)"
                                        :placeholder="$t('Current password')"
                                        v-model="oldPassword"
                                        required
                                    >


                                    <div class="validation-error">
                                        {{ $t(errors[0]) }}
                                    </div>

                                </ValidationProvider>

                            </div>

                        </div>


                        <div class="mt-3 d-flex justify-content-center">

                            <button
                                type="button"
                                :disabled="!isValid"
                                class="btn btn-success"
                                @click="changeDetails"
                            >
                                {{ $t('Save') }}
                            </button>

                        </div>

                    </div>


                </ValidationObserver>

            </div>

        </div>

    </div>

</div>

</template>

<!-- ====================================================================== -->

<script>

import Password from "vue-password-strength-meter"

import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate"

import NavBar from "@/components/navbar/navbar"
import AppMenu from "@/components/navbar/menus/app_menu"
import Loading from "@/components/app_utils/loading/loading"
import Message from "@/components/app_utils/message_handler/message_handler"

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation.json"


//-------------------------------------------------------------------------------


export default {
    data() {
        return {
            menu: AppMenu,
            transparent: "",

            username: "",
            currentUsername: "",
            firstName: "",
            currentFirstName: "",
            lastName: "",
            currentLastName: "",
            company: "",
            currentCompany: "",
            jobRole: "",
            currentJobRole: "",
            email: "",
            currentEmail: "",
            phone: "",
            currentPhone: "",

            newPassword: "",
            newPasswordConfirm: "",
            oldPassword: "",
            loading: true,
            showMessage: false,
            message: "There has been a problem while retrieving your profile, please try again by refreshing the page",
            messageType: "error"
        }
    },

    beforeCreate() {
        this.$i18n.add(FR, translationFR)
    },

    mounted() {
        this.getCurrentUser()
    },

    computed: {
        isValid: function() {
            let hasDetailsChanged = this.hasDetailsChanged()
            let isDetailsValid = hasDetailsChanged & this.checkDetailsValidity()

            let hasPasswordChanged = this.hasPasswordChanged()
            let isPasswordValid = this.checkPasswordValidity()

            let hasCurrentPassword = this.oldPassword !== ""

            let res = hasCurrentPassword


            if (hasDetailsChanged) {
                res &= isDetailsValid
                if (hasPasswordChanged) {
                    res &= isPasswordValid
                }
            } else {
                if (hasPasswordChanged) {
                    res &= isPasswordValid
                } else {
                    res = false
                }
            }

            return Boolean(res)
        }
    },

    methods: {
        forceRerender: function() {
            this.reset()
            this.getCurrentUser()
        },

        reset: function() {
            Object.assign(this.$data, this.$options.data.call(this))
        },

        getCurrentUser: function() {
            let username = this.axios.defaults.headers.common["Authorization"]
            this.axios({
                method: "post",
                url: "evdesign/users_management/get_user",
                data: {
                    username: username
                },
                type: "json"
            }).then((response) => {
                let status = response.data.data
                if (status === 0) {
                    this.showMessage = true
                } else {
                    this.username = username
                    this.currentFirstName = status.first_name
                    this.currentLastName = status.last_name
                    this.currentCompany = status.company
                    this.currentJobRole = status.job_role
                    this.currentEmail = status.email
                    this.currentPhone = status.phone
                    this.loadDetails()
                }
            })
                .catch(() => {
                    this.showMessage = true
                })
                .finally(() => {
                    this.loading = false
                })
        },

        loadDetails: function() {
            this.firstName = this.currentFirstName
            this.lastName = this.currentLastName
            this.company = this.currentCompany
            this.jobRole = this.currentJobRole
            this.email = this.currentEmail
            this.phone = this.currentPhone
        },

        hasDetailsChanged: function() {
            let hasChange = false

            hasChange |= this.firstName !== this.currentFirstName
            hasChange |= this.lastName !== this.currentLastName
            hasChange |= this.company !== this.currentCompany
            hasChange |= this.jobRole !== this.currentJobRole
            hasChange |= this.email !== this.currentEmail
            hasChange |= this.phone !== this.currentPhone

            return hasChange
        },

        checkDetailsValidity: function() {
            let isValid = true

            isValid &= this.username !== ""
            isValid &= this.firstName !== ""
            isValid &= this.lastName !== ""
            isValid &= this.company !== ""
            isValid &= this.jobRole !== ""
            isValid &= this.email !== ""
            isValid &= this.phone !== ""

            return isValid
        },

        hasPasswordChanged: function() {
            let hasChanges = false
            hasChanges |= this.newPassword !== ""
            hasChanges |= this.newPasswordConfirm !== ""
            return hasChanges
        },

        checkPasswordValidity: function() {
            let isValid = true

            if (this.newPassword !== "") {
                isValid &= this.newPasswordConfirm !== ""
            } else if (this.newPasswordConfirm !== "") {
                isValid &= this.newPassword !== ""
            }

            return isValid
        },

        changeDetails: function() {
            this.axios({
                method: "post",
                url: "evdesign/users_management/details_change",
                data: {
                    username: this.username,
                    // email: this.email,
                    company: this.company,
                    job_role: this.jobRole,
                    old_password: this.oldPassword,
                    new_password: this.newPassword
                },
                type: "json"
            }).then((response) => {
                let status = response.data.data
                this.showMessage = true
                if (status === 1) {
                    this.messageType = "success"
                    this.message = "Your profile has been updated successfully"
                } else {
                    this.message = "There has been a problem updating your profile, please try again"
                }
            })
                .catch(() => {
                })
        },

        getInputClass: function(errorsList) {
            if (errorsList.length !== 0) {
                return "form-control form-control-alternative border-danger"
            }
            return "form-control form-control-alternative"
        }

    },
    components: {
        NavBar,
        AppMenu,
        Password,
        ValidationProvider,
        ValidationObserver,
        Loading,
        Message,
    }
}
</script>


<!-- ====================================================================== -->


<style scoped>

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: .375rem;
    background-color: #fff;
    background-clip: border-box;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card-body {
    padding: 1.5rem;
    flex: 1 1 auto;
}

.card-header {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    background-color: #f7fafc;
}

.card-header:first-child {
    border-radius: calc(.375rem - 1px) calc(.375rem - 1px) 0 0;
}

.bg-secondary {
    background-color: #f7fafc !important;
}

.btn[disabled]:hover,
.btn:disabled:hover {
    cursor: not-allowed !important;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
