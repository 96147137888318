<!-- ===========================================================================
@author Thibault Soret, tsoret@e-vitech.com
@date 2018/05/11 by Thibault Soret
@copyright EVITECH
================================================================================

============================================================================ -->


<template>

    <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#changeLanguageModal"
        name="changeLanguageButton"
    >

        <i class="fas fa-globe"></i>

        {{ $t("Change language") }}

    </button>

</template>


<!-- ======================================================================= -->


<script>

export default {

}

</script>


<!-- ======================================================================= -->


<style>

</style>


<!-- ======================================================================= -->
<!-- End of file -->
<!-- ======================================================================= -->
