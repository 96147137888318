<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <nav class="navbar py-0 p-1" :class="navClass">

        <div class="container-fluid">

            <div class="navbar-header">

                <router-link
                    :to="getRouterLink()"
                    class="navbar-brand"
                >

                    <img
                        :src="logoSource"
                        style="height: 52px;"
                    />

                    <!-- EVDesign -->

                </router-link>

            </div>


            <component
                :is="menu"
                class="ms-auto"
            >
            </component>



            <DjefChangeLanguageButton
                class="btn evdesign-btn evdesign-blue-bg text-uppercase"
            />

        </div>

    </nav>

</template>

<!-- ====================================================================== -->

<script>

import $ from "jquery"

import DjefChangeLanguageButton from "@/i18n/components/change_language"


//-------------------------------------

export default {
    props: {
        menu: {
            type: [String, Object],
            default: "div"
        },
        navClass: {
            type: String,
            default: "",
        },
        logoSource: {
            type: String,
            default: "static/img/evdesign_white.png",
        },
    },

    mounted: function() {
        $(function() {
            $(document).scroll(function() {
                var navbar = $(".navbar")
                navbar.toggleClass("scrolled", $(this).scrollTop() > navbar.height())
            })
        })
    },

    methods: {
        getRouterLink() {
            if (this.$store.getters["authentification_evdesign/isAuthenticated"]) {
                return this.$store.state.applicationDashboardURL
            }
            return this.$store.state.applicationHomeURL
        },
    },

    components: {
        DjefChangeLanguageButton,
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>

.navbar.scrolled {
    background-color: rgb(172, 170, 170) !important;
    transition: background-color 200ms linear;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
