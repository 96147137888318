//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/05/11 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


export const FR = "fr-FR"
export const EN = "en-US"


export const LANGUAGES = [
    { id: EN, label: "English" },
    { id: FR, label: "Français" },
]


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
