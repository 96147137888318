//-----------------------------------------------------------------------------
// @author tsoret, tsoret@e-vitech.com
// @date 2019/05/24
// @copyright EVITECH
//-----------------------------------------------------------------------------


import Vue from "vue"
import VueBus from "vue-bus"


//-----------------------------------------------------------------------------


export function setupBus() {
    Vue.use(VueBus)
}


//-----------------------------------------------------------------------------
// End of file
//-----------------------------------------------------------------------------
