<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2020/10/21
@copyright EVITECH
===============================================================================



=========================================================================== -->


<template>

    <div
        class="w3-row mx-0 py-2 border align-items-center text-center"
        :class="{'border-themed': disabled}"
    >

        <div class="w3-col" style="width: 200px; margin-top: 22px;">

            <div class="position-relative">

                <input
                    type="text"
                    :placeholder="$t('Manufacturer')"
                    class="form-control border"
                    v-model="cameraClone.manufacturer"
                    :disabled="!editMode && !newCamera"
                />

                <div
                    v-if="!editMode && !newCamera"
                    class="position-absolute double-click-div"
                    @dblclick="activeEditMode()"
                ></div>

            </div>

        </div>


        <div class="w3-col" style="width: 200px; margin-top: 22px;">

            <div class="position-relative">

                <input
                    type="text"
                    :placeholder="$t('Model')"
                    class="form-control border"
                    v-model="cameraClone.model"
                    :disabled="!editMode && !newCamera"
                />

                <div
                    v-if="!editMode && !newCamera"
                    class="position-absolute double-click-div"
                    @dblclick="activeEditMode()"
                ></div>

            </div>

        </div>


        <div class="w3-col" style="width: 200px; margin-top: 22px;">

            <div class="position-relative">

                 <select
                    class="form-select"
                    style="font-size: 16px; height: 46px; margin-bottom: 5px; padding: 13px 12px;"
                    v-model="cameraClone.sensor_type"
                    :disabled="!editMode && !newCamera"
                >

                    <option :value="''" disabled>
                        {{ $t("Choose") }}
                    </option>


                    <option value="1">
                        {{ $t("Visible") }}
                    </option>


                    <option value="2">
                        {{ $t("Thermal") }}
                    </option>


                    <option value="3">
                        {{ $t("Thermographic") }}
                    </option>

                </select>


                <div
                    v-if="!editMode && !newCamera"
                    class="position-absolute double-click-div"
                    @dblclick="activeEditMode()"
                ></div>

            </div>

        </div>


        <div class="w3-col" style="width: 120px; margin-top: 33px;">

            <div class="position-relative">

                <toggle-button
                    class="my-auto"
                    v-model="cameraClone.is_mobile"
                    :width="50"
                    :height="20"
                    :disabled="!editMode && !newCamera"
                />


                <div
                    v-if="!editMode && !newCamera"
                    class="position-absolute double-click-div"
                    @dblclick="activeEditMode()"
                ></div>

            </div>

        </div>


        <div class="w3-col" style="width: 250px">

            <div class="input-group position-relative">

                <span
                    :id="widthID"
                    class="input-group-text sensor-detail"
                >
                    {{ $t("Width") }}
                </span>


                <input
                    type="text"
                    :placeholder="'-'"
                    class="col form-control border"
                    :aria-describedby="widthID"
                    v-model="cameraClone.sensor_width"
                    :disabled="!editMode && !newCamera"
                />


                <div
                    v-if="!editMode && !newCamera"
                    class="position-absolute double-click-div"
                    @dblclick="activeEditMode()"
                ></div>

            </div>


            <div class="input-group position-relative">

                <span
                    :id="heightID"
                    class="input-group-text sensor-detail"
                >
                    {{ $t("Height") }}
                </span>


                <input
                    type="text"
                    :placeholder="'-'"
                    class="col form-control border"
                    :aria-describedby="heightID"
                    v-model="cameraClone.sensor_height"
                    :disabled="!editMode && !newCamera"
                />


                <div
                    v-if="!editMode && !newCamera"
                    class="position-absolute double-click-div"
                    @dblclick="activeEditMode()"
                ></div>

            </div>

        </div>


        <div class="w3-col w3-right" style="width: 250px; padding-left: 40px; margin-top: 20px;">

            <div
                v-if="!editMode && !newCamera && !deleteMode"
                class="btn-group"
                role="group"
            >

                <button
                    class="btn evdesign-btn action-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :disabled="disabled"
                    :title="$t('Delete camera')"
                    @click="activeDeleteMode()"
                >
                    <i class="fas fa-trash fa-2x"></i>
                </button>


                <button
                    class="btn evdesign-btn action-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :disabled="disabled"
                    :title="$t('Edit')"
                    @click="activeEditMode()"
                >
                    <i class="fas fa-edit fa-2x"></i>
                </button>

            </div>


            <div
                v-if="editMode && !newCamera && !deleteMode"
                class="btn-group"
                role="group"
            >

                <button
                    class="btn evdesign-btn action-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('Cancel')"
                    @click="cancelEditMode()"
                >
                    <i class="fas fa-times fa-2x"></i>
                </button>


                <button
                    class="btn evdesign-btn action-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :disabled="!hasChange"
                    :title="$t('Save changes')"
                    @click="saveChange()"
                >
                    <i class="fas fa-save fa-2x"></i>
                </button>

            </div>


            <div
                v-if="newCamera"
                class="btn-group"
                role="group"
            >

                <button
                    class="btn evdesign-btn action-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :disabled="!hasChange"
                    :title="$t('Cancel')"
                    @click="cancelEditMode()"
                >
                    <i class="fas fa-times fa-2x"></i>
                </button>


                <button
                    class="btn evdesign-btn action-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :disabled="!hasChange"
                    :title="$t('Register camera')"
                    @click="registerCamera()"
                >
                    <i class="fas fa-plus fa-2x"></i>
                </button>

            </div>


            <div
                v-if="deleteMode && !newCamera && !editMode"
                class="btn-group"
                role="group"
            >

                <button
                    class="btn evdesign-btn action-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('Cancel')"
                    @click="cancelDeleteMode()"
                >
                    <i class="fas fa-times fa-2x"></i>
                </button>


                <button
                    class="btn evdesign-btn action-btn bg-danger"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('Confirm delete camera')"
                    @click="deleteCamera()"
                >
                    <i class="bg-error fas fa-check fa-2x"></i>
                </button>

            </div>

        </div>


        <div class="w3-col w3-right" style="width: 120px; padding-left: 40px; margin-top: 35px;">

            {{ camera.used_counter }}

        </div>


        <div class="w3-rest">

            <div class="form-inline d-flex">

                <div class="input-group aov-info position-relative">

                    <span
                        :id="AOVHMinID"
                        class="input-group-text aov-detail"
                    >
                        {{ $t("horiz min") }}
                    </span>


                    <input
                        type="text"
                        :placeholder="'-'"
                        class="form-control border"
                        :aria-describedby="AOVHMinID"
                        v-model="cameraClone.aov_h_min"
                        :disabled="!editMode && !newCamera"
                    />


                    <div
                        v-if="!editMode && !newCamera"
                        class="position-absolute double-click-div"
                        @dblclick="activeEditMode()"
                    ></div>

                </div>


                <div class="input-group aov-info position-relative">

                    <span
                        :id="AOVHMaxID"
                        class="input-group-text aov-detail"
                    >
                        {{ $t("horiz max") }}
                    </span>


                    <input
                        type="text"
                        :placeholder="'-'"
                        class="form-control border"
                        :aria-describedby="AOVHMaxID"
                        v-model="cameraClone.aov_h_max"
                        :disabled="!editMode && !newCamera"
                    />


                    <div
                        v-if="!editMode && !newCamera"
                        class="position-absolute double-click-div"
                        @dblclick="activeEditMode()"
                    ></div>

                </div>

            </div>


            <div class="form-inline d-flex">

                <div class="input-group aov-info position-relative">

                    <span
                        :id="AOVVMinID"
                        class="input-group-text aov-detail"
                    >
                        {{ $t("vert min") }}
                    </span>


                    <input
                        type="text"
                        :placeholder="'-'"
                        class="form-control border"
                        :aria-describedby="AOVVMinID"
                        v-model="cameraClone.aov_v_min"
                        :disabled="!editMode && !newCamera"
                    />


                    <div
                        v-if="!editMode && !newCamera"
                        class="position-absolute double-click-div"
                        @dblclick="activeEditMode()"
                    ></div>

                </div>


                <div class="input-group aov-info position-relative">

                    <span
                        :id="AOVVMaxID"
                        class="input-group-text aov-detail"
                    >
                        {{ $t("vert max") }}
                    </span>


                    <input
                        type="text"
                        :placeholder="'-'"
                        class="form-control border"
                        :aria-describedby="AOVVMaxID"
                        v-model="cameraClone.aov_v_max"
                        :disabled="!editMode && !newCamera"
                    />


                    <div
                        v-if="!editMode && !newCamera"
                        class="position-absolute double-click-div"
                        @dblclick="activeEditMode()"
                    ></div>

                </div>

            </div>

        </div>

    </div>

</template>


<!-- ====================================================================== -->


<script>


import { ToggleButton } from "vue-js-toggle-button"


//-----------------------------------------------------------------------------


let _ = require("lodash")


//-----------------------------------------------------------------------------


export default {
    props: {
        camera: {
            type: Object,
            default: () => {
                let defaultCamera = {
                    aov_h_min: "",
                    aov_h_max: "",
                    aov_v_min: "",
                    aov_v_max: "",
                    factory_camera_id: null,
                    focal_length: "",
                    img_path: null,
                    manufacturer: "",
                    model: "",
                    sensor_height: "",
                    sensor_width: "",
                    sensor_type: "",
                    is_mobile: false,
                    used_counter: "-",
                }
                return defaultCamera
            }
        },
    },

    data: function() {
        return {
            editMode: false,
            deleteMode: false,
            cameraClone: undefined,
        }
    },

    computed: {
        disabled: function() {
            return false
        },

        hasChange: function() {
            return !_.isEqual(this.cameraClone, this.camera)
        },

        newCamera: function() {
            return this.cameraClone.factory_camera_id === null
        },

        heightID: function() {
            return this.camera.model + "-height"
        },

        widthID: function() {
            return this.camera.model + "-width"
        },

        AOVVMinID: function() {
            return this.camera.model + "-AOV-v-min"
        },

        AOVVMaxID: function() {
            return this.camera.model + "-AOV-v-max"
        },

        AOVHMinID: function() {
            return this.camera.model + "-AOV-h-min"
        },

        AOVHMaxID: function() {
            return this.camera.model + "-AOV-H-max"
        },
    },

    beforeMount: function() {
        this.resetCameraClone()
    },

    watch: {
        camera: function() {
            this.resetCameraClone()
        },
    },

    methods: {
        activeDeleteMode: function() {
            this.deleteMode = true
        },

        cancelDeleteMode: function() {
            this.deleteMode = false
        },

        activeEditMode: function() {
            this.editMode = true
        },

        unactiveEditMode: function() {
            this.editMode = false
        },

        cancelEditMode: function() {
            this.resetCameraClone()
            this.unactiveEditMode()
        },

        resetCameraClone: function() {
            this.cameraClone = _.cloneDeep(this.camera)
        },

        formatClone: function() {
            this.cameraClone.aov_h_min = parseFloat(this.cameraClone.aov_h_min)
            this.cameraClone.aov_h_max = parseFloat(this.cameraClone.aov_h_max)
            this.cameraClone.aov_v_min = parseFloat(this.cameraClone.aov_v_min)
            this.cameraClone.aov_v_max = parseFloat(this.cameraClone.aov_v_max)
            this.cameraClone.sensor_height = parseInt(this.cameraClone.sensor_height)
            this.cameraClone.sensor_width = parseInt(this.cameraClone.sensor_width)
        },

        saveChange: function() {
            this.formatClone()

            let requestParams = {
                "updated_camera": this.cameraClone,
            }

            this.axios({
                method: "post",
                url: "evdesign/cameras_management/update_camera",
                data: requestParams,
                type: "json",
            })
                .then((response) => {
                    let data = response.data
                    this.$emit("updated", data.camera)
                    this.unactiveEditMode()
                })
                .catch((error) => {
                    console.log("Update camera ", this.camera.factory_camera_id, "error", error)
                })
        },

        registerCamera: function() {
            let requestParams = {
                "new_camera": this.cameraClone,
            }

            this.axios({
                method: "post",
                url: "evdesign/cameras_management/create_camera",
                data: requestParams,
                type: "json",
            })
                .then((response) => {
                    let data = response.data
                    this.$emit("created", data.camera)
                    this.resetCameraClone()
                    this.unactiveEditMode()
                })
                .catch((error) => {
                    console.log("create camera ", this.camera.factory_camera_id, "error", error)
                })
        },

        deleteCamera: function() {
            let requestParams = {
                "deleted_camera": this.cameraClone,
            }

            this.axios({
                method: "post",
                url: "evdesign/cameras_management/delete_camera",
                data: requestParams,
                type: "json",
            })
                .then((response) => {
                    let data = response.data
                    this.$emit("deleted", data.camera_id)
                    this.unactiveEditMode()
                })
                .catch((error) => {
                    console.log("Deleted camera ", this.camera.factory_camera_id, "error", error)
                })
        },
    },

    components: {
        ToggleButton,
    },
}

</script>


<!-- ====================================================================== -->


<style scoped>

.sensor-detail {
    width: 80px !important;
}

.w3-col {
    padding-left: 15px;
    padding-right: 15px;
}

.input-group,
.input-group-text {
    height: 46px;
}

.aov-info {
    width: 50% !important;
}

.aov-detail {
    width: 90px !important;
}

.action-btn {
    width: 50px !important;
}

.double-click-div {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
