<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2020/06/30
@copyright EVITECH
===============================================================================

=========================================================================== -->


<template>

    <div id="application">

        <div class="header sticky-top">

            <NavBar
                :menu="getMenu"
                :navClass="getNavBarClass"
                :logoSource="logoSource"
            />

        </div>


        <router-view class="view" />


        <DjefChangeLanguageModal
            borderTheme="#197ae2"
            selectTheme="evdesign-blue-bg"
            @change="setGoogleMap"
        />


        <AuthModal />

    </div>

</template>


<!-- ====================================================================== -->


<script>

import $ from "jquery"
import Vue from "vue"
import Cookies from "js-cookie"

import GmapVue from "gmap-vue"
import VueSweetalert2 from "vue-sweetalert2"
import "vue-slider-component/theme/antd.css"
import "sweetalert2/dist/sweetalert2.min.css"

import "@/components/app_utils/validators/validators"

import NavBar from "@/components/navbar/navbar"
import AppMenu from "@/components/navbar/menus/app_menu"
import HomePageMenu from "@/components/navbar/menus/homepage_menu"
import AuthModal from "@/components/app_home/auth_modal/auth_modal"

import "../static/styles/switchButton.css"
import "../static/styles/evdesign.css"
import "../static/styles/animations.css"

import DjefChangeLanguageModal from "@/i18n/components/languages_modal"
import { FR, LANGUAGES } from "@/i18n/i18n_languages"


//-----------------------------------------------------------------------------


Vue.use(VueSweetalert2)


//-----------------------------------------------------------------------------


export default {
    created: function() {
        this.setTitle()
        this.setLanguage()
        this.setAxios()
        this.setFavicon()
    },

    computed: {
        getMenu: function() {
            if (this.$store.getters["authentification_evdesign/isAuthenticated"]) {
                return AppMenu
            } else {
                return HomePageMenu
            }
        },

        getNavBarClass: function() {
            let navbarClass = "evdesign-color sticky-top"

            if (this.$route.name === "Homepage") {
                navbarClass = "navbar-transparent navbar-absolute"
            }
            return navbarClass
        },

        logoSource: function() {
            if (this.$route.name === "Homepage") {
                return "static/img/evdesign_white.png"
            }
            return "static/img/evdesign_black.png"
        },
    },

    methods: {
        setGoogleMap: function() {
            let i18n = this.$i18n.locale().split("-")
            let lang = i18n[0]
            let region = i18n[1]

            Vue.use(GmapVue, {
                load: {
                    key: "AIzaSyBJ5Wizi8n-E5KOJqtMMWPCwNMuKsYlvfA", // evtdev
                    libraries: [
                        "places",
                        "marker",
                        "geometry",
                        "drawing",
                    ],
                    region: region,
                    language: lang,
                    v: "quarterly",
                },
                installComponents: true
            })
        },

        setAxios: function() {
            let protocol = window.location.protocol
            let hostname = window.location.hostname
            let url = protocol + "//" + hostname
            this.axios.defaults.serverURL = url
            this.axios.defaults.front_hostname = hostname
            let devMode = false
            let serverREST = Cookies.get("serverAPI")
            if (serverREST !== undefined) {
                let port = window.location.port
                if (port !== "") {
                    url += ":"
                    url += port
                }
            } else {
                // Serveur de dev
                devMode = true
                url += ":9090"
            }

            this.axios.defaults.devMode = devMode
            this.axios.defaults.mediaURL = url
            // this.axios.defaults.serverURL = url + "/evdesign/"
            this.axios.defaults.serverURL = url

            if (serverREST !== undefined) {
                url += serverREST
                Cookies.remove("serverAPI")
            }

            this.axios.defaults.baseURL = url

            this.axios.timeout = 1200000

            this.axios.defaults.xsrfCookieName = "csrftoken"
            this.axios.defaults.xsrfHeaderName = "X-CSRFToken"
            this.axios.defaults.withCredentials = true
        },

        setLanguage: function() {
            let userLanguage = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)
            if (LANGUAGES.some(language => language.id.toLowerCase() === userLanguage.toLowerCase())) {
                this.$i18n.set(userLanguage)
            } else {
                this.$i18n.set(FR)
            }
            this.setGoogleMap()
        },

        setTitle: function() {
            document.title = "EVDesign"
        },

        setFavicon: function() {
            $("link[rel*=icon]")[0].href = "../jaguarweb/static/img/evdesign_favicon.svg"
        },
    },

    components: {
        VueSweetalert2,
        DjefChangeLanguageModal,
        NavBar,
        AppMenu,
        HomePageMenu,
        AuthModal,
    }
}

</script>


<!-- ====================================================================== -->


<style>

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
