//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/05/11 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


// MODULE NAME


export const AUTH_MODULE_NAME = "authentification_evdesign"


//------------------------------------------------------------------------------


// ACTIONS


export const AUTH_REQUEST = "AUTH_REQUEST"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_ERROR = "AUTH_ERROR"
export const AUTH_LOGOUT = "AUTH_LOGOUT"

//------------------------------------------------------------------------------


// GETTERS

export const AUTH_STATUS_GETTER = "AUTH_UPDATE_USERNAME"


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
