<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

Composant qui représente un projet

=========================================================================== -->

<template>

<tr>

    <th scope="row" class="handle project-row">
        <i class="fas fa-arrows-alt fa-1x"></i>
    </th>


    <td class="project-row">
        {{ project.name }}
    </td>


    <td class="project-row">
        {{ project.coordinate.address }}
    </td>


    <td class="project-row">

        <div
            v-if="project.settings.type === 1"
            class="caption text-wrap"
        >
            {{ $t("Google maps plan") }}
        </div>


        <div
            v-if="project.settings.type === 2"
            class="caption text-wrap"
        >
            {{ $t("Photo plan") }}
        </div>

    </td>


    <!-- TODO REFAIRE LA TRAD -->
    <td class="project-row">
        {{ created_at_date }} {{ $t(" at ") }} {{ created_at_time }}
    </td>


    <td class="project-row">
        {{ saved_at_date }} {{ $t(" at ") }} {{ saved_at_time }}
    </td>


    <td class="p-0" style="display: flex; flex-wrap: wrap;">

        <div
            v-if="!displayDeleteConfirmation"
            class="btn-group"
            role="group"
        >

            <button
                class="btn evdesign-btn"
                data-toggle="tooltip"
                data-placement="top"
                :title="$t('Edit project')"
                @click="goToProject"
            >
                <i class="fas fa-edit fa-2x"></i>
            </button>


            <button
                class="btn evdesign-btn"
                data-toggle="tooltip"
                data-placement="top"
                :title="$t('Clone the project')"
                @click="cloneProject"
            >
                <i class="fas fa-clone fa-2x"></i>
            </button>


            <button
                class="btn evdesign-btn"
                data-toggle="tooltip"
                data-placement="top"
                :disabled="project.report_path === null"
                :title="$t('Download last PDF report')"
                @click="downloadPDF()"
            >
                <i class="fas fa-file-pdf fa-2x"></i>
            </button>


            <button
                class="btn evdesign-btn"
                data-toggle="tooltip"
                data-placement="top"
                :title="$t('Delete project')"
                @click="waitDeleteConfirmation()"
            >
                <i class="fas fa-trash fa-2x"></i>
            </button>

        </div>


        <div
            v-else
            class="btn-group"
            role="group"
        >

            <button
                class="btn evdesign-btn btn-double bg-success"
                data-toggle="tooltip"
                data-placement="top"
                :title="$t('Confirm delete project')"
                @click="remove"
            >

                <i class="fas fa-check fa-2x"></i>

            </button>


            <button
                class="btn evdesign-btn btn-double bg-danger"
                data-toggle="tooltip"
                data-placement="top"
                :title="$t('Cancel delete project')"
                @click="cancelDeleteConfirmation()"
            >

                <i class="fas fa-times fa-2x"></i>

            </button>

        </div>


        <div class="display-none">
            <a
                ref="downloader"
                :href="project.report_path"
                :download="reportFilename"
            ></a>
        </div>

    </td>

</tr>

</template>


<!-- ====================================================================== -->


<script>

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"

//-----------------------------------------------------------------------------

export default {
    props: {
        project: {
            type: Object,
        },
    },

    computed: {
        reportFilename: function() {
            return this.project.name + ".pdf"
        },
    },

    data: function() {
        return {
            created_at_date: "",
            created_at_time: "",
            saved_at_date: "",
            saved_at_time: "",
            displayDeleteConfirmation: false,
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    mounted() {
        this.formatDate()
    },

    methods: {
        goToProject: function() {
            this.$router.push("/project/" + this.project.id)
        },

        cloneProject: function() {
            this.$emit("clone", this.project.id)
        },

        remove: function() {
            this.$emit("delete", this.project.id)
        },

        formatDate: function() {
            this.created_at_date = new Date(this.project.created_at).toLocaleDateString()
            this.created_at_time = new Date(this.project.created_at).toLocaleTimeString()

            this.saved_at_date = new Date(this.project.last_saved_at).toLocaleDateString()
            this.saved_at_time = new Date(this.project.last_saved_at).toLocaleTimeString()
        },

        downloadPDF: function() {
            this.$refs.downloader.click()
        },

        waitDeleteConfirmation: function() {
            this.displayDeleteConfirmation = true
        },

        cancelDeleteConfirmation: function() {
            this.displayDeleteConfirmation = false
        },
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>

.handle {
    cursor: move;
}

.project-row {
    padding-top: 16px;
    padding-bottom: 15px;
}

.evdesign-btn {
    width: 45px;
}

.btn-double {
    width: 94px !important;
    /* 69.5px pour 3 boutons */
    /* 94px pour 4 boutons */
}

.btn[disabled]:hover,
.btn:disabled:hover {
    cursor: not-allowed !important;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->

