<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/08/25
@copyright EVITECH
===============================================================================

=========================================================================== -->
<template>

    <div>

        <div ref="flyawayMWL">

            <slot name="labelContent"></slot>

        </div>

        <slot></slot>

      </div>

</template>


<!-- ====================================================================== -->


<script>

export default (
    function(x) {
        return x.default || x
    }
)(require("./js/markerWithLabel"))


</script>

<!-- ====================================================================== -->

<style scoped>

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
