//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/05/11 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


import axios from "axios"


import {
    AUTH_MODULE_NAME,
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
} from "../actions/auth"


//------------------------------------------------------------------------------


const state = {
    status: "",
    user: {
        username: "",
        is_staff: false
    }
}


//-------------------------------------


const getters = {
    isAuthenticated: state => state.status === "success",
    authStatus: state => state.status,
    isAdmin: state => state.user.is_staff
}


//-------------------------------------


const actions = {
    [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            axios({
                method: "post",
                url: "evdesign/authentication/login",
                data: { data: user },
                headers: { username: user.username },
                type: "json",
            })
                .then((response) => {
                    if (response.data.user !== undefined) {
                        state.user = response.data.user
                        sessionStorage.setItem("user-username", state.user.username)
                        axios.defaults.headers.common.Authorization = state.user.username
                        commit(AUTH_SUCCESS, response)
                        resolve(response)
                    } else {
                        resolve(response)
                    }
                })
                .catch((error) => {
                    commit(AUTH_ERROR, error)
                    sessionStorage.setItem("user-username", user.username)
                    reject(error)
                })
        })
    },

    [AUTH_LOGOUT]: ({ commit, dispatch }, username) => {
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: "evdesign/authentication/logout",
                data: { data: { username: username } },
                type: "json",
            })
                .then((response) => {
                    state.user.username = ""
                    commit(AUTH_LOGOUT)
                    sessionStorage.removeItem("user-username")
                    resolve()
                })
                .catch((error) => {
                    console.log(error)
                    state.user.username = ""
                    sessionStorage.removeItem("user-username")
                    commit(AUTH_ERROR, error)
                    reject(error)
                })
        })
    }
}


//-------------------------------------


const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = "loading"
    },

    [AUTH_SUCCESS]: (state) => {
        state.status = "success"
    },

    [AUTH_ERROR]: (state) => {
        state.status = "error"
    },

    [AUTH_LOGOUT]: (state) => {
        state.status = ""
    },
}


//------------------------------------------------------------------------------


export default { moduleName: AUTH_MODULE_NAME, namespaced: true, state, getters, actions, mutations }


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
