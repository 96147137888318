<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

Component pour Login/Register/Forgot Password

=========================================================================== -->

<template>

    <div
        id="authModal"
        class="modal fade authModal"
        tabindex="-1"
        aria-hidden="true"
    >

        <div class="modal-dialog modal-lg mt-1">

            <div class="modal-content">

                <div class="modal-header">

                    <div class="modal-title fw-bold">
                        {{ title }}
                    </div>


                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                    </button>

                </div>


                <div class="modal-body py-2 px-3">

                    <Loading v-if="loading" />


                    <Login
                        ref="login"
                        v-show="displayLogin"
                        :recaptchaKey="recaptchaKey"
                        @request="startLoading"
                        @success="success"
                        @error="error"
                        @login="loginSuccess()"
                    />


                    <Register
                        ref="register"
                        v-show="displayRegister"
                        :recaptchaKey="recaptchaKey"
                        @request="startLoading"
                        @error="error"
                        @success="success"
                        @goToCGU="goToCGU"
                    />


                    <ForgotPasswordModal
                        v-if="displayForgetPassword"
                    />

                </div>


                <div
                    v-if="!loading"
                    class="modal-footer justify-content-center"
                >

                    <div
                        v-if="displayLogin || displayForgetPassword"
                        class="w-100 text-center"
                    >

                        <span>
                            {{ $t("Don't have an account yet?") }}
                        </span>


                        <a
                            href="#"
                            @click="showRegisterModal"
                        >
                            {{ $t("Create an account") }}
                        </a>

                    </div>


                    <div
                        v-if="displayRegister || displayForgetPassword"
                        class="w-100 text-center"
                    >

                        <span>
                            {{ $t("Already have an account?") }}
                        </span>


                        <a
                            href="#"
                            @click="showLoginModal"
                        >
                            {{ $t("Login") }}
                        </a>

                    </div>


                    <div
                        v-if="!displayForgetPassword"
                        class="text-center"
                    >

                        <a
                            href="#"
                            @click="showForgotPasswordModal"
                        >
                            {{ $t("Forgot your password?") }}
                        </a>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<!-- ====================================================================== -->

<script>

import $ from "jquery"
import SweetalertIcon from "vue-sweetalert-icons"

import { FR } from "@/i18n/i18n_languages"

import Loading from "@/components/app_utils/loading/loading"
import Login from "@/components/app_authentification/login/login"
import Register from "@/components/app_authentification/register/register"
import ForgotPasswordModal from "@/components/app_authentification/forgot_password/forgot_password"

import translationFR from "./i18n/FR/translation"


//-------------------------------------


const SHAKER_TIME = 1001


//-------------------------------------

export default {
    data: function() {
        return {
            recaptchaKey: "6LeySSAaAAAAAH8_UxjamyQMDWR0c-dtAgTVwYTa",
            loading: false,
            display: "login",
        }
    },

    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },

    computed: {
        displayLogin: function() {
            let res = true
            res &&= !this.loading
            res &&= this.display === "login"
            return res
        },

        displayRegister: function() {
            let res = true
            res &&= !this.loading
            res &&= this.display === "register"
            return res
        },

        displayForgetPassword: function() {
            let res = true
            res &&= !this.loading
            res &&= this.display === "forgotPassword"
            return res
        },

        title: function() {
            let label
            if (this.displayLogin) {
                label = this.$t("Login")
            } else if (this.displayRegister) {
                label = this.$t("Register")
            } else if (this.displayForgetPassword) {
                label = this.$t("Reset password")
            } else {
                label = this.$t("Loading")
            }
            return label
        },
    },

    created: function() {
        let self = this
        this.$bus.$on("clickedItem", (clickedMenuItem) => {
            self.displayComponent(clickedMenuItem)
        })
    },

    methods: {
        displayComponent: function(label) {
            this.display = label
        },

        goToCGU: function() {
            this.$router.push(this.$store.state.applicationCGUURL)
        },

        // ----------------------------

        showRegisterModal: function() {
            this.$refs.login.resetComponent()
            this.displayComponent("register")
        },

        showLoginModal: function() {
            this.$refs.register.resetComponent()
            this.displayComponent("login")
        },

        showForgotPasswordModal: function() {
            this.$refs.login.resetComponent()
            this.displayComponent("forgotPassword")
        },

        // ----------------------------

        shakeModal: function() {
            let div = $(".authModal").find(".modal-dialog")
            let classes = "animated shake"
            div.addClass(classes)
            setTimeout(
                function() {
                    div.removeClass(classes)
                },
                SHAKER_TIME,
            )
        },

        // ----------------------------

        startLoading: function() {
            this.loading = true
        },

        stopLoading: function() {
            this.loading = false
        },

        error: function() {
            this.stopLoading()
            this.shakeModal()
        },

        success: function() {
            this.stopLoading()
        },

        loginSuccess: function() {
            this.success()
            this.$router.push(this.$store.state.applicationDashboardURL)
        },
    },

    components: {
        SweetalertIcon,
        Login,
        Register,
        Loading,
        ForgotPasswordModal,
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>

a {
    color: #007bffc9;
}

</style>
