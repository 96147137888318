//------------------------------------------------------------------------------
// @author Thibault Soret, tsoret@e-vitech.com
// @date 2018/05/11 by Thibault Soret
// @copyright EVITECH
//------------------------------------------------------------------------------


import Vue from "vue"
import vuexI18n from "vuex-i18n"

import { FR } from "./i18n_languages"
import translationFR from "./FR/translation"


//------------------------------------------------------------------------------


export function defineI18n(store) {
    Vue.use(vuexI18n.plugin, store)
    Vue.i18n.add(FR, translationFR)
}


//------------------------------------------------------------------------------
// End of file
//------------------------------------------------------------------------------
