<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <div>

        <Presentation  />

        <Features />

        <Credits />

    </div>

</template>

<!-- ====================================================================== -->

<script>

import Presentation from "@/components/app_home/presentation/presentation"
import Features from "@/components/app_home/features/features"
import Credits from "@/components/app_home/credits/credits"

//----------------------------------------------------------------------------

export default {
    components: {
        Presentation,
        Features,
        Credits,
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>
</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
