<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

=========================================================================== -->

<template>

    <drop-down
        tag="li"
        title="Menu"
        icon="fas fa-bars"
        class="nav-item"
        @change="reset"
        ref="dropdown"
    >

        <router-link
            :to="{ name: 'projects'}"
            class="dropdown-item"
        >

            <i class="fa fa-tasks"></i>

            {{ $t("My projects")}}

        </router-link>


        <router-link
            :to="{ name: 'profile'}"
            class="dropdown-item"
        >

          <i class="fa fa-user"></i>

          {{ $t("My profile")}}

        </router-link>


        <router-link
            v-if="isAdmin()"
            :to="{ name: 'admin'}"
            class="dropdown-item"
        >
            <i class="fa fa-user"></i>

            {{ $t("Admin Dashboard")}}

        </router-link>


        <!-- Le composant est fourni à dropdown et placé dans un slot -->


        <Logout />

    </drop-down>

</template>

<!-- ====================================================================== -->

<script>

import DropDown from "@/components/dropdown/dropdown"
import Logout from "@/components/app_authentification/logout/logout"


//-----------------------------------------------------------------------------

export default {
    methods: {
        reset: function(isOpen) {
            if (isOpen) {
                this.$refs.dropdown.closeDropDown()
            }
        },

        isAdmin: function() {
            return this.$store.getters["authentification_evdesign/isAdmin"]
        },
    },

    components: {
        DropDown,
        Logout,
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>

.nav-link {
    color: black !important;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
