<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/09/03
@copyright EVITECH
=========================================================================== -->


<template>

<div>

    <ValidationObserver v-slot="{ invalid }">

        <transition name="fade">

            <Alert
                v-if="displayUserMessage"
                :message="userMessage"
                :alertType="userMessageType"
            />

        </transition>


        <transition name="fade">

            <Alert
                v-if="userNotConfirmed"
                alertType="primary"
            >
                <a
                    href="#"
                    @click="sendConfirmationEmail"
                >
                    {{ $t("Click here to send the confirmation email") }}
                </a>

            </Alert>

        </transition>


        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
        >

            <label>
                {{ $t("Username / email") }}
            </label>


            <input
                class="w-100 rounded border"
                type="text"
                :class="getInputClass(errors)"
                :placeholder="$t('Username / email')"
                v-model="username"
            />


            <div class="validation-error">
                {{ $t(errors[0]) }}
            </div>

        </ValidationProvider>


        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
        >

            <label>
                {{ $t("Password") }}
            </label>


            <input
                class="w-100 rounded border"
                type="password"
                :class="getInputClass(errors)"
                :placeholder="$t('Password')"
                v-model="password"
            />


            <div class="validation-error">
                {{ $t(errors[0]) }}
            </div>

        </ValidationProvider>


        <div
            v-if="!axios.defaults.devMode"
            class="recaptcha mt-3"
        >

            <vue-recaptcha
                ref="recaptcha"
                :sitekey="recaptchaKey"
                :loadRecaptchaScript="true"
                @verify="captchaVerified"
                @expired="captchaExpired"
            ></vue-recaptcha>

        </div>


        <button
            name="connectButton"
            class="btn evdesign-btn w-100 mt-3 mb-0 mx-0"
            :disabled="invalid || !captchaValidated"
            @click="login"
        >
            {{ $t("Login") }}
        </button>


        <button
            ref="closeModal"
            class="d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
        ></button>

    </ValidationObserver>

</div>

</template>


<!-- ====================================================================== -->


<script>


import VueRecaptcha from "vue-recaptcha"

import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate"

import { FR } from "@/i18n/i18n_languages"

import { djefRegisterModule } from "@/javascript/helpers/components_helper"

import Alert from "@/components/app_utils/alert/alert"

import {
    AUTH_MODULE_NAME,
    AUTH_REQUEST,
} from "./actions/auth"
import auth from "./modules/auth"
import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------


export default {
    props: {
        recaptchaKey: {
            type: String
        }
    },

    data: function() {
        return {
            username: undefined,
            password: undefined,
            captchaValidated: false,

            displayUserMessage: false,
            userMessageType: "",
            userMessage: "",

            userNotConfirmed: false,
        }
    },

    beforeCreate: function() {
        let state = ""

        let getters = ["authStatus"]

        let actions = {
            AUTH_REQUEST
        }

        let mutations = ""

        djefRegisterModule(
            this,
            AUTH_MODULE_NAME,
            auth,
            state,
            getters,
            actions,
            mutations
        )

        this.$i18n.add(FR, translationFR)
    },

    beforeMount: function() {
        this.resetCaptcha()
    },

    methods: {
        getInputClass: function(errorsList) {
            if (errorsList.length !== 0) {
                return "border-danger"
            }
        },

        // ----------------------------

        login: function() {
            this.$emit("request")

            this.resetMessages()

            this.AUTH_REQUEST({
                username: this.username,
                password: this.password,
            })
                .then(() => {
                    this.successLogin()
                })
                .catch((error) => {
                    let userMessage

                    if (error.response) {
                        let errorMessage = error.response.data.data

                        if (errorMessage.includes("UDNE")) {
                            userMessage = this.$t("Incorrect username or password")
                        } else if (errorMessage.includes("UNC")) {
                            this.userNotConfirmed = true
                            userMessage = this.$t("To activate your account, please visit the link sent by email")
                        } else {
                            userMessage = this.$t("Unknown error, please contact us")
                        }
                    } else {
                        userMessage = this.$t("Could not contact server, please try again")
                    }

                    this.error(userMessage)
                })
                .finally(() => {
                    this.resetCaptcha()
                })
        },

        sendConfirmationEmail: function() {
            this.$emit("request")

            this.resetMessages()

            this.axios({
                method: "post",
                url: "/evdesign/users_management/send_verification_email",
                data: {
                    username: this.username
                },
                type: "json"
            })
                .then(() => {
                    let userMessage = this.$t("The link has been sent!")
                    this.success(userMessage)
                })
                .catch((error) => {
                    let userMessage

                    if (error.response) {
                        userMessage = this.$t("Could not send mail")
                    } else {
                        userMessage = this.$t("Could not contact server, please try again")
                    }

                    this.error(userMessage)
                })
        },

        // ----------------------------

        captchaVerified: function() {
            this.captchaValidated = true
        },

        captchaExpired: function() {
            this.captchaValidated = false
        },

        // ----------------------------

        resetInputs: function() {
            this.username = undefined
            this.password = undefined
        },

        resetMessages: function() {
            this.resetUserMessage()
            this.resetEmailMessage()
        },

        resetUserMessage: function() {
            this.displayUserMessage = false
            this.userMessageType = ""
            this.userMessage = ""
        },

        resetEmailMessage: function() {
            this.userNotConfirmed = false
        },

        resetCaptcha: function() {
            if (this.axios.defaults.devMode) {
                this.captchaValidated = true
            } else {
                this.captchaValidated = false
                this.$refs.recaptcha.reset()
            }
        },

        resetComponent: function() {
            this.resetInputs()
            this.resetMessages()
            this.resetCaptcha()
        },

        // ----------------------------

        closeModal: function() {
            this.$refs.closeModal.click()
        },

        // ----------------------------

        success: function(userMessage) {
            if (userMessage) {
                this.userMessageType = "success"
                this.userMessage = userMessage
                this.displayUserMessage = true
            }
        },

        error: function(userMessage) {
            if (userMessage) {
                this.userMessageType = "danger"
                this.userMessage = userMessage
                this.displayUserMessage = true
            }
            this.emitError()
        },

        successLogin: function() {
            this.success()
            this.resetComponent()
            this.closeModal()
            this.emitSuccessLogin()
        },

        // ----------------------------

        emitSuccess: function() {
            this.$emit("success")
        },

        emitSuccessLogin: function() {
            this.$emit("login")
        },

        emitError: function() {
            this.$emit("error")
        },
    },

    components: {
        ValidationProvider,
        ValidationObserver,
        VueRecaptcha,
        Alert,
    },
}
</script>


<!-- ====================================================================== -->


<style scoped>
</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
