<!-- ==========================================================================
@author Thibault, tsoret@e-vitech.com
@date 2020/08/25
@copyright EVITECH
===============================================================================

=========================================================================== -->


<template>

    <div class="container-fluid text-center bg-secondary text-light">

        <a
            href="http://www.evitech.com/fr/"
            target="_blank"
        >
            {{ $t("Made by Evitech") }}
        </a>

    </div>

</template>


<!-- ====================================================================== -->


<script>

import { FR } from "@/i18n/i18n_languages"

import translationFR from "./i18n/FR/translation"


//-------------------------------------


export default {
    beforeCreate: function() {
        this.$i18n.add(FR, translationFR)
    },
}

</script>


<!-- ====================================================================== -->


<style scoped>
</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
