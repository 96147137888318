<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/07/02
@copyright EVITECH
===============================================================================

Composant pour ajouter un projet

=========================================================================== -->

<template>

<div class="modal fade addProjectModal">

    <div class="modal-dialog modal-lg">

        <div class="modal-content">

            <div class="modal-header">

                <div class="modal-title fw-bold">
                    {{ $t("Create a new project") }}
                </div>


                <button
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="button"
                >
                </button>

            </div>


            <div class="modal-body">

                <ValidationObserver v-slot="{ invalid  }">

                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        ref="projectNameValidate"
                    >

                        <div class="d-flex align-items-center align-self-center">

                            <div class="label">

                                <div>
                                    {{ $t("Project name") }}
                                </div>

                                <div style="font-size: .7em;">
                                    {{ $t("(max: 30 characters)") }}
                                </div>

                            </div>


                            <div class="w-100">

                                <input
                                    type="text"
                                    name="name"
                                    class="border"
                                    maxlength="30"
                                    v-model.trim="name"
                                    :placeholder="$t('Project name')"
                                    :class="[(errors.length > 0 ) ? 'border-danger' : '']"
                                />

                            </div>


                            <div class="validation-error">
                                {{ $t(errors[0]) }}
                            </div>

                        </div>

                    </ValidationProvider>


                    <div class="projectType mt-2">

                        <div class="label">
                            {{ $t("Project type") }}
                        </div>


                        <div class="d-flex pb-2 px-3">

                            <div class="my-auto pe-5">
                                {{ $t("Would you like to use a map or upload an image ?") }}
                            </div>


                            <toggle-button
                                class="my-auto"
                                :value="true"
                                :labels="{ checked: $t('Map'), unchecked: $t('Image') }"
                                :color="{ checked: '#75C791', unchecked: '#75C791' }"
                                :width="150"
                                :height="30"
                                :font-size="15"
                                :sync="true"
                                v-model="pickedType"
                            />

                        </div>


                        <div>

                            <div
                                v-if="!pickedType"
                                class="w-100"
                            >

                                <div class="text-center">

                                    <button
                                        name="triggerUploadButton"
                                        class="w3-button w3-theme"
                                        @click="triggerUpload"
                                    >
                                        <i class="fas fa-file-upload"></i>

                                        {{ $t("Select your image") }}

                                    </button>

                                </div>


                                <div v-show="image">

                                    <div class="w3-display-container">

                                        <img id="imageMap">

                                        <canvas
                                            id="imageCanvas"
                                            class="evd-canvas"
                                            :style="canvasStyle"
                                        >
                                        </canvas>

                                    </div>


                                    <div class="container mt-3 ps-0">

                                        <div class="px-3">
                                            {{ $t("Move red line ends to define the scale") }}
                                        </div>


                                        <div class="px-3 d-flex align-items-center align-self-center">

                                            <div class="label">
                                                {{ $t("Distance between line ends :") }}
                                            </div>


                                            <input
                                                class="p-2 m-0 border rounded"
                                                type="number"
                                                step="0.1"
                                                min="0.0"
                                                max="50.0"
                                                :value="distance"
                                                @input="setDistance"
                                            />


                                            <div class="ms-2">
                                                {{ $t("meters") }}
                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <input
                                    id="imageUploader"
                                    style="position: absolute; top: -2000px;"
                                    type="file"
                                    @change="selectFile"
                                />

                            </div>


                            <div v-else>

                                <div class="w-100 mt-2">

                                    <p class="text-center">
                                        {{ $t("To choose a location, please move the marker or the map, or type an address below") }}
                                    </p>


                                    <gmap-autocomplete
                                        class="border rounded w-100 p-2 mb-1"
                                        placeholder="This is a placeholder text"
                                        @place_changed="setPlace"
                                        ref="autoComplete"
                                    >
                                    </gmap-autocomplete>

                                </div>


                                <div class="w-100">

                                    <GmapMap
                                        :center="center"
                                        :zoom="15"
                                        map-type-id="terrain"
                                        ref="mapRef"
                                        class="map"
                                        @idle="setMarkerPositionAndAddress()"
                                        :options="mapOptions"
                                    >

                                        <GmapMarker
                                            :key="index"
                                            v-for="(m, index) in marker"
                                            :position="m.position"
                                            :clickable="true"
                                            :draggable="true"
                                            ref="marker"
                                            @click="center=m.position"
                                            @dragend="getMarkerPosition($event.latLng)"
                                        />

                                    </GmapMap>

                                </div>

                            </div>

                        </div>

                    </div>


                    <div class="projectSettings mt-3">

                        <div>

                            <div>
                                {{ $t("Project default properties :") }}
                            </div>


                            <div class="ms-3">

                                <div class="d-flex align-items-center align-self-center">

                                    <div class="label">
                                        {{ $t("Default camera height :") }}
                                    </div>


                                    <input
                                        class="p-2 m-0 border rounded"
                                        type="number"
                                        step="0.1"
                                        min="0.0"
                                        max="50.0"
                                        :value="defaultCameraHeight"
                                        @change="setDefaultHeight"
                                    />


                                    <div class="ms-2">
                                        {{ $t("meters") }}
                                    </div>

                                </div>


                                <vue-slider
                                    class="mx-4"
                                    v-model="defaultCameraHeight"
                                    tooltip="hover"
                                    :useKeyboard=false
                                    :tooltip-formatter="defaultCameraHeight+' m'"
                                    :interval="0.1"
                                    :min="0.0"
                                    :max="50.0"
                                />

                            </div>


                            <div class="ms-3 d-flex align-items-center align-self-center">

                                <div class="label">
                                    {{ $t("Default target height :") }}
                                </div>


                                <input
                                    class="p-2 m-0 border rounded"
                                    type="float"
                                    min="0.1"
                                    step="0.1"
                                    :value="defaultTargetHeight"
                                    @change="setDefaultTargetHeight"
                                />


                                <div class="ms-2">
                                    {{ $t("meters") }}
                                </div>

                            </div>


                            <div class="ms-3 d-flex align-items-center align-self-center">

                                <div class="label">
                                    {{ $t("Target's height in pixels :") }}
                                </div>


                                <input
                                    class="p-2 m-0 border rounded"
                                    type="number"
                                    step="1"
                                    min="1"
                                    :value="numberOfPixels"
                                    @change="setNumberOfPixels"
                                />


                                <div class="ms-2">
                                    {{ $t("pixels") }}
                                </div>

                            </div>

                        </div>

                    </div>


                    <button
                        class="btn evdesign-btn mt-2 w-100"
                        :disabled="verifyUserInput(invalid)"
                        @click="createProject"
                    >
                        {{ $t("Create the project") }}
                    </button>


                    <button
                        ref="closeModal"
                        class="d-none"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                    </button>

                </ValidationObserver>

            </div>

        </div>

    </div>

</div>

</template>

<!-- ====================================================================== -->

<script>

import $ from "jquery"
import { getGoogleMapsAPI } from "gmap-vue"
import VueSlider from "vue-slider-component"
import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate"
import { ToggleButton } from "vue-js-toggle-button"

import { FR } from "@/i18n/i18n_languages"
import {
    initCanvas,
    clearCanvas,
    removeCanvas,
    computeCoord,
    getCanvasDimension,
    basicMove,
    canvasConstraint,
    canvasConstraintObject,
    basicMoveObject,
} from "@/javascript/helpers/drawing_helper"

import translationFR from "./i18n/FR/translation"


//------------------------------------------------------------------------------


// let _ = require("lodash")
let paper = require("paper")


const TOLERANCE_PERCENTAGE = 3
const TOLERANCE_MIN = 10


//------------------------------------------------------------------------------


export default {
    data: function() {
        return {
            name: "",
            pickedType: true,
            marker: [],
            center: {
                lat: 48.8064460587285,
                lng: 2.114802210321857,
            },
            centerAddress: "",
            defaultCameraHeight: 5,
            defaultTargetHeight: 1.80,
            numberOfPixels: 10.0,
            // type: 1,
            image_path: "plan",
            errorMessage: "Please select a project type",
            place: null,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: false,
                fullscreenControl: true,
                disableDefaultUI: false,
                rotateControl: true,
            },
            image: undefined,
            imageSource: "",
            imageObj: undefined,
            distance: 20,
            firstPointPos: {x: 30, y: 50},
            secondPointPos: {x: 60, y: 50},
            canvasStyle: {
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
            },
        }
    },

    computed: {
        google: getGoogleMapsAPI
    },

    beforeCreate() {
        this.$i18n.add(FR, translationFR)
    },

    beforeMount: function() {
        let self = this
        window.addEventListener("resize", self.resizeFunction)
        window.addEventListener("fullscreenchange", self.resizeFunction)
    },

    async mounted() {
        await this.$gmapApiPromiseLazy()

        // Il est nécessaire ici d'attendre la promise gmap
        // avant de procéder à l'appel de google.*

        this.setMarker()
    },

    beforeDestroy: function() {
        clearCanvas("imageCanvas")
        removeCanvas()
        let self = this
        window.removeEventListener("resize", self.resizeFunction)
        window.removeEventListener("fullscreenchange", self.resizeFunction)
    },

    methods: {
        createProject: function() {
            if (this.pickedType) {
                this.createMapProject()
            } else {
                this.createImageProject()
            }
        },

        createMapProject: function() {
            let formData = {
                name: this.name,
                type: 1,
                address: this.centerAddress,
                lat: this.center.lat,
                lng: this.center.lng,
                scale: this.$refs.mapRef.$mapObject.getZoom(),
                default_cam_height: this.defaultCameraHeight,
                target_height: this.defaultTargetHeight,
                target_pixels: this.numberOfPixels
            }

            this.axios({
                method: "post",
                url: "evdesign/projects/create_map_project",
                data: formData,
                type: "json",
            })
                .then(() => {
                    this.createSuccess()
                })
                .catch((error) => {
                    this.createFail(error)
                })
        },

        createImageProject: function() {
            let formData = new FormData()
            formData.set("name", this.name)
            formData.set("type", 2)
            formData.set("scale", 1)
            formData.set("lat", this.center.lat)
            formData.set("lng", this.center.lng)

            formData.set("firstX", this.firstPointPos.x)
            formData.set("firstY", this.firstPointPos.y)
            formData.set("secondX", this.secondPointPos.x)
            formData.set("secondY", this.secondPointPos.y)
            formData.set("imageScale", this.distance)

            formData.set("default_cam_height", this.defaultCameraHeight)
            formData.set("target_height", this.defaultTargetHeight)
            formData.set("target_pixels", this.numberOfPixels)

            formData.set("image", this.image)

            this.axios({
                method: "post",
                headers: {"content-Type": "multipart/form-data"},
                url: "evdesign/projects/create_image_project",
                data: formData,
            })
                .then(() => {
                    this.createSuccess()
                })
                .catch((error) => {
                    this.createFail(error)
                })
        },

        createSuccess: function() {
            this.$emit("success")
            this.reset()
            this.$refs.closeModal.click()
        },

        createFail: function() {
            this.$noty.error(this.$t("Can't create this project"))
        },

        setDefaultHeight: function(e) {
            let value = parseFloat(e.target.value)
            if (value > 50.0) {
                value = 50
            }
            if (value < 0.0 || Number.isNaN(value)) {
                value = 0
            }

            if (!Number.isInteger(value)) {
                value = Number(value.toFixed(1))
            }

            this.defaultCameraHeight = value
            e.target.value = this.defaultCameraHeight
        },

        setDefaultTargetHeight: function(e) {
            let value = parseFloat(e.target.value)
            if (value < 0.1 || Number.isNaN(value)) {
                value = 0.1
            }

            if (!Number.isInteger(value)) {
                value = Number(value.toFixed(1))
            }

            this.defaultTargetHeight = value
            e.target.value = this.defaultTargetHeight
        },

        setNumberOfPixels: function(e) {
            let value = parseInt(e.target.value)
            if (value < 1 || Number.isNaN(value)) {
                value = 1
            }

            this.numberOfPixels = value
            e.target.value = this.numberOfPixels
        },

        setDistance: function(e) {
            let value = parseFloat(e.target.value)
            if (value < 0.1 || Number.isNaN(value)) {
                value = 0.1
            }

            if (!Number.isInteger(value)) {
                value = Number(value.toFixed(2))
            }

            this.distance = value
            e.target.value = this.distance
        },

        getMarkerPosition: function(event) {
            this.center.lat = event.lat()
            this.center.lng = event.lng()
            this.decodeLatLong(this.center)
        },

        setMarkerPositionAndAddress: function() {
            this.marker[0].position = { lat: this.center.lat, lng: this.center.lng }
            this.decodeLatLong(this.center)
        },

        decodeLatLong: function(position) {
            this.$gmapApiPromiseLazy().then(() => {
                // eslint-disable-next-line
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({
                    location: { lat: position.lat, lng: position.lng } }, (results, status) => {
                    if (status === "OK") {
                        this.centerAddress = results[0].formatted_address
                        this.$refs.autoComplete.$refs.input.value = this.centerAddress
                    }
                })
            })
        },

        setPlace: function(place) {
            this.place = place
            this.centerAddress = place.formatted_address
            this.center.lat = place.geometry.location.lat()
            this.center.lng = place.geometry.location.lng()
        },

        setMarker: function() {
            this.marker = [
                {
                    // eslint-disable-next-line
                    position: new google.maps.LatLng({ lat: 48.7103077, lng: 2.2565976 }),
                    weight: 100,
                },
            ]
        },

        verifyUserInput: function(invalid) {
            let isInvalid = invalid
            if (!this.pickedType) {
                isInvalid = invalid || (this.image === undefined)
            }
            return isInvalid
        },

        validate: function() {
            this.$refs.projectNameValidate.validate()
        },

        getInputClass: function(errorsList) {
            if (errorsList.length !== 0) {
                return "w-100 rounded border border-danger"
            }
            return "w-100 rounded border"
        },

        reset: function() {
            Object.assign(this.$data, this.$options.data.apply(this))
            this.setMarker()
        },

        triggerUpload: function() {
            $("#imageUploader").trigger("click")
        },

        selectFile: function(e) {
            this.image = e.target.files[0]
            this.imageSource = URL.createObjectURL(this.image)
            // this.drawImageMap()
            this.initImage()
        },

        initImage: function() {
            this.imageObj = new Image()
            this.imageObj.id = "imageMap"
            this.imageObj.style.width = "100%"
            let self = this
            this.imageObj.onload = function() {
                clearCanvas("imageCanvas")
                removeCanvas()
                self.refreshCanvasStyle()
                self.mountCanvas()
                self.drawLine()
            }
            this.imageObj.src = this.imageSource
            $("#imageMap").replaceWith(this.imageObj)
        },

        mountCanvas: function() {
            let tolerance = this.computeTolerance()
            initCanvas("imageCanvas", this, tolerance)
        },

        resizeFunction: function() {
            if (!this.pickedType) {
                clearCanvas("imageCanvas")
                removeCanvas()
                this.refreshCanvasStyle()
                this.mountCanvas()
                this.drawLine()
            }
        },

        refreshCanvasStyle: function() {
            let image = $(this.imageObj)
            this.canvasStyle = {
                top: image.position().top + "px",
                left: image.position().left + "px",
                width: image.width() + "px",
                height: image.height() + "px",
            }
        },

        drawLine: function() {
            let dimension = getCanvasDimension()

            let first = new paper.Point(computeCoord(this.firstPointPos, dimension))
            let second = new paper.Point(computeCoord(this.secondPointPos, dimension))

            let linePath = new paper.Path.Line(first, second)

            linePath.first = first
            linePath.second = second
            linePath.strokeColor = "red"
            linePath.strokeWidth = 5
            linePath.strokeScaling = false
            linePath.selectable = true

            let tolerance = this.computeTolerance()

            let self = this
            linePath.on({
                mousedown: function(e) {
                    let hitOptions = {
                        segments: true,
                        stroke: true,
                        tolerance: tolerance,
                    }
                    let hitResult = paper.project.hitTest(e.point, hitOptions)
                    this.clickHitResult = hitResult
                },
                mousedrag: function(e) {
                    let type = this.clickHitResult.type
                    if (type === "segment") {
                        let movable = true
                        movable &= canvasConstraint(this.clickHitResult.segment, e)
                        if (movable) {
                            basicMove(this.clickHitResult.segment, e)
                        }
                    } else if (type === "stroke") {
                        let movable = true
                        movable &= canvasConstraintObject(this, e)
                        if (movable) {
                            basicMoveObject(this, e)
                        }
                    }
                },
                mouseup: function(e) {
                    if (this.clickHitResult) {
                        let type = this.clickHitResult.type
                        if (type === "segment") {
                            let point = {
                                x: this.clickHitResult.segment.point.x,
                                y: this.clickHitResult.segment.point.y,
                            }
                            // // let pourcentageCoord = uncomputeCoord(point, dimension)
                            let pourcentageCoord = {
                                x: Number(((point.x * 100) / dimension.x).toFixed(2)),
                                y: Number(((point.y * 100) / dimension.y).toFixed(2)),
                            }
                            if (this.clickHitResult.point.x === this.first.x && this.clickHitResult.point.y === this.first.y) {
                                this.first = point
                                self.firstPointPos = pourcentageCoord
                            } else if (this.clickHitResult.point.x === this.second.x && this.clickHitResult.point.y === this.second.y) {
                                this.second = point
                                self.secondPointPos = pourcentageCoord
                            } else {
                                // N'arrive pas
                            }
                        } else if (type === "stroke") {
                            this.first = {
                                x: this.clickHitResult.item.segments[0].point.x,
                                y: this.clickHitResult.item.segments[0].point.y,
                            }
                            self.firstPointPos = {
                                x: Number(((this.first.x * 100) / dimension.x).toFixed(2)),
                                y: Number(((this.first.y * 100) / dimension.y).toFixed(2)),
                            }
                            this.second = {
                                x: this.clickHitResult.item.segments[1].point.x,
                                y: this.clickHitResult.item.segments[1].point.y,
                            }
                            self.secondPointPos = {
                                x: Number(((this.second.x * 100) / dimension.x).toFixed(2)),
                                y: Number(((this.second.y * 100) / dimension.y).toFixed(2)),
                            }
                        }
                    }
                    this.clickHitResult = null
                },
            })

            paper.view.draw()
        },

        computeTolerance: function() {
            let canvasWidth = $("#imageCanvas").innerWidth()
            let tolerance = TOLERANCE_MIN
            let percentage = parseInt(canvasWidth * TOLERANCE_PERCENTAGE / 100)
            if (percentage > TOLERANCE_MIN) {
                tolerance = percentage
            }
            return tolerance
        },

        computeImageScale: function() {
            // console.log("FIRST", this.firstPointPos.x, this.firstPointPos.y)
            // console.log("SECOND", this.secondPointPos.x, this.secondPointPos.y)

            // let projectionPoint = {
            //     x: this.secondPointPos.x,
            //     y: this.firstPointPos.y,
            // }
            // console.log("PRJECTION", projectionPoint.x, projectionPoint.y)

            let l = this.secondPointPos.x - this.firstPointPos.x
            let h = this.secondPointPos.y - this.firstPointPos.y
            // console.log("l", l)
            // console.log("h", h)

            let alpha = Math.atan(h / l) // * 180 / Math.PI
            // console.log("alpha", alpha)

            // console.log("distance", this.distance)

            let projectionDistance = (Math.cos(alpha) * this.distance).toFixed(2)
            // console.log("project distance", projectionDistance)

            return projectionDistance
        },
    },

    components: {
        ValidationProvider,
        ValidationObserver,
        VueSlider,
        ToggleButton,
    },
}

</script>

<!-- ====================================================================== -->

<style scoped>

.map {
    width: auto;
    height: 40vh
}

.evd-canvas {
    position: absolute;
}

.label {
    width: 250px;
}


</style>


<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
