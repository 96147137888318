<!-- ==========================================================================
@author Mehdi, mramzi@e-vitech.com
@date 2020/08/20
@copyright EVITECH
===============================================================================

Composant pour ajouter une caméra

=========================================================================== -->

<template>

<div
    class="modal addCameraModal fade"
    aria-hidden="true"
>

    <div class="modal-dialog animated">

        <div class="modal-content">

            <div class="modal-header">

                <div class="modal-title fw-bold">
                    {{ $t("Add a new camera") }}
                </div>


                <button
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="button"
                >
                </button>

            </div>


            <div
                v-show="displayAddCamera"
                class="modal-body"
            >

                <ValidationObserver v-slot="{ invalid }">

                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        ref="cameraNameValidate"
                    >

                        <div class="cameraName">

                            <div class="container ps-0">

                                <div class="px-3">
                                    {{ $t("Camera name") }}
                                </div>


                                <input
                                    type="text"
                                    :placeholder="$t('Camera name')"
                                    name="name"
                                    :class="[(errors.length > 0 ) ? 'border-danger' : '']"
                                    v-model="name"
                                    class="border"
                                />


                                <div class="validation-error">
                                    {{ $t(errors[0]) }}
                                </div>

                            </div>

                        </div>

                    </ValidationProvider>


                    <div class="projectType mt-2 container ps-0">

                        <NewFactory
                            ref="camerasFactory"
                            class="w-100"
                            @selectedCamera="selectFactoryCamera"
                        />


                        <div class="mt-2 container ps-0">

                            <div class="d-flex align-items-center align-self-center">

                                <div class="ms-2">
                                    {{ $t("Height : ") }}
                                </div>


                                <input
                                    class="p-2 ms-2 border rounded"
                                    type="number"
                                    min="0"
                                    max="50"
                                    step="0.1"
                                    :value="cameraHeight"
                                    @input="setHeight"
                                    style="width: 100px;"
                                />


                                <div class="ms-2">
                                    {{ $t("meters") }}
                                </div>

                            </div>


                            <vue-slider
                                class="mx-4 my-2"
                                v-model="cameraHeight"
                                tooltip="hover"
                                :min="0"
                                :max="50"
                                :useKeyboard=false
                                :tooltip-formatter="cameraHeight+' m'"
                                :interval="0.1"
                            />

                        </div>


                        <div class="mt-2 container ps-0">

                            <div class="d-flex align-items-center align-self-center">

                                <div class="ms-2">
                                    {{ $t("Tilt : ") }}
                                </div>


                                <input
                                    class="p-2 ms-2 border rounded"
                                    type="number"
                                    min="0"
                                    max="90"
                                    step="1"
                                    :value="cameraTilt"
                                    @input="setTilt"
                                    style="width: 100px;"
                                />


                                <div class="ms-2">
                                    {{ $t("° degrees") }}
                                </div>

                            </div>


                            <vue-slider
                                class="mx-4 my-2"
                                v-model="cameraTilt"
                                tooltip="hover"
                                :min="0"
                                :max="90"
                                :useKeyboard=false
                                :tooltip-formatter="cameraTilt+'°'"
                                :interval="1"
                            />

                        </div>

                    </div>


                    <button
                        class="btn evdesign-btn w-100 mt-2"
                        :disabled="disableButton(invalid)"
                        @click="chooseLocation"
                    >
                        {{ $t("Add camera into the project") }}
                    </button>

                </ValidationObserver>

            </div>


            <div
                v-if="displayLocationInfo"
                class="modal-body text-center"
            >

                <h1>
                    {{ $t("Choosing the camera location") }}
                </h1>


                <p>
                    {{ $t("You are going to be redirected back to the map, please click on the location where you want to place the camera") }}
                </p>


                <!-- Checkbox "ne plus afficher" ne fonctionne pas -->
                <div v-if="false">

                    <input type="checkbox">

                    <label>
                        {{ $t("Don't show this again in the future") }}
                    </label>

                </div>


                <button
                    ref=closeModal
                    class="btn evdesign-btn mt-2 w-100"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                >
                    {{ $t("Ok") }}
                </button>

            </div>

        </div>

    </div>

</div>

</template>


<!-- ====================================================================== -->


<script>


import { isEmpty } from "lodash"
import VueSlider from "vue-slider-component"
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate"
import { ToggleButton } from "vue-js-toggle-button"

import { FR } from "@/i18n/i18n_languages"

import NewFactory from "@/components/factory_cameras/new_factory"

import translationFR from "./i18n/FR/translation"


//-------------------------------------------------------------------------------


export default {
    props: {
        defaultCameraHeight: {
            type: Number,
            default: 5,
        }
    },

    data: function() {
        return {
            pickedType: false,
            name: "",
            cameraTilt: 0,
            cameraHeight: 0,
            display: "addCamera",
            factoryCamera: {},
            duplicateCameraSettings: {},
        }
    },

    watch: {
        defaultCameraHeight: {
            handler(value) {
                this.cameraHeight = value
            },
        },
    },

    computed: {
        displayAddCamera: function() {
            return this.display === "addCamera"
        },

        displayLocationInfo: function() {
            return this.display === "locationInfo"
        },
    },

    beforeCreate() {
        this.$i18n.add(FR, translationFR)
    },

    mounted: function() {
        this.cameraHeight = this.defaultCameraHeight
    },

    methods: {
        disableButton: function(invalid) {
            return isEmpty(this.factoryCamera) || invalid
        },

        selectFactoryCamera: function(selectedFactoryCamera) {
            this.factoryCamera = selectedFactoryCamera
        },

        chooseLocation: function() {
            this.goTo("locationInfo")
            this.$emit("chooseLocation")
        },

        setHeight: function(event) {
            let value = parseFloat(event.target.value)
            if (value > 50.0) {
                value = 50
            }
            if (value < 0.0 || Number.isNaN(value)) {
                value = 0
            }
            this.cameraHeight = value
            event.target.value = this.cameraHeight
        },

        setTilt: function(event) {
            let value = parseFloat(event.target.value)
            if (value > 90.0) {
                value = 90.0
            }
            if (value < 0.0 || Number.isNaN(value)) {
                value = 0
            }
            this.cameraTilt = value
            event.target.value = this.cameraTilt
        },

        addCamera: function(clickedLocation, numberOfCameras) {
            this.goTo("addCamera")

            let camera = {
                factory_id: this.factoryCamera.factory_camera_id,
                aov_v_min: this.factoryCamera.aov_v_min,
                aov_v_max: this.factoryCamera.aov_v_max,
                aov_h_min: this.factoryCamera.aov_h_min,
                aov_h_max: this.factoryCamera.aov_h_max,
                model: this.factoryCamera.model,
                manufacturer: this.factoryCamera.manufacturer,
                sensor_type: this.factoryCamera.sensor_type,
                sensor_height: this.factoryCamera.sensor_height,
                sensor_width: this.factoryCamera.sensor_width,
            }

            if (Object.keys(this.duplicateCameraSettings).length !== 0) {
                camera.settings = this.duplicateCameraSettings
            } else {
                camera.settings = {
                    name: this.name,
                    tilt: this.cameraTilt,
                    height: this.cameraHeight,
                    orientation: 0,
                    aov_h: this.factoryCamera.aov_h_min,
                    aov_v: this.factoryCamera.aov_v_min,
                    is_in_portrait_position: false,
                    fov_color: "#37FF00",
                }
            }

            camera.settings.location = {
                coordinate: [clickedLocation[0], clickedLocation[1]]
            }
            camera.settings.marker = {
                // eslint-disable-next-line
                position: new google.maps.LatLng({
                    lat: clickedLocation[0],
                    lng: clickedLocation[1]
                }),
                // eslint-disable-next-line
                anchor: new google.maps.Point(-20, 0),
            }
            camera.settings.position_in_list = numberOfCameras

            this.reset()
            this.$refs.closeModal.click()
            this.$emit("success", camera)
        },

        goTo: function(label) {
            this.display = label
        },

        reset: function() {
            Object.assign(this.$data, this.$options.data.call(this))
            this.$refs.camerasFactory.reset()
            this.cameraHeight = this.defaultCameraHeight
            this.duplicateCameraSettings = {}
        },

        duplicate: function(cameraToClone) {
            let newName = cameraToClone.settings.name + "_copy"
            this.name = newName
            this.cameraTilt = cameraToClone.settings.tilt
            this.cameraHeight = cameraToClone.settings.height
            this.factoryCamera = {
                factory_camera_id: cameraToClone.factory_id,
                manufacturer: cameraToClone.manufacturer,
                model: cameraToClone.model,
                sensor_type: cameraToClone.sensor_type,
                sensor_height: cameraToClone.sensor_height,
                sensor_width: cameraToClone.sensor_width,
                aov_v_min: cameraToClone.aov_v_min,
                aov_v_max: cameraToClone.aov_v_max,
                aov_h_min: cameraToClone.aov_h_min,
                aov_h_max: cameraToClone.aov_h_max,
            }

            this.duplicateCameraSettings = {
                name: newName,
                tilt: cameraToClone.settings.tilt,
                height: cameraToClone.settings.height,
                orientation: cameraToClone.settings.orientation,
                aov_h: cameraToClone.settings.aov_h,
                aov_v: cameraToClone.settings.aov_v,
                is_in_portrait_position: cameraToClone.settings.is_in_portrait_position,
                fov_color: cameraToClone.settings.fov_color,
            }

            this.goTo("locationInfo")
            this.chooseLocation()
        },
    },

    components: {
        ValidationProvider,
        ValidationObserver,
        VueSlider,
        ToggleButton,
        NewFactory,
    },
}
</script>

<!-- ====================================================================== -->

<style scoped>

h1 {
    margin: 0px;
    margin-left: -9px;
    margin-bottom: 11px;
    font-size: 15px;
}

h2 {
    margin: 0px;
    margin-left: -9px;
    margin-bottom: 11px;
    font-size: 10px;
}

.addCameraModal .modal-dialog {
    max-width: 90vw;
}

</style>

<!-- ====================================================================== -->
<!-- End of file -->
<!-- ====================================================================== -->
